import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../shared.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css']
})
export class CommunicationComponent implements OnInit {
  
  prescriptions: any;
  patientId:any;

  constructor(private service: SharedService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        this.patientId = params['patientId'];
      });
    if (this.patientId != undefined) {
      this.getPrescriptions();
    }
  }

  getPrescriptions() {
    this.service.getPatientPrescriptons(this.patientId).subscribe(data => {
      this.prescriptions = data;
    });
  }

}
