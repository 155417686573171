<nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <div class="main-wrapper">
        <!--Header-->
        <div class="header">
            <div class="header-left form-group">
                <span><a href="index-2.html" class="logo">
                    <img src="assets/img/AnjaniInnovationsLogo.png" width="35" height="35" alt=""> <span>Ai Clinics</span>
                </a></span>                
            </div>
            
            <a id="toggle_btn" href="javascript:void(0);"><i class="fa fa-bars"></i></a>
            <a id="mobile_btn" class="mobile_btn float-left" href="#sidebar"><i class="fa fa-bars"></i></a>
            <span class="clinicHeader">{{hospitalName}}</span>
            <ul class="nav user-menu float-right">
                <!-- <li class="nav-item float-left" style="margin-right: 34rem;"><div class="clinicname">Marvel Clinics</div></li> -->
                <li class="nav-item ">
                    <a routerLink="addPatient" [queryParams]="{fromNavbar:'true'}" class="nav-link user-link">
                        <span>Add Patient</span>
                    </a>
                </li>
                <li class="nav-item ">
                    <a routerLink="addappointment" class="nav-link user-link">
                        <span>Add Appointment</span>
                    </a>
                </li>

                <li class="nav-item dropdown has-arrow">
                    <a href="#" class="dropdown-toggle nav-link user-link" data-toggle="dropdown">
                        <span class="user-img"><img class="rounded-circle" src="" width="40" alt="">
                        </span>
                        <span>Add Bill</span>
                    </a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" routerLink="addeditbill" style="background-color: blanchedalmond;">Clinic</a>
                    </div>
                </li>

                <li class="nav-item dropdown d-none">
                    <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown"><i class="fa fa-bell-o"></i>
                        <span class="badge badge-pill bg-danger float-right">3</span></a>
                    <div class="dropdown-menu notifications">
                        <div class="topnav-dropdown-header">
                            <span>Notifications</span>
                        </div>
                        <div class="drop-scroll">
                            <ul class="notification-list">
                                <li class="notification-message">
                                    <a href="activities.html">
                                        <div class="media">
                                            <span class="avatar">
                                                <img alt="John Doe" src="assets/img/user.jpg" class="img-fluid">
                                            </span>
                                            <div class="media-body">
                                                <p class="noti-details"><span class="noti-title">John Doe</span> added
                                                    new task <span class="noti-title">Patient appointment booking</span>
                                                </p>
                                                <p class="noti-time"><span class="notification-time">4 mins ago</span>
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="notification-message">
                                    <a href="activities.html">
                                        <div class="media">
                                            <span class="avatar">V</span>
                                            <div class="media-body">
                                                <p class="noti-details"><span class="noti-title">Tarah Shropshire</span>
                                                    changed the task name <span class="noti-title">Appointment booking
                                                        with payment gateway</span></p>
                                                <p class="noti-time"><span class="notification-time">6 mins ago</span>
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="notification-message">
                                    <a href="activities.html">
                                        <div class="media">
                                            <span class="avatar">L</span>
                                            <div class="media-body">
                                                <p class="noti-details"><span class="noti-title">Misty Tison</span>
                                                    added <span class="noti-title">Domenic Houston</span> and <span
                                                        class="noti-title">Claire Mapes</span> to project <span
                                                        class="noti-title">Doctor available module</span></p>
                                                <p class="noti-time"><span class="notification-time">8 mins ago</span>
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="notification-message">
                                    <a href="activities.html">
                                        <div class="media">
                                            <span class="avatar">G</span>
                                            <div class="media-body">
                                                <p class="noti-details"><span class="noti-title">Rolland Webber</span>
                                                    completed task <span class="noti-title">Patient and Doctor video
                                                        conferencing</span></p>
                                                <p class="noti-time"><span class="notification-time">12 mins ago</span>
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="notification-message">
                                    <a href="activities.html">
                                        <div class="media">
                                            <span class="avatar">V</span>
                                            <div class="media-body">
                                                <p class="noti-details"><span class="noti-title">Bernardo Galaviz</span>
                                                    added new task <span class="noti-title">Private chat module</span>
                                                </p>
                                                <p class="noti-time"><span class="notification-time">2 days ago</span>
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="topnav-dropdown-footer">
                            <a href="activities.html">View all Notifications</a>
                        </div>
                    </div>
                </li>
                <li class="nav-item dropdown d-none">
                    <a href="javascript:void(0);" id="open_msg_box" class="hasnotifications nav-link"><i
                            class="fa fa-comment-o"></i> <span
                            class="badge badge-pill bg-danger float-right">8</span></a>
                </li>
                <li class="nav-item dropdown has-arrow d-none">
                    <a href="#" class="dropdown-toggle nav-link user-link" data-toggle="dropdown">
                        <span class="user-img"><img class="rounded-circle" src="assets/img/user.jpg" width="40" alt="Admin">
                            <span class="status online"></span></span>
                        <!-- <span>Admin</span> -->
                        <span>Logout</span>
                    </a>
                    <div class="dropdown-menu d-none">
                        <a class="dropdown-item" href="profile.html">My Profile</a>
                        <a class="dropdown-item" href="edit-profile.html">Edit Profile</a>
                        <a class="dropdown-item" href="settings.html">Settings</a>
                        <a class="dropdown-item" href="login.html">Logout</a>
                    </div>
                </li>
                <li class="nav-item ">
                    <a class="nav-link user-link" [routerLink]="" (click)="logMeOut()">
                        <span class="text-warning fs-1 fw-bold">Logout</span>
                    </a>
                </li>
            </ul>
            <div class="dropdown mobile-user-menu float-right">
                <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i
                        class="fa fa-ellipsis-v"></i></a>
                <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="profile.html">My Profile</a>
                    <a class="dropdown-item" href="edit-profile.html">Edit Profile</a>
                    <a class="dropdown-item" href="settings.html">Settings</a>
                    <a class="dropdown-item" href="login.html">Logout</a>
                </div>
            </div>
        </div>
        <!-- /Header-->
        <!-- Sidebar -->
        <div class="sidebar" id="sidebar"> 
            <div class="sidebar-inner slimscroll">
                <div id="sidebar-menu" class="sidebar-menu">
                    <ul>
                        <li>
                            <a routerLink="dashboard"><i class="fa fa-dashboard"></i> <span>Dashboard</span></a>
                        </li>
                        <li class="d-none">
                            <a href="#"><i class="fa fa-user-md"></i> <span>Doctors</span></a>
                        </li>
                        <li>
                            <a routerLink="patient"><i class="fa fa-wheelchair"></i> <span>Patients</span></a>
                        </li>
                        <li>
                            <a routerLink="appointments"><i class="fa fa-calendar"></i>
                                <span>Appointments</span></a>
                        </li>
                        <li>
                            <a routerLink="prescription"><i class="fa fa-calendar-check-o"></i>
                                <span>Prescriptions</span></a>
                        </li>
                        <li class="submenu">
                            <a><i class="fa fa-pencil-square-o"></i> <span>Billing</span><span
                                    class="menu-arrow"></span></a>
                            <ul style="display: none;">
                                <li><a routerLink="bills"> Clinic </a></li>
                                <li class="d-none"><a href="#"> Pharmacy </a></li>
                                <li class="d-none"><a href="#"> Lab </a></li>
                            </ul>
                        </li>
                        <li class="d-none">
                            <a href="schedule.html"><i class="fa fa-calendar-check-o"></i> <span>Inventory</span></a>
                        </li>
                        <li class="submenu d-none">
                            <a href="#"><i class="fa fa-flag-o"></i> <span> Reports </span> <span
                                    class="menu-arrow"></span></a>
                            <ul class="d-none">
                                <li><a href="expense-reports.html"> Expense Report </a></li>
                                <li><a href="invoice-reports.html"> Invoice Report </a></li>
                            </ul>
                        </li>
                        <li>
                            <a routerLink="settings"><i class="fa fa-cog"></i> <span>Settings</span></a>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
        <!-- /sidebar -->
    </div>
</nav>

<div class="sidebar-overlay" data-reff=""></div>
<router-outlet></router-outlet>