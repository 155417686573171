import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl,FormGroup} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../shared.service';
import { AuthenticationService } from './../_services/authentication.service';

@Component({
  selector: 'app-department',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit {

  form: FormGroup;
  currentUser:any;
  Departments:any =[];
  viewDashboard: any = false;
  submitText:any;
  departmentDetails:any;

  constructor(public service: SharedService, private fb: FormBuilder, private authenticationService: AuthenticationService,private route: ActivatedRoute,private router: Router) { 
      this.currentUser = this.authenticationService.currentUserValue;
      this.createForm();
    }

  ngOnInit(): void {
    if (JSON.stringify(this.currentUser) == '{}' && localStorage.getItem('currentUser') == undefined) {
      this.router.navigateByUrl('login');      
    } else {
      this.route.queryParams.subscribe(
        params => {
          if (params['viewDashboard'] != undefined && params['viewDashboard'] == 'true') {
            this.viewDashboard = true;
            this.loadDepartmentsList();
          }
        });
    }
  }
  createForm(){
    this.submitText = 'SAVE';
    this.viewDashboard = false;
    this.form = this.fb.group({
      departmentId: new FormControl(""),
      departmentName: new FormControl(""),
      hospitalId: new FormControl(this.currentUser.User.HospitalId)
    });
  }

  loadDepartmentsList() {
    this.service.getDepartmentsList(this.currentUser.User.HospitalId).subscribe(data => {
      this.Departments = data;
      this.viewDashboard = true;
    });
  }

  getDepartmentDetails(id:any){
    this.form.reset();
    this.service.getDepartmentetails(id,this.currentUser.User.HospitalId).subscribe((data: any) => {
      this.submitText = 'UPDATE';
      this.viewDashboard = false;
      this.departmentDetails = data;
      if(this.departmentDetails != null){
        this.form.controls.departmentId.setValue(data.DepartmentId);
        this.form.controls.departmentName.setValue(data.DepartmentName);
        this.form.controls.hospitalId.setValue(data.HospitalId);
      }
    });
  }

  deleteDepartment(id: any) {
    this.service.deleteDepartment(id,this.currentUser.User.HospitalId).subscribe((data: any) => {
      var res = data;
      alert("Department deleted successfully");
      this.loadDepartmentsList();
    });
  }

  submit() {
    //this.submitted=true;
    this.form.value.hospitalId = this.currentUser.User.HospitalId;
    if (this.form.value.departmentId == null || this.form.value.departmentId == '') {
      delete this.form.value.departmentId;   
      this.service.addDepartment(this.form.value).subscribe((data: any) => {
        var res = data;
        alert("Department added successfully");
        this.loadDepartmentsList();
      });
    }
    else {
      this.service.updateDepartment(this.form.value).subscribe((data: any) => {
        var res = data;
        alert("Department details updated");        
        this.loadDepartmentsList();
      });
    }

  }

}
