import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from 'src/app/_services/authentication.service';

export class Item {
  id: string;
  name: string;
}
export const ITEMS: Item[] = [
  {
    id: '1',
    name: 'Male'
  },
  {
    id: '2',
    name: 'Female'
  },
  {
    id: '3',
    name: 'Other'
  }
];
@Component({
  selector: 'app-register',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  roles: any;
  gendersList: any = [];
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(private fb: FormBuilder,private router: Router,
    private route: ActivatedRoute,private datePipe: DatePipe,
    private authenticationService: AuthenticationService) { }


  ngOnInit(): void {
    this.getSelecteditem();
    this.createForm();
  }

  public createForm() {
    this.registerForm = this.fb.group({
      userId:['0'],
      firstName:['', Validators.required],
      lastName:['', Validators.required],
      userName: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      phonenumber: ['', Validators.required],
      email: ['', Validators.required,Validators.email],
      dateOfBirth: ['', Validators.required],
      gender: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: ['', Validators.required],
      hospitalName: ['', Validators.required],
      // hospitalAddress: ['', Validators.required],
      CreatedDateTimeStamp:[this.datePipe.transform(new Date(), "yyyy-MM-dd hh:mm:ss"), Validators.required],
      UpdatedDateTimeStamp:[''],
      hospitalPhonenumber:['', Validators.required],
      HospitalMailId:['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      subscriptionPack:['Hospital', Validators.required],
      ExpireDate:['', Validators.required],
      roleId:['1'],
      hospitalAddress: ['', Validators.required],
      hospitalCity: ['', Validators.required],
      hospitalState: ['', Validators.required],
      hospitalPostalCode: ['', Validators.required]
    }, {
      validator: this.MustMatch('password', 'confirmPassword')
  });
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

  register(){   
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }

    // set validity to one year
    let date: Date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    let datePipe: DatePipe = new DatePipe('en-US');
    this.registerForm.value.UpdatedDateTimeStamp = datePipe.transform(date, 'yyyy-MM-dd hh:mm:ss');
    
    this.loading = true;
    this.authenticationService.createUser(this.registerForm.value).subscribe(data => {
      this.loading = false;
      this.router.navigateByUrl('login');
    },
      error => {
        this.error = error;
        this.loading = false;
      });
  }
  getSelecteditem() {
    this.gendersList = ITEMS;
  }

  get f() {
    return this.registerForm.controls;
  }

}
