import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SharedService } from 'src/app/shared.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError } from 'rxjs/operators';
import { Console } from 'console';
import { parse } from 'path';

@Component({
  selector: 'app-add-edit-bill',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './add-edit-bill.component.html',
  styleUrls: ['./add-edit-bill.component.css']
})
export class AddEditBillComponent implements OnInit {

  billForm: any;
  enableAddService: boolean = false;
  paymentMethods: any;
  servicesData: any;
  searchServiceName: any;
  SearchServicesList: any = 0;
  billId: any;
  bpId: any = 0;
  buttonName: any;
  discountAmount: any;
  finalAmount: any;
  paymentList: any[];
  page: number = 1;
  pageSize = 5;
  billDate: any;
  editBill: boolean = false;
  ItemIndex: number = 0;
  fromGetBillDetails: boolean = false;
  submitted = false;

  //Patient search
  patientsData: any;
  searchPatientName: any;
  SearchPatientsList: any = 0;

  //Doctor search
  doctorsData: any;
  searchDoctorName: any;
  SearchDoctorsList: any = 0;

  //Service bill details variables
  serviceDiscountAmount: any = 0;
  serviceDiscountPercentage: any = 0;
  serviceFinalRate: any = 0;

  //Total bill details variables
  totalServices: any = 0;
  modifiedBillDiscountPercentage: any = 0;
  billDiscountPercentage: any = 0;
  billDiscountAmount: any = 0;
  netAmountAfterDiscount: any = 0;
  netAmount: any = 0;
  totalAmountPaid: any = 0;
  totalDueAmount: number = 0;
  totalFinalRates: any = 0;
  currentUser: any;
  roleId: any;
  datePipe: DatePipe = new DatePipe('en-US');

  constructor(private fb: FormBuilder, private service: SharedService,
    private route: ActivatedRoute, private router: Router,) { this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}') }

  ngOnInit(): void {
    if (JSON.stringify(this.currentUser) == '{}' && localStorage.getItem('currentUser') == undefined) {
      this.router.navigateByUrl('login');
    }
    else {
      this.roleId = this.currentUser.User.RoleId;
      this.createForm();
      this.getPatients();
      this.getDoctors();
      this.getPaymentModes();
      this.getServiceNames();
      this.route.queryParams.subscribe(
        params => {
          this.billId = params['billId'];
        });
      if (this.billId != undefined) {
        this.getBillDetails();
        this.buttonName = 'Update Bill';
        this.editBill = true;
      }
      this.billForm.controls.paymentDate.setValue(this.datePipe.transform(new Date(), "yyyy-MM-dd"));
    }
  }

  public createForm() {
    this.billForm = this.fb.group({
      invoiceNo: ['INVOICE-123', Validators.required],
      BillCode: ['1', Validators.required],
      BillId: ['0'],
      BillDate: [(this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss")), Validators.required],
      Discount: ['0'],
      PaidAmount: ['0'],
      PatientId: [''],
      PaymentModeId: ['1'],
      TotalAmount: ['0'],
      hospitalId: this.currentUser.User.HospitalId,
      patientName: ['', Validators.required],
      doctorName: ['', Validators.required],
      doctorId: [''],

      serviceId: [''],
      search: [''],
      serviceName: [''],
      amount: [''],
      discountPercentage: ['0'],
      quantity: ['', [Validators.pattern("^[0-9]*$")]],

      //Add bill variables
      paymentDate: [''],
      paymentAmount: [''],
      paymentMode: [''],

      Services: this.fb.array([]),
      billServices: this.fb.array([]),
      billPayments: this.fb.array([])
    })
  };

  changePayment(e: any) {
    this.PaymentModeId?.setValue(e.target.value, {
      onlySelf: true,
    });
    this.getPaymentMode();
  }
  // Access formcontrols getter
  get PaymentModeId() {
    return this.billForm.get('PaymentModeId');
  }
  get paymentMode() {
    return this.billForm.get('paymentMode');
  }

  getPaymentMode() {
    var paymentModeObj = this.paymentMethods.filter((data: any) => {
      return data.PaymentModeId.toString().toLowerCase().match(this.PaymentModeId.value.toLowerCase());
    });
    //this.billForm.value.paymentMode = paymentModeObj != null ? paymentModeObj[0].PaymentMethod : '';
    this.paymentMode.setValue(paymentModeObj[0].PaymentMethod);
  }

  getPaymentModes() {
    this.service.paymentMethods()
      .subscribe(
        (data) => {
          if (data != null) {
            this.paymentMethods = data;
          };
        })
  };
  getServiceNames() {
    this.service.getServices(this.currentUser.User.HospitalId)
      .subscribe(
        (data) => {
          if (data != null) {
            this.servicesData = data;
          };
        })
  };
  getPatients() {
    this.service.getPatientsList(this.currentUser.User.HospitalId)
      .subscribe(
        (data) => {
          if (data != null) {
            data.forEach(element => {
              if (element.LastName == null) {
                element.LastName = "";
              }
            });
            this.patientsData = data;
          };
        })
  };
  getDoctors() {
    this.service.getDoctorsList(this.currentUser.User.HospitalId)
      .subscribe(
        (data) => {
          if (data != null) {
            this.doctorsData = data;
            this.doctorsData.forEach((element: any) => {
              if (element.LastName == null) {
                element.LastName = "";
              }
              element.UserName = element.FirstName + ' ' + element.LastName;
            });
          };
        })
  };
  getBillDetails() {
    this.service.getBillById(this.billId, this.currentUser.User.HospitalId)
      .subscribe(
        (data) => {
          if (data != null) {
            if (data.Bill != null) {
              this.billDate = data.Bill.BillDate;
              this.billForm.controls.BillDate.setValue(this.datePipe.transform(this.billDate, "yyyy-MM-dd HH:mm:ss"));
              this.modifiedBillDiscountPercentage = this.billDiscountPercentage = data.Bill.DiscountPercentage;
            }
            if (data.Doctor != null) {
              this.billForm.controls.doctorName.setValue(data.Doctor.User.FirstName + ' ' + data.Doctor.User.LastName);
              this.billForm.controls.doctorId.setValue(data.Doctor.DoctorId);
            }
            if (data.Patient != null) {
              this.billForm.controls.patientName.setValue(data.Patient.FirstName + ' ' + data.Patient.LastName);
              this.billForm.controls.PatientId.setValue(data.Patient.PatientId);
            }
            this.billForm.controls.hospitalId.setValue(this.currentUser.User.HospitalId);
            if (data.PaymentDetails != null && data.PaymentDetails.length > 0) {
              this.paymentList = data.PaymentDetails;
              data.PaymentDetails.forEach((t: any) => {
                this.totalAmountPaid = this.totalAmountPaid + t.PaidAmount;
              });
            }
            if (data.ServiceDetails != null) {
              this.fromGetBillDetails = true;
              this.totalServices = data.ServiceDetails.length;
              data.ServiceDetails.forEach((t: any) => {
                this.servicesList.push(this.addPatchValues(t.ServiceId, t.ServiceName, t.Discount,
                  t.Amount, t.Unit));
              });
            }
          }
          this.netAmount = this.totalFinalRates;
          this.calculateTotalDiscount(this.billDiscountPercentage);
        })
  };

  //Edit service values  
  addPatchValues(id: any, name: any, discount: any, amount: any, unit: any) {
    this.netAmount = this.totalFinalRates = this.totalFinalRates + ((amount * unit) - ((amount * unit) * (discount / 100)));
    this.calculateTotalDiscount(this.modifiedBillDiscountPercentage);
    return this.fb.group({
      serviceId: id,
      serviceName: name,
      discountPercentage: discount,
      amount: amount,
      quantity: unit,
      serviceDiscountAmount: (amount * unit) * (discount / 100),
      finalAmount: (amount * unit) - ((amount * unit) * (discount / 100)),
    })
  };
  // addPaymentPatchValues(id: any, date: any, totalAmount: any, paidAmount: any, paymentMethod: any) {
  //   return ({
  //     BillId: id,
  //     Billdate: date,
  //     TotalAmount: totalAmount,
  //     PaidAmount: paidAmount,
  //     PaymentMethod: paymentMethod
  //   });
  // }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  // allow 2 degits after decimal
  handleNumberChanged(e: any) {
    const fixed = parseFloat(e.target.value).toFixed(2).toString()
    if (fixed.length < parseFloat(e.target.value).toString().length)
      e.target.value = fixed
  }

  calculateDiscount(i: number, event: number) {
    var id = this.servicesList.controls[i].value.serviceId;
    var name = this.servicesList.controls[i].value.serviceName;
    var disPercentage = parseInt(this.servicesList.controls[i].value.discountPercentage);
    var amount = parseInt(this.servicesList.controls[i].value.amount);
    var unit = event;
    var totalAmount = (amount * unit);
    var discountAmount = totalAmount * (disPercentage / 100);
    var finalAmount = totalAmount - discountAmount;

    this.servicesList.controls[i].patchValue({
      serviceId: id,
      serviceName: name,
      discountPercentage: disPercentage,
      amount: amount,
      quantity: unit,
      serviceDiscountAmount: discountAmount,
      finalAmount: finalAmount,
    })

    this.totalServicesAmount();
  }

  totalServicesAmount() {
    this.fromGetBillDetails = false;
    var serviceAmount = 0;
    this.servicesList.controls.forEach(element => {
      serviceAmount = serviceAmount + element.value.finalAmount;
    });
    this.netAmount = this.totalFinalRates = serviceAmount;
    this.calculateTotalDiscount(this.modifiedBillDiscountPercentage);
  }

  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      // switchMap allows returning an observable rather than maps array
      switchMap((searchText) => this.servicesData.artistLookup(searchText)),
      //catchError(new ErrorInfo().parseObservableResponseError)              
    );
  }

  resultFormatBandListValue(value: any) {
    return value.name;
  }
  inputFormatBandListValue(value: any) {
    if (value.name)
      return value.name
    return value;
  }

  Search(value: any) {
    if (value == 1) {
      if (this.searchPatientName == "") {
        if (this.SearchPatientsList.length > 0) {
          this.SearchPatientsList.length = 0;
        }
      } else {
        this.SearchPatientsList = this.patientsData.filter((data: any) => {
          return data.FirstName.toString().toLowerCase().match(this.searchPatientName.toLowerCase());
        });
      }
    }
    else if (value == 2) {
      if (this.searchDoctorName == "") {
        if (this.SearchDoctorsList.length > 0) {
          this.SearchDoctorsList.length = 0;
        }
      } else {
        this.SearchDoctorsList = this.doctorsData.filter((data: any) => {
          return data.UserName.toString().toLowerCase().match(this.searchDoctorName.toLowerCase());
        });
      }
    }
    else if (value == 3) {
      if (this.searchServiceName == "") {
        if (this.SearchServicesList.length > 0) {
          this.SearchServicesList.length = 0;
        }
      } else {
        this.SearchServicesList = this.servicesData.filter((data: any) => {
          return data.Name.toString().toLowerCase().match(this.searchServiceName.toLowerCase());
        });
      }
    }
  }
  onSelect(e: any) {
    this.servicesList.push(this.addPatchValues(e.ServiceId, e.Name, e.DiscountPercentage,
      e.Amount, e.Unit = 1));
    this.searchServiceName = "";
    this.SearchServicesList.length = 0;
    this.totalServices = this.servicesList.length;
  }
  onPatientSelect(e: any) {
    this.billForm.controls.patientName.setValue(e.FirstName + ' ' + e.LastName, {
      onlySelf: true
    });
    this.billForm.controls.PatientId.setValue(e.PatientId, {
      onlySelf: true
    });
    this.SearchPatientsList.length = 0;
  }

  onDoctorSelect(e: any) {
    this.billForm.controls.doctorName.setValue(e.UserName, {
      onlySelf: true
    });
    this.billForm.controls.doctorId.setValue(e.DoctorId, {
      onlySelf: true
    });
    this.SearchDoctorsList.length = 0;
  }

  addToPaymentList() {
    if (this.billForm.value.paymentAmount == "") {
      alert("Please enter amount to be paid");
    }
    else if (this.billForm.value.paymentMode == "" && this.paymentMethods != undefined) {
      this.billForm.value.paymentMode = this.paymentMethods[0].PaymentMethod;
    }

    let data = {
      BpId: this.bpId,
      BillId: this.billId,
      Billdate: this.billForm.value.paymentDate,
      TotalAmount: this.totalAmountPaid,
      PaidAmount: this.billForm.value.paymentAmount,
      PaymentMethod: this.billForm.value.paymentMode,
      EditPayment: true,
      ItemIndex: this.ItemIndex + 1
    };
    //this.paymentList.push(this.addPaymentPatchValues(this.billId, this.billForm.value.paymentDate, this.totalAmountPaid, this.billForm.value.paymentAmount, this.billForm.value.paymentMode));
    if (this.paymentList == undefined) {
      this.paymentList = [];
    }
    this.paymentList.push(data);
    this.billForm.controls.paymentAmount.setValue("");
    this.PaymentModeId.setValue(this.paymentMethods[0].PaymentModeId.toString());
    this.getPaymentMode();
    this.ItemIndex = this.ItemIndex + 1;
    this.totalAmountPaid = 0;
    this.paymentList.forEach((t: any) => {
      this.totalAmountPaid = this.totalAmountPaid + parseInt(t.PaidAmount);
      this.totalDueAmount = Math.round(this.netAmountAfterDiscount) - this.totalAmountPaid;
      if (this.totalAmountPaid > this.netAmountAfterDiscount) {
        alert("Paid amount is greater than total amount");
        //this.totalAmountPaid = this.totalAmountPaid - parseInt(t.PaidAmount);
        this.removePayment(t);
      }

    });


  }
  removePayment(event: any) {
    let index = this.paymentList.findIndex(x => x.ItemIndex == event.ItemIndex && event.EditPayment == true);
    if (index != undefined) {
      this.totalAmountPaid = 0;
      this.paymentList.splice(index, 1);
      this.paymentList.forEach((t: any) => {
        this.totalAmountPaid = this.totalAmountPaid + parseInt(t.PaidAmount);
        this.totalDueAmount = Math.round(this.netAmountAfterDiscount) - this.totalAmountPaid;
      });
    }

  }

  addService() {
    this.enableAddService = true;
  }
  removeService(i: number) {
    this.servicesList.removeAt(i);
    if (this.servicesList.length > 0) {
      this.totalServices = this.servicesList.length;
    }
    this.totalServicesAmount();
  }
  addServiceItem(id: any, unit: any) {
    return this.fb.group({
      serviceNameServiceId: [id],
      Unit: [unit],
    })
  };
  saveService() {
    if (this.billForm.controls.serviceName.value == "") {
      alert("Enter Service Name");
      return;
    }
    if (this.billForm.controls.amount.value == "") {
      alert("Enter amount details");
      return;
    }
    if (this.billForm.controls.quantity.value == "") {
      alert("Enter number of units");
      return;
    }
    this.enableAddService = false;
    var newService = { Name: this.billForm.controls.serviceName.value, Amount: this.billForm.controls.amount.value, DiscountPercentage: 0, HospitalId: this.currentUser.User.HospitalId }
    this.service.addService(newService).subscribe(res => {
      var newServiceID = res.Data;
      alert("Service details added successfully");

      this.servicesList.push(this.addPatchValues(newServiceID, this.billForm.controls.serviceName.value, this.billForm.controls.discountPercentage.value = 0,
        this.billForm.controls.amount.value, this.billForm.controls.quantity.value));

      this.totalServices = this.servicesList.length;
      this.billForm.controls.serviceName.setValue("");
      this.billForm.controls.amount.setValue("");
      this.billForm.controls.quantity.setValue("");
    },
      error => {
        console.log(error);
      });
  }
  cancelService() {
    this.enableAddService = false;
    this.billForm.controls.serviceName.setValue("");
    this.billForm.controls.amount.setValue("");
    this.billForm.controls.quantity.setValue("");
  }
  addPaymentItem(bpId: any, date: any, amount: any, paymentMode: any) {
    return this.fb.group({
      BpId: [bpId],
      PaymentDate: [date],
      PaidAmount: [amount],
      PaymentModeId: [paymentMode],
    })
  };

  saveBill() {    
    if (this.billForm.value.paymentAmount == "") {
      this.billForm.controls.PaidAmount.setValue(this.totalAmountPaid);
    } else {
      this.billForm.controls.PaidAmount.setValue(this.billForm.value.paymentAmount);
    }
    this.billForm.controls.TotalAmount.setValue(this.totalFinalRates);
    this.billForm.controls.discountPercentage.setValue(this.modifiedBillDiscountPercentage);
    if (this.servicesList.length <= 0) {
      alert("minimum one service is required");
      return;
    };

    this.servicesList.value.forEach((e: any) => {
      this.billServices.push(this.addServiceItem(e.serviceId, e.quantity));
    });

    if (this.paymentList != undefined && this.paymentList.length > 0) {
      var modeId: any = 0;
      this.paymentList.forEach((t: any) => {
        this.paymentMethods.forEach((e: any) => {
          if (e.PaymentMethod.toLowerCase() == t.PaymentMethod.toLowerCase()) {
            modeId = e.PaymentModeId;
            this.billPayments.push(this.addPaymentItem(t.BpId, t.Billdate, t.PaidAmount, modeId));
          }
        });
      });
    }

    this.submitted = true;
    if (this.billForm.invalid) {
      return;
    }
    if(this.billForm.value.PatientId == ""){
      alert('Enter valid patient details');
      return;
    }
    if(this.billForm.value.doctorId == ""){
      alert('Enter valid doctor details');
      return;
    }
    if (this.billForm.value.doctorName != "") {
      if (this.billForm.controls.doctorName.value != "") {
        let doctor = this.doctorsData.filter((data: any) => {
          var name = data.FirstName + ' ' + data.LastName;
          return name.toString().toLowerCase().match(this.billForm.controls.doctorName.value.toLowerCase()) ? true : false;
        });
      
      if (doctor.length > 0 && doctor != null) {
        doctor.forEach((t: any) => {
          t;
        });
      }

    }

    }
    if (this.billId != undefined) {
      this.billForm.controls.BillId.setValue(this.billId);
      this.billForm.controls.BillDate.setValue(this.datePipe.transform(this.billDate, "yyyy-MM-dd'T'HH:mm:ss"));
      this.service.updateBill(this.billForm.value).subscribe(res => {
        alert("Bill updated successfully");
        this.router.navigateByUrl('header/bills');
      },
        error => {
          console.log(error);
        });
    }
    else {
      this.service.addBill(this.billForm.value).subscribe(res => {
        alert("Bill details added successfully");
        this.router.navigateByUrl('header/bills');
      },
        error => {
          console.log(error);
        });
    }
  }

  get f() {
    return this.billForm.controls;
  }
  get servicesList(): FormArray {
    return this.billForm.get('Services') as FormArray;
  }
  get billServices(): FormArray {
    return this.billForm.get('billServices') as FormArray;
  }
  get billPayments(): FormArray {
    return this.billForm.get('billPayments') as FormArray;
  }

  inputDiscount(event: number) {
    this.fromGetBillDetails = false;
    this.calculateTotalDiscount(event);
  }

  calculateTotalDiscount(event: number) {
    var discountAmount: any;
    var netAmount: any;
    netAmount = this.netAmount;
    discountAmount = netAmount * (event / 100);
    discountAmount = parseFloat(discountAmount).toFixed(2); // converting decimal upto 2 digits    

    var netAmountAfterDiscount = netAmount - discountAmount;
    this.totalDueAmount = Math.round(netAmountAfterDiscount) - this.totalAmountPaid;

    if (discountAmount > netAmount) {
      this.modifiedBillDiscountPercentage = 0;
      if (!this.fromGetBillDetails) {
        alert("Discount amount can't be grater than Total amount");
      }
      this.modifiedBillDiscountPercentage = this.billDiscountPercentage;
      this.billDiscountAmount = this.netAmount * (this.modifiedBillDiscountPercentage / 100);
      this.netAmountAfterDiscount = this.netAmount - this.billDiscountAmount;
      this.totalDueAmount = Math.round(this.netAmountAfterDiscount) - this.totalAmountPaid;
    }
    else if (this.paymentList != undefined && netAmountAfterDiscount < this.totalDueAmount) {
      this.modifiedBillDiscountPercentage = 0;
      if (!this.fromGetBillDetails) {
        alert("Discount amount can't be grater than Total due amount");
      }
      this.modifiedBillDiscountPercentage = this.billDiscountPercentage;
      this.billDiscountAmount = this.netAmount * (this.modifiedBillDiscountPercentage / 100);
      this.netAmountAfterDiscount = this.netAmount - this.billDiscountAmount;
      this.totalDueAmount = Math.round(this.netAmountAfterDiscount) - this.totalAmountPaid;
    }
    else {
      this.modifiedBillDiscountPercentage = event;
      this.billDiscountAmount = discountAmount;
      this.netAmount = netAmount;

      this.netAmountAfterDiscount = this.netAmount - this.billDiscountAmount;
      this.totalDueAmount = Math.round(this.netAmountAfterDiscount) - this.totalAmountPaid;

      if (!this.fromGetBillDetails && this.totalDueAmount < 0) {
        alert("Due amount can't be less than zero");
        this.modifiedBillDiscountPercentage = this.billDiscountPercentage;
        this.billDiscountAmount = this.netAmount * (this.modifiedBillDiscountPercentage / 100);
        this.netAmountAfterDiscount = this.netAmount - this.billDiscountAmount;
        this.totalDueAmount = Math.round(this.netAmountAfterDiscount) - this.totalAmountPaid;
      }
    }


  }
}
