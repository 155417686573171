import { User } from './../../_models/user';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../../shared.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { flatten } from '@angular/compiler';

@Component({
  selector: 'app-complaint',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.css']
})
export class ComplaintComponent implements OnInit {

  form: FormGroup;
  viewDashboard:boolean = true;
  currentUser: any;
  itemsList:any = [];
  searchName:any;
  submitted: any;
  submitText:any;
  editComplaint:boolean = false;
  editExamination:boolean = false;
  editDiagnosis:boolean = false;
  getListName:any;
  doctorId:any;
  title:any = "Heading";
  addTitle:any;
  viewComplaints:boolean = false;
  viewExaminations:boolean = false;
  viewDiagnosis:boolean = false;
  SearchItemList: any = [];

  page: number = 1;
  pageSize = 5;

  constructor(
    public service: SharedService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser.User.RoleId == 2) {
      this.getDoctorDetails();
    }
    this.createForm();
  }

  ngOnInit(): void {
    if (JSON.stringify(this.currentUser) == '{}' && localStorage.getItem('currentUser') == undefined) {
      this.router.navigateByUrl('login');
    }
    else {
      this.route.queryParams.subscribe(
        params => {
          if (params['viewDashboard'] != undefined && params['viewDashboard'] == 'true') {
            this.viewDashboard = true;
            if (params['viewComplaint'] != undefined && params['viewComplaint'] == 'true') {
              this.viewComplaints = true;
              this.viewDiagnosis= false;
              this.viewExaminations = false;
              this.getListName = "GetComplaints";
              this.title = "Complaints";
              this.addTitle="AddComplaint";
            }
            else if (params['viewExamination'] != undefined && params['viewExamination'] == 'true') {
              this.viewComplaints = false;
              this.viewDiagnosis= false;
              this.viewExaminations = true;
              this.getListName = "GetExaminations";
              this.title = "Examinations";
              this.addTitle="AddExamination";
            }
            else if (params['viewDiagnosis'] != undefined && params['viewDiagnosis'] == 'true') {
              this.viewComplaints = false;
              this.viewDiagnosis= true;
              this.viewExaminations = false;
              this.getListName = "GetDiagnosis";
              this.title = "Diagnosis";
              this.addTitle="AddDiagnosis";
            }
            if(this.doctorId != undefined){
              this.loadItemsList();
             }           
          }
        });
      
    }
  }

  createForm() {
    this.submitText = 'SAVE';
    this.viewDashboard = false;
    this.editComplaint = false;
    this.form = this.fb.group({
      id: new FormControl(''),
      name: new FormControl('',Validators.required),
      doctorId: new FormControl(''),
      hospitalId: new FormControl('')      
    });
  }

  loadItemsList(){
    this.service.getMastersList(this.doctorId,this.currentUser.User.HospitalId,this.getListName).subscribe((data: any) => {
      var res = data;
      this.itemsList = res;
      this.SearchItemList = res;
      this.viewDashboard = true;
    },
      (error) => {
        alert(error);
      });    
  }

  getItem(id:any){
    this.form.reset();
    this.searchName = "";
    var name='';
    this.form.controls.id.setValue(id);
    this.form.controls.doctorId.setValue(this.doctorId);
    this.form.controls.hospitalId.setValue(this.currentUser.User.HospitalId);
    if (this.viewComplaints) {
      name = 'GetComplaint';
    }
    else if (this.viewExaminations) {
      name = 'GetExamination';
    }
    else if (this.viewDiagnosis) {
      name = 'GetDiagnosis';
    }
    this.service.getPMasterItem(id,this.form.value.doctorId,this.form.value.hospitalId,name).subscribe((data: any) => {
      if(data != null){      
        var res = data;
          this.editExamination = true;
          this.submitText = 'UPDATE';
          this.viewDashboard = false;
        this.form.controls.name.setValue(data.Name);

      }      
    },
      (error) => {
        alert(error);
    });
  }

  deleteItem(id: any) {
    this.searchName = "";
    var name='';
    this.form.controls.doctorId.setValue(this.doctorId);
    this.form.controls.hospitalId.setValue(this.currentUser.User.HospitalId);
    if (this.viewComplaints) {
      name = 'DeleteComplaint';
    }
    else if (this.viewExaminations) {
      name = 'DeleteExamination';
    }
    else if (this.viewDiagnosis) {
      name = 'DeleteDiagnosis';
    }
    this.service.deletePMasterItem(id,this.doctorId, this.currentUser.User.HospitalId,name).subscribe((data: any) => {
      var res = data;
      alert("Deleted successfully");
      this.loadItemsList();
    });
  }

  getDoctorDetails(){
    this.service.getDoctorDetailsById(this.currentUser.User.UserId,this.currentUser.User.HospitalId).subscribe((data: any) => {
      if(data != null){
        this.doctorId = data.DoctorId;
        this.loadItemsList();
      }      
    },
      (error) => {
        alert(error);
      });
    
  }

  searchItem(){
    if (this.searchName == "") {
      this.ngOnInit();
    } else {
      this.itemsList = this.SearchItemList.filter((data: any) => {
        var name = data.Name ;
        return name.toString().toLowerCase().match(this.searchName.toLowerCase());
      })
    }
  }

  submit(){
    var name='';
    this.form.controls.doctorId.setValue(this.doctorId);
    this.form.controls.hospitalId.setValue(this.currentUser.User.HospitalId);
    

    if(this.form.value.id == null || this.form.value.id == ''){      
      delete this.form.value.id;
      if (this.viewComplaints) {
        name = 'SaveComplaint';
      }
      else if (this.viewExaminations) {
        name = 'SaveExamination';
      }
      else if (this.viewDiagnosis) {
        name = 'SaveDiagnosis';
      }

    this.service.addPMasterItem(this.form.value,name).subscribe((data: any) => {
      alert("Added successfully");
      this.loadItemsList();     
    },
      (error) => {
        alert(error);
    });
  }
  else{
    if (this.viewComplaints) {
      name = 'UpdateComplaint';
    }
    else if (this.viewExaminations) {
      name = 'UpdateExamination';
    }
    else if (this.viewDiagnosis) {
      name = 'UpdateDiagnosis';
    }
    
    this.service.updatePMasterItem(this.form.value,name).subscribe((data: any) => {
      alert("Updated successfully");
      this.loadItemsList();     
    },
      (error) => {
        alert(error);
    });
  }

  }

  get f() {
    return this.form.controls;
  }

}
