<div class="main-wrapper">
    <div [ngClass]="{'dashboard-edit-appointment': editAppClass}">
        <div class="container" [ngClass]="{'dash-widget': editAppClass}">
            <button type="button" class="close" aria-label="Close" *ngIf="editAppClass" onclick="window.history.back()">
                <span aria-hidden="true">×</span>
            </button>
            <div class="row d-none" [ngClass]="{'d-block': editAppClass}">
                <div class="col-lg-8 offset-lg-0">
                    <h4 class="page-title">Update Appointment</h4>
                </div>
            </div>
            <form [formGroup]="editForm" (ngSubmit)="submit()" novalidate>
                <div class="form-row">
                    <div class="form-group col-8">
                        <label>Clinic Name <span class="text-danger">*</span></label>
                        <input formControlName="clinicname" name="clinicname" class="form-control" type="text"
                            readonly="readonly">
                        <div class="text-danger" *ngIf="(f.clinicnametouched || submitted) && f.clinicname.errors?.required">Clinic Name is required</div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-6">
                        <label>Appointment ID <span class="text-danger">*</span></label>
                        <input formControlName="appointmentid" class="form-control" type="text" value="APT-0001"
                            readonly>
                            <div class="text-danger" *ngIf="(f.appointmentid.touched || submitted) && f.appointmentid.errors?.required">Appointment id is required</div>
                    </div>
                    <div class="form-group col-6">
                        <label>Patient Name <span class="text-danger">*</span></label>
                        <input formControlName="patientname" class="form-control" type="text" readonly>
                        <input formControlName="patientid" class="form-control" type="hidden">
                        <div class="text-danger" *ngIf="(f.patientid.touched || submitted) && f.patientid.errors?.required">Patient Name is required</div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-6">
                        <label>Doctor</label>
                        <select [(ngModel)]="currentDoctorId" (change)="selectDoctorName()" formControlName="doctorid"
                            class="form-control select">
                            <option *ngFor="let item of Doctors" [ngValue]="item.DoctorId" [selected]="item.DoctorId == currentDoctorId">{{item.FirstName}} {{item.LastName}}</option>
                        </select>
                    </div>
                    <div class="form-group col-6">
                        <label>Department</label>
                        <input formControlName="departmentname" type="text" class="form-control" readonly>
                    </div> 
                </div>
                <div class="form-row">
                    <div class="form-group col-6">
                        <label>Date <span class="text-danger">*</span></label>
                        <div>
                            <input formControlName="date" type="date" class="form-control">
                        </div>
                        <div class="text-danger" *ngIf="(f.date.touched || submitted) && f.date.errors?.required">Select the Date of appointment</div>
                    </div>
                    <div class="form-group col-6">
                        <label>Time <span class="text-danger">*</span></label>
                        <div>
                            <input formControlName="time" type="time" class="form-control" id="datetimepicker3">
                        </div>
                        <div class="text-danger" *ngIf="(f.time.touched || submitted) && f.time.errors?.required">Select the Time of appointment</div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-6">
                        <label>Appointment Status</label>
                        <!-- <input formControlName="status" class="form-control" type="text" [readonly]="!appointmentCompleted" [attr.disabled]="appointmentCompleted"> -->
                        <select (change)="changeStatus($event)" formControlName="status" class="form-control select">
                            <option *ngFor="let item of statusList">{{item}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-6">
                        <label>Purpose of Visit</label>
                        <textarea formControlName="purposeofvisit" class="form-control"></textarea>
                    </div>
                    
                </div>
                <div class="m-t-20 text-center">
                    <button class="btn btn-primary submit-btn mr-1" type="submit" data-dismiss="modal" #closeAddExpenseModal (ngSubmit)="submit()">Update</button>
                    <button class="btn btn-primary submit-btn" type="button" 
                        (click)="deleteAppointment()">Delete</button>
                </div>
            </form>
        </div>
    </div>
</div>