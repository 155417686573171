import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-nav-menu',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  editPatientID: any;
  patientDetails: any;
  name:any;
  patientId:any;
  age: any;
  gender:any;
  currentUser:any;

  constructor(
    public service: SharedService,
    private route: ActivatedRoute,
    public datepipe: DatePipe,
    private authenticationService: AuthenticationService
  ) {this.currentUser = this.authenticationService.currentUserValue;}

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        this.editPatientID = params['patientId'];
      });
      if (this.editPatientID != undefined) {
        this.getPatientDetails();
      }  
  }
  getPatientDetails() {
    this.service.getPatientDetailsByID(this.editPatientID,this.currentUser.User.HospitalId)
      .subscribe(
        (data) => {
          if (data.LastName == null) {
            data.LastName = "";
          }
          this.patientDetails = data;
          this.name = this.patientDetails.FirstName+' '+this.patientDetails.LastName;
          this.patientId = this.patientDetails.PatientId;
          this.gender = this.patientDetails.Gender;

          if (this.patientDetails.DateOfBirth != null) {
           let patientDob = new Date(this.patientDetails.DateOfBirth);
           patientDob.setDate(patientDob.getDate());
           this.age = this.service.ageCalculator(this.datepipe.transform(patientDob, 'yyyy-MM-dd'));
          }
        },
        error => {
          console.log(error);
        });
  }
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
