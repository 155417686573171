<div class="main-wrapper">
  <div class="page-wrapper">    
    <div class="content">
      <div><button class="d-print-none btn btn-primary float-right" (click)="openPDF()">Print Bill</button></div>
      <div class="container" id="htmlData">
        <div class="selector-for-some-widget row">
          <div class="text-center col-9">
            <div class="font-weight-bold text-capitalize">{{hospitalName}}</div>
            <div>{{hospitalAddress}}</div>
            <div>Ph: {{hospitalPhonenumber}}, Email: {{hospitalEmail}}</div>
          </div>
          <div class="col-2">
            <table class="table table-bordered">
              <tr>
                <th class="text-center">InvoiceNo:<br>{{invoiceNo}}</th>
              </tr>
            </table>
          </div>
        </div>
        <hr class="border border-dark">
        <div>
          <div>
            <span class="font-weight-bold">Patient Details :</span><span class="text-capitalize">Mr. {{patientDetails}}</span>
            <div class="float-right"><span class="font-weight-bold">UHID No :</span><span class="text-capitalize"> {{UHIdNo}}</span></div><br>
            <span class="font-weight-bold">Address : </span>{{patientAddres}}
            <div class="float-right"><span class="font-weight-bold">Contact No : </span>{{patientContactNo}}</div><br>
            <span class="font-weight-bold">Consultant Doctor : </span>Dr. {{referralDoctorName}}
            <span class="float-right"><span class="font-weight-bold">Bill Date: </span><span>{{billDate}}</span></span>
            
          </div>
        </div>
        <hr class="border border-dark">

        <div>
          <div>
            <table class="table table-bordered">
              <tr class="table-primary">
                <th>Service Name</th>
                <th>Rate(Rs)</th>
                <th>Unit</th>
                <th>Discount(%)</th>
                <th>Final Rate(Rs)</th>
              </tr>
              <tr *ngFor="let item of servicesList">
                <th>{{item.ServiceName}}</th>
                <td>{{item.Amount}}</td>
                <td>{{item.Unit}}</td>
                <td>{{item.Discount}}</td>
                <td>{{item.finalAmount}}</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="float-right">Grand Total :<span>₹ {{grandTotal}}</span></div><br>
        <div class="row">
          <div class="col-4">Discount( % ) : <span>{{billDiscountPercentage}} </span></div>
          <div class="col-4">Discount Amount : <span>₹ {{discountAmount}}</span></div>
          <div class="col-4">Net Payable Amount : <span>₹ {{netPayableAmount}}</span></div>
        </div>
        <div class="row">
          <div class="col-4">
            Amount Paid : <span>₹ {{paidAmount}}</span>
          </div>
          <div class="col-4">
            Due Amount : <span>₹ {{dueAmount}}</span>
          </div>
        </div><br><br>
        <!-- <div class="row">
          <div class="col-md-6 offset-md-4">
            Payment Mode: <span>Cash</span>
          </div>
        </div> -->
        <div>
          <table class="table-sm table-bordered" *ngIf="paymentList != undefined ">
            <tr class="table-primary" *ngIf="paymentList.length > 0">
              <th>Date</th>
              <th>Amount Paid (Rs)</th>
              <th>Payment Mode</th>
            </tr>
            <tr *ngFor="let item of paymentList">
              <th>{{item.Billdate | date :'MMM d, y h:mm a'}}</th>
              <td class="text-center">{{item.PaidAmount}}</td>
              <td class="text-center">{{item.PaymentMethod}}</td>
            </tr>
          </table>
          <div class="text-danger" *ngIf="paymentList == undefined || paymentList.length == 0">No Payment details available</div>
        </div>
        <div class="font-weight-bold float-right">Signature</div>

        <!-- <hr class="border border-dark">
        <div class="row">
          <div class="col-4">Print Date : <span>{{todayDate | date :'MMM d, y h:mm a'}}</span></div>
          <div class="col-4">Bill Generated By : <span>{{userName}}</span></div>
          <div class="col-4 font-weight-bold">Signature </div>
        </div>
        <hr class="border border-dark"> -->

      </div>
    </div>
  </div>
</div>