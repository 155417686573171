import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IAppointment } from 'src/app/appointment/iappointment';
import { FormBuilder, FormGroup, FormControl,FormsModule, Validators } from '@angular/forms';
import { AppointmentModel } from '../appointment.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-edit-appointment',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './edit-appointment.component.html',
  styleUrls: ['./edit-appointment.component.css']
})
export class EditAppointmentComponent implements OnInit {

  id: number;
  editForm: FormGroup;
  appointmentModelObj: AppointmentModel = new AppointmentModel();
  appointmentData: any;
  Doctors: any = [];
  statusList: any = ["Pending", "Completed", "Missed"];
  appointmentCompleted: any = false;
  currentDoctorId:any =0;
  Departments: any = []; // Department Names
  submitted = false

  constructor(
    public service: SharedService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  }

  editAppClass: boolean = false;
  @Input() editAppointmentID: any;
  currentUser:any;
  ngOnInit(): void {
    if (JSON.stringify(this.currentUser) == '{}' && localStorage.getItem('currentUser') == undefined) {
      this.router.navigateByUrl('login');      
    }
    else{    
    this.route.queryParams.subscribe(
      params => {
        if (params['editAppointment'] != undefined) {
          this.editAppointmentID = params['editAppointment'];
          this.editAppClass = true;
        }
      });

      this.loadDoctorList();
      this.loadDepartmentsList();
    this.editForm = this.formBuilder.group({
      clinicname: ['', Validators.required],
      appointmentid: [''],
      patientname: ['', Validators.required],
      patientid: [''],
      departmentname: ['', Validators.required],
      doctorname: [''],
      date: ['', Validators.required],
      time: ['', Validators.required],
      status: ['', Validators.required],
      purposeofvisit: [''],
      hospitalId:[''],
      doctorid:[''],
      username:['']
    });

    this.getAppointment();
  }
}

  get f() {
    return this.editForm.controls;
  }
  get doctorname() {
    return this.editForm.get('doctorname');
  }

  submit() {
    if (this.editForm.controls.doctorid.value == "") {
      alert("Doctor name is mandatory !. Please select doctor");
      return;
     }
     if (this.editForm.controls.departmentname.value == "") {
       alert("Department name not provided");
       return;
     }

    this.editForm.value.date = this.editForm.value.date + ' ' + this.editForm.value.time;
    this.editForm.value.hospitalId = this.currentUser.User.HospitalId;
    
    this.submitted = true;
    if (this.editForm.invalid) {
      return;
    }
    this.service.updateAppointment(this.editForm.value).subscribe(res => {
      alert("Updated successfully!");
      //this.router.navigateByUrl('header/appointments');
      window.location.reload();
    })
  }
  getAppointment() {
    //this.id = this.route.snapshot.params['appintId'];
    this.id = this.editAppointmentID;
    this.service.getAppointmentDetails(this.id,this.currentUser.User.HospitalId).subscribe(data => {
      if(data != null){
      this.appointmentData = data;

      let appDate = new Date(this.appointmentData.Date);
      appDate.setDate(appDate.getDate());
      this.editForm.controls['date'].setValue(this.datePipe.transform(appDate, 'yyyy-MM-dd'));

      let now = new Date(this.appointmentData.Date);
      let hours = ("0" + now.getHours()).slice(-2);
      let minutes = ("0" + now.getMinutes()).slice(-2);
      let str = hours + ':' + minutes;
      this.editForm.controls['time'].setValue(str);

      this.editForm.controls['appointmentid'].setValue(this.appointmentData.AppointmentId);
      this.editForm.controls['clinicname'].setValue(this.appointmentData.ClinicName);
      this.editForm.controls['patientname'].setValue(this.appointmentData.Patient.FirstName+' '+this.appointmentData.Patient.LastName);
      this.editForm.controls['patientid'].setValue(this.appointmentData.PatientId);
      this.editForm.controls['departmentname'].setValue(this.appointmentData.DepartmentName);
      this.editForm.controls['doctorname'].setValue(this.appointmentData.DoctorName);
      this.editForm.controls['doctorid'].setValue(this.appointmentData.DoctorId);
      this.editForm.controls['status'].setValue(this.appointmentData.Status);
      this.editForm.controls['purposeofvisit'].setValue(this.appointmentData.PurposeOfVisit);
      if (this.appointmentData.Status.toLowerCase() == 'completed'){
        this.appointmentCompleted = true;
      }

      if (this.Doctors != undefined) {
         var desiredValue = this.Doctors.find((x: any) => x.DoctorId === this.appointmentData.DoctorId)
        this.editForm.controls['username'].setValue(desiredValue.FirstName+' '+desiredValue.LastName);
        this.currentDoctorId = this.appointmentData.DoctorId;
        this.getDepartmentId(this.currentDoctorId);        
      }
    }
  });
}

  loadDoctorList() {
    this.service.getDoctorsList(this.currentUser.User.HospitalId).subscribe(data => {
      this.Doctors = data;
    });
  }
  loadDepartmentsList() {
    this.service.getDepartmentsList(this.currentUser.User.HospitalId).subscribe(data => {
      this.Departments = data;
    });
  }
  selectDoctorName() {    
    this.currentDoctorId =  this.editForm.controls.doctorid.value;
    this.getDepartmentId(this.editForm.controls.doctorid.value);
  }
  getDepartmentId(id:any){
    var doctorObj = this.Doctors.filter((data: any) => {
      return data.DoctorId.toString().toLowerCase().match(id);
    });

    if(doctorObj != null){
      var departmentObj = this.Departments.filter((data: any) => {
       return data.DepartmentId.toString().toLowerCase().match(doctorObj[0].Speciality.toString().toLowerCase());
      });
      if(departmentObj !=null){
       this.editForm.controls.departmentname.setValue(departmentObj[0].DepartmentName);
      }
    }
  }

  changeStatus(e: any) {
    this.editForm.controls.status.setValue(e.target.value, {
      onlySelf: true
    });
  }

  deleteAppointment() {
    this.id = this.editAppointmentID;
    this.service.deleteAppointment(this.id,this.currentUser.User.HospitalId).subscribe(data => {
      this.appointmentData = data;
      alert("Appointment Deleted Successfully");
      this.router.navigate(['appointments']);
    });
  }
}
