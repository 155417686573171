import { Patient } from 'src/app/patient';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../shared.service';
import { ActivatedRoute, Router } from '@angular/router';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: '[app-printbill]',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './printbill.component.html',
  styleUrls: ['./printbill.component.css']
})
export class PrintbillComponent implements OnInit {

  currentUser: any;
  billId: any;
  servicesList: any;
  paymentList: any[];
  grandTotal: any = 0;
  billDiscountPercentage: any = 0;
  billDate: any;
  paidAmount: any = 0;
  netPayableAmount: any = 0;
  discountAmount: any = 0;
  dueAmount: any = 0;
  invoiceNo: any;
  userName: any;
  todayDate: any = new Date();
  hospitalName: any;
  hospitalAddress: any;
  hospitalEmail: any;
  hospitalPhonenumber: any;
  patientDetails: any;
  patientAddres: any;
  patientContactNo: any;
  referralDoctorName: any;
  datePipe: DatePipe = new DatePipe('en-US');
  UHIdNo:any;

  @ViewChild('htmlData') htmlData!: ElementRef;
  constructor(private service: SharedService, private route: ActivatedRoute, private router: Router) { this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}') }

  ngOnInit(): void {
    this.hospitalName = this.currentUser.User.Hospital.HospitalName;
    this.hospitalAddress = this.currentUser.User.Hospital.HospitalAddress;
    this.hospitalEmail = this.currentUser.User.Hospital.MailId;
    this.hospitalPhonenumber = this.currentUser.User.Hospital.Phonenumber;

    this.route.queryParams.subscribe(
      params => {
        this.billId = params['billId'];
      });
    if (this.billId != undefined) {
      this.todayDate = this.datePipe.transform(this.todayDate, "MMM d, y h:mm a");
      this.getBillDetails();
    }
  }

  getBillDetails() {
    this.service.getBillById(this.billId, this.currentUser.User.HospitalId)
      .subscribe(
        (data) => {
          if (data != null) {
            if (data.Bill != null) {
              //Patient Data
              this.patientDetails = data.Patient.FirstName + ' ' + data.Patient.LastName + ',' + data.Patient.Gender;
              if(data.Patient.Address != null){
              this.patientAddres = data.Patient.Address + ' ';
              }
              if(data.Patient.City != null){
              this.patientAddres = this.patientAddres + ' ' + data.Patient.City + ',' ;
              }
              if(data.Patient.State != null){
              this.patientAddres = data.Patient.Address + ' ' + data.Patient.State + ',' ;
              }
              if(data.Patient.PostalCode != null){
              this.patientAddres = (data.Patient.Address + ' ' + data.Patient.PostalCode).trim();
              }
              
              this.patientContactNo = data.Patient.PhoneNumber;
              this.referralDoctorName = data.Doctor.User.FirstName + ' ' + data.Doctor.User.LastName;
              this.UHIdNo= data.Patient.PatientId;
              //BillData              
              this.billDate = this.datePipe.transform(data.Bill.BillDate, "MMM d, y h:mm a");
              this.billDiscountPercentage = data.Bill.DiscountPercentage;
              this.paidAmount = data.Bill.PaidAmount;
              this.grandTotal = data.Bill.TotalAmount;
              this.netPayableAmount = ((this.grandTotal) - (this.grandTotal * (this.billDiscountPercentage / 100)));
              this.discountAmount = (this.grandTotal * (this.billDiscountPercentage / 100));
              this.dueAmount = this.grandTotal - this.paidAmount;
              this.invoiceNo = "Bill - " + data.Bill.BillId;
              this.userName = this.currentUser.User.FirstName + ' ' + this.currentUser.User.LastName;
            }

            if (data.ServiceDetails != null) {
              data.ServiceDetails.forEach((t: any) => {
                t.finalAmount = ((t.Amount * t.Unit) - ((t.Amount * t.Unit) * (t.Discount / 100)));
              });
              this.servicesList = data.ServiceDetails;
            }
            if (data.PaymentDetails != null && data.PaymentDetails.length > 0) {
              this.paymentList = data.PaymentDetails;
            }

          }

        });
  }

  public openPDF(): void {
    let DATA: any = document.getElementById('htmlData');
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 208;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 4;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      addFooters(PDF);
      PDF.save(this.invoiceNo+'.pdf');
    });

    const addFooters = (doc:any) => {
      const pageCount = doc.internal.getNumberOfPages()    
      doc.setFont('helvetica', 'italic')
      doc.setFontSize(8)
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.line(5, 280, 200, 280);
        doc.setLineWidth(0.5);
        doc.text('Print Date : ' + this.todayDate , doc.internal.pageSize.width / 14, 287, {
          align: 'left'
        });
        doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width / 2, 287, {
          align: 'center'
        });
        doc.text('clinics.ainetwork.in', doc.internal.pageSize.width/1.1, 287, {
          align: 'right'
        })
      }
    }

    this.router.navigate(['header/bills']);
  }

}
