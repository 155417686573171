<div class="main-wrapper">
  <div class="page-wrapper">
    <div class="content">
      <div class="table-responsive">

        <div class="row text-center">
          <div class="col-md-4">
            <div class="btn-group">
              <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay()">
                Previous
              </div>
              <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                Today
              </div>
              <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay()">
                Next
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
          </div>
          <div class="col-md-4">
            <div class="btn-group">
              <div class="btn btn-primary" (click)="setView(CalendarView.Month)"
                [class.active]="view === CalendarView.Month">
                Month
              </div>
              <div class="btn btn-primary" (click)="setView(CalendarView.Week)"
                [class.active]="view === CalendarView.Week">
                Week
              </div>
              <div class="btn btn-primary" (click)="setView(CalendarView.Day)"
                [class.active]="view === CalendarView.Day">
                Day
              </div>
            </div>
          </div>
        </div>
        <br />
        <div [ngSwitch]="view">
          <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
            [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
            (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"
            data-toggle="modal" data-target="#PatientAddEditModal">
          </mwl-calendar-month-view>
          <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
            [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)">
          </mwl-calendar-week-view>
          <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
            [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)">
          </mwl-calendar-day-view>
        </div>


        <br /><br /><br />        
        <ng-template #modalContent let-close="close">
          <div class="modal-header text-center">
            <h5 class="page-title col-lg-8 offset-lg-2">Update Appointment</h5>
            <button type="button" class="close" (click)="close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- <div>
                      Action:
                      <pre>{{ modalData?.action }}</pre>
                    </div>
                    <div>
                      Event:
                      <pre>{{ modalData?.event | json }}</pre>
                    </div>  -->
            <app-edit-appointment [editAppointmentID]="currentItem"></app-edit-appointment>
          </div>
        </ng-template>

      </div>
    </div>










    <div class="content" style="display:none">
      <div class="table-responsive">
        <table class="table table-border table-striped custom-table mb-0">
          <thead>
            <tr>
              <th>Appointment ID</th>
              <th>Patient Name</th>
              <th>Doctor Name</th>
              <th>Department Name</th>
              <th>Date</th>
              <th>Time</th>
              <!-- <th>Patient Email</th> -->
              <th>Patient Status</th>
              <th>Purpose of Visit</th>
              <th>Clinic Name</th>
              <th class="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of appointments; let i =index">
              <td>{{ item.appointmentid }}</td>
              <td>{{ item.patientname }}</td>
              <td>{{ item.doctorname }}</td>
              <td>{{ item.departmentname }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.time }}</td>
              <!-- <td>{{ item.email }}</td> -->
              <td>{{ item.status }}</td>
              <td>{{ item.purposeofvisit }}</td>
              <td>{{ item.clinicname }}</td>
              <td class="text-right">
                <div class="dropdown dropdown-action">
                  <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i
                      class="fa fa-ellipsis-v"></i></a>
                  <div class="dropdown-menu dropdown-menu-right">

                    <a class="dropdown-item" [routerLink]="['/editappointment/', 2]"><i class="fa fa-pencil m-r-5"></i>
                      Edit</a>
                    <a class="dropdown-item" (click)="deleteAppointment(item.appointmentid)"><i
                        class="fa fa-trash-o m-r-5"></i> Delete</a>

                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <h4 class="page-title">Add Schedule</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <form>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Category</label>
                  <select class="select">
                    <option>Personal</option>
                    <option>Hospital Visit</option>
                    <option>Meeting </option>
                    <option>Conference </option>
                    <option>Others </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Available Days</label>
                  <select class="select" multiple>
                    <option>Select Days</option>
                    <option>Sunday</option>
                    <option>Monday</option>
                    <option>Tuesday</option>
                    <option>Wednesday</option>
                    <option>Thursday</option>
                    <option>Friday</option>
                    <option>Saturday</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Start Time</label>
                  <div class="time-icon">
                    <input type="text" class="form-control" id="datetimepicker3">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>End Time</label>
                  <div class="time-icon">
                    <input type="text" class="form-control" id="datetimepicker4">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Additinal Information</label>
              <textarea cols="30" rows="4" class="form-control"></textarea>
            </div>

            <div class="m-t-20 text-center">
              <button class="btn btn-primary submit-btn">Create Schedule</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="sidebar-overlay" data-reff=""></div> -->

<script>
  $(function () {
    $('#datetimepicker3').datetimepicker({
      format: 'LT'
    });
  });

  $(function () {
    $('#datetimepicker3').datetimepicker({
      format: 'LT'
    });
    $('#datetimepicker4').datetimepicker({
      format: 'LT'
    });
  });
</script>