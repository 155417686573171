import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, SelectControlValueAccessor, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../../shared.service';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-title-medicines',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './title-medicines.component.html',
  styleUrls: ['./title-medicines.component.css']
})
export class TitleMedicinesComponent implements OnInit {
  
  frequencies: Array<any> = [
    { id: 1, name: 'Morning',value: 'Morning', isSelected: false },
    { id: 2, name: 'Afternoon',value: 'Afternoon', isSelected: false },
    { id: 3, name: 'Night',value:'Night', isSelected: false }
  ];

  form: FormGroup;
  viewDashboard: boolean = true;
  currentUser: any;
  submitText:any;
  doctorId:any;
  titleId:any;
  itemsList: any = [];
  searchName: any;
  SearchItemList: any = [];
  submitted: any;
  titleHeading: any = "Heading";
  addTitle:any;

  page: number = 1;
  pageSize = 5;

  constructor(
    public service: SharedService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser.User.RoleId == 2) {
      this.getDoctorDetails();
    }
    this.createForm();
  }

  ngOnInit(): void {
    if (JSON.stringify(this.currentUser) == '{}' && localStorage.getItem('currentUser') == undefined) {
      this.router.navigateByUrl('login');
    }
    else {
      this.route.queryParams.subscribe(
        params => {
          if (params['viewDashboard'] != undefined && params['viewDashboard'] == 'true') {
            this.viewDashboard = true;
            this.addTitle = "Add Medicine";
            this.titleId = params['titleId'];
            this.titleHeading = params['title'];
            
            if (this.doctorId != undefined && this.titleId) {
              this.loadMedicinesList();
            }
          }
        });
    }
  }

  createForm() {
    this.submitText = 'SAVE';
    this.viewDashboard = false;
    this.form = this.fb.group({
      id: new FormControl(''),
      doctorId: new FormControl(this.doctorId),
      hospitalId: new FormControl(this.currentUser.User.HospitalId),
      masterTitleId: new FormControl(parseInt(this.titleId)),
      medicineName: new FormControl('', Validators.required),
      frequency: new FormControl(''),
      noOfDays: new FormControl('', Validators.required),
      quantity: new FormControl('', Validators.required),
      instructions: new FormControl('', Validators.required),
      customText: new FormControl(''),
      //medicines: new FormArray([]),
      selectedFrequencies: new FormArray([])
    });
  }

  //const selectedFrequencies = (this.form.controls['selectedFrequencies'] as FormArray);

  getMedicineItem(id: any) {
    this.form.reset();
    this.searchName = "";
    this.form.controls.id.setValue(id);
    this.form.controls.doctorId.setValue(this.doctorId);
    this.form.controls.hospitalId.setValue(this.currentUser.User.HospitalId);
    this.form.controls.masterTitleId.setValue(this.titleId);

    this.service.getMedicine(id, this.form.value.masterTitleId).subscribe((data: any) => {
      if (data != null) {
        this.submitText = 'UPDATE';
        this.viewDashboard = false;
        this.form.controls.medicineName.setValue(data.MedicineName);
        this.form.controls.frequency.setValue(data.Frequency);
        this.form.controls.noOfDays.setValue(data.NoOfDays);
        this.form.controls.quantity.setValue(data.Quantity);
        this.form.controls.instructions.setValue(data.Instructions);
        this.form.controls.customText.setValue(data.CustomText);

        if(data.Frequency != null && data.Frequency != ""){
          var freArray = data.Frequency.split(',');
          freArray.forEach((element:any) => {
           this.selectedFrequencies.push( new FormControl(element.trim()));
           
           this.frequencies.filter((data: any) => {
            if(data.name.toString().toLowerCase().localeCompare(element.trim().toLowerCase()) == 0){
              data.isSelected = true;
            }
          });

          });
        }
      }
    },
      (error) => {
        alert(error);
      });
  }

  deleteItem(id: any) {
    this.searchName = "";
    this.service.deleteMedicine(id, parseInt(this.titleId)).subscribe((data: any) => {
      var result = data;
      alert("Deleted successfully");
      this.loadMedicinesList();
    });
  }

  onCheckboxChange(event: any) {
    if (event.target.checked) {
      this.selectedFrequencies.push( new FormControl(event.target.value));
      this.frequencies.filter((data: any) => {
        if(data.name.toString().toLowerCase().localeCompare(event.target.value.toLowerCase())== 0){
          data.isSelected = true;
        }
      });

    } else {
      const index = this.selectedFrequencies.controls.findIndex( x => x.value === event.target.value);
      this.selectedFrequencies.removeAt(index);

      this.frequencies.filter((data: any) => {
        if(data.name.toString().toLowerCase().localeCompare(event.target.value.toLowerCase()) != 0){
          data.isSelected = false;
        }
      });

    }
  }

  searchItem() {
    if (this.searchName == "") {
      this.ngOnInit();
    } else {
      this.itemsList = this.SearchItemList.filter((data: any) => {
        var name = data.MedicineName;
        return name.toString().toLowerCase().match(this.searchName.toLowerCase());
      })
    }
  }

  getDoctorDetails() {
    this.service.getDoctorDetailsById(this.currentUser.User.UserId, this.currentUser.User.HospitalId).subscribe((data: any) => {
      if (data != null) {
        this.doctorId = data.DoctorId;
        this.loadMedicinesList();
      }
    },
      (error) => {
        alert(error);
      });
  }

  loadMedicinesList() {
    this.service.getMedicinesList(this.titleId).subscribe((data: any) => {
      var result = data;
      this.itemsList = result;
      this.SearchItemList = result;
      this.viewDashboard = true;
    },
      (error) => {
        alert(error);
      });
  }

  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    if(this.form.value.selectedFrequencies.length > 0){
      var _frequency =",";
      this.form.value.selectedFrequencies.forEach((e:any) => {
        _frequency = _frequency + e + ",";
      });
      _frequency = _frequency.replace(/^,|,$/g, '');
      this.form.controls.frequency.setValue(_frequency);
    }
    
    if (this.form.value.id == null || this.form.value.id == '') {
      delete this.form.value.id;

      this.service.addMedicine(this.form.value).subscribe((data: any) => {
        alert("Added successfully");
        // for (let i = 0; i < this.frequencyList.length; i++) {
        //   this.frequencyList[i].isSelected = false;
        // }
        this.frequencies.filter((data: any) => {
          data.isSelected = false;
        });
        this.selectedFrequencies.clear();
        this.loadMedicinesList();
      },
        (error) => {
          alert(error);
        });
    }
    else {
      this.service.updateMedicine(this.form.value).subscribe((data: any) => {
        alert("Updated successfully");
        this.frequencies.filter((data: any) => {
          data.isSelected = false;
        });
        this.selectedFrequencies.clear();
        this.loadMedicinesList();
      },
        (error) => {
          alert(error);
        });
    }

  }

  get medicineList(): FormArray {
    return this.form.get('medicines') as FormArray;
  }
  get selectedFrequencies(): FormArray {
    return this.form.get('selectedFrequencies') as FormArray;
  }
  get f() {
    return this.form.controls;
  }

}
