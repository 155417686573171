<div class="page-wrapper">
  <div class="content">
    <form [formGroup]="userForm">
      <button type="submit" class="btn btn-primary btn-default mb-2 ml-1" style="float: right;" data-toggle="modal"
        data-target="#scheduleImmunization" (click)="createForm()">Schedule Immunization</button>
      <div class="mt-2"><strong class="h4 font-bold">Scheduled immunization:</strong></div>
      <div class="table-responsive">
        <table class="table table-border table-striped custom-table">
          <thead>
            <tr>
              <th scope="col">Scheduled Date</th>
              <th scope="col">Vaccine</th>
              <th scope="col">No of Dose</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let item of scheduledList | slice: (scheduledPage-1) * scheduledPageSize : (scheduledPage-1) * scheduledPageSize + scheduledPageSize">
              <td scope="row">{{item.ScheduledDate | date:"dd-MM-yyyy"}}</td>
              <td>{{item.Vaccine}}</td>
              <td>{{item.DoseFrequency}}</td>
              <td><button type="button" class="btn btn-info btn-sm" data-toggle="modal"
                  data-target="#scheduleImmunization" (click)="editSchedule(item.Id)">Edit</button><button type="button"
                  class="btn btn-danger ml-1 btn-sm" (click)="deleteScheduledImmunization(item.Id)">Delete</button></td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination *ngIf="scheduledList?.length" class="pagination justify-content-center"
          [collectionSize]="scheduledList.length" [(page)]="scheduledPage" [maxSize]="5" [pageSize]="scheduledPageSize">
          <ng-template ngbPaginationPrevious>Prev</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination>
      </div>

      <div class="mt-4 mb-2"><strong>Completed immunization:</strong></div>
      <button type="submit" class="btn btn-primary btn-default ml-1 mb-2" style="float: right;">Generate vaccine
        certificate</button>
      <button type="submit" class="btn btn-primary btn-default mb-2" style="float: right;" data-toggle="modal"
        data-target="#immunization" (click)="createForm()">Add Immunization</button>
      <div class="table-responsive">
        <table class="table table-border table-striped custom-table">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Age</th>
              <th scope="col">Brand</th>
              <th scope="col">Composition</th>
              <th scope="col">No of Dose</th>
              <th scope="col">Batch No</th>
              <th scope="col">Expiry Date</th>
              <th scope="col">Remarks</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of completedList | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
              <td scope="row">{{item.ImmunizationDate | date:"dd-MM-yyyy"}}</td>
              <td>{{item.Age}}</td>
              <td>{{item.Brand}}</td>
              <td>{{item.Composition}}</td>
              <td>{{item.NoOfDose}}</td>
              <td>{{item.BatchNo}}</td>
              <td>{{item.ExpiryDate | date:"MM-yyyy"}}</td>
              <td>{{item.Remarks}}</td>
              <td><button type="button" class="btn btn-info btn-sm" style="width: 59%;" data-toggle="modal"
                  data-target="#immunization" (click)="editImmunization(item.Id)">Edit</button><button type="button"
                  class="btn btn-danger mt-1 btn-sm" (click)="deleteCompletedImmunization(item.Id)">Delete</button></td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination *ngIf="completedList?.length" class="pagination justify-content-center"
          [collectionSize]="completedList.length" [(page)]="page" [maxSize]="5" [pageSize]="pageSize">
          <ng-template ngbPaginationPrevious>Prev</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>
        </ngb-pagination>
      </div>

      <!--Schedule Immunization Popup-->
      <div class="modal fade" id="scheduleImmunization" tabindex="-1" role="dialog" aria-labelledby="title"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="title">Schedule Immunization </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group" formGroupName="scheduleImmunization">
                <div hidden><input type="text" class="form-control" formControlName="patientId" />PatientId</div>
                <div class="form-row mt-3">
                  <div class="col-md-3">Date</div>
                  <div class="col-xs-6 col-sm-6"><input type="date" class="form-control"
                      formControlName="scheduleddate" /></div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-3">Vaccine</div>
                  <div class="col-xs-6 col-sm-6"><input type="text" class="form-control" formControlName="vaccine" />
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-3">No of dose</div>
                  <div class="col-xs-6 col-sm-6"><input type="text" class="form-control"
                      formControlName="dosefrequency" /></div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" (click)="saveSchedule()">{{buttonText}}</button>
            </div>
          </div>
        </div>
      </div>

      <!--Add Immunization Popup-->
      <div class="modal fade" id="immunization" tabindex="-1" role="dialog" aria-labelledby="immunizationTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="immunizationTitle"> Immunization </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group" formGroupName="addImmunization">
                <div hidden><input type="text" class="form-control" formControlName="patientId" />PatientId</div>
                <div class="form-row mt-3">
                  <div class="col-md-2">Date</div>
                  <div class="col-xs-6 col-sm-4"><input type="date" class="form-control" [min]="todayDate"
                      formControlName="immunizationdate" placeholder="dd-MM-yyyy" /></div>
                  <div class="col-md-2">Age</div>
                  <div class="col-xs-6 col-sm-4"><input type="text" class="form-control" formControlName="age"
                      readonly />
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-2">Brand</div>
                  <div class="col-xs-6 col-sm-4"><input type="text" class="form-control" formControlName="brand" />
                  </div>
                  <div class="col-md-2">Composition</div>
                  <div class="col-xs-6 col-sm-4"><input type="text" class="form-control"
                      formControlName="composition" />
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-2">No of dose</div>
                  <div class="col-xs-6 col-sm-4"><input type="text" class="form-control" formControlName="noofdose" />
                  </div>
                  <div class="col-md-2">Batch no</div>
                  <div class="col-xs-6 col-sm-4"><input type="text" class="form-control" formControlName="batchno" />
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-2">Exp date</div>
                  <div class="col-xs-6 col-sm-4"><input type="date" [min]="todayDate" class="form-control"
                      placeholder="dd-MM-yyyy" formControlName="expirydate" /></div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-2"> Remarks </div>
                  <div class="col-xs-6 col-sm-4"><input type="text" class="form-control" formControlName="remarks" />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" (click)="submit()">{{buttonText}}</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>