import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';

import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

//Sorting and Pagenation
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2OrderModule } from 'ng2-order-pipe';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';

// Calendar module for Appointments
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'; 
import { DatePipe } from '@angular/common';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { PatientComponent } from './Patient/patient.component';
import { AddEditPatientComponent } from './Patient/add-edit-patient/add-edit-patient.component';
import { ShowPatientComponent } from './Patient/show-patient/show-patient.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { AddAppointmentComponent } from './appointment/add-appointment/add-appointment.component';
import { EditAppointmentComponent } from './appointment/edit-appointment/edit-appointment.component';
import { PrescriptionComponent } from './prescription/prescription.component';
import { AddPrescriptionComponent } from './prescription/add-prescription/add-prescription.component';
import { DoctorProfileComponent } from './doctor-profile/doctor-profile.component';
import { StaffProfileComponent } from './staff-profile/staff-profile.component';
import { SettingsComponent } from './settings/settings.component';
import { SharedService } from './shared.service';

// Patient profile
import { HomeComponent } from './patientprofile/home/home.component';
import { AppointmentsComponent } from './patientprofile/appointments/appointments.component';
import { CommunicationComponent } from './patientprofile/communication/communication.component';
import { LabReportsComponent } from './patientprofile/lab-reports/lab-reports.component';
import { ImmunizationComponent } from './patientprofile/immunization/immunization.component';
import { ImagesComponent } from './patientprofile/images/images.component';
import { PaymentComponent } from './patientprofile/payment/payment.component';
import { NavMenuComponent } from './patientprofile/nav-menu/nav-menu.component';
import { PatientDetailsComponent } from './patientprofile/patient-details/patient-details.component';
import { BillComponent } from './bill/bill.component';
import { AddEditBillComponent } from './bill/add-edit-bill/add-edit-bill.component';
import { RegisterComponent } from './login/register/register.component';
import { DepartmentComponent } from './department/department.component';
import { PrintbillComponent } from './bill/printbill/printbill.component';
import { PrintPrescriptionComponent } from './prescription/print-prescription/print-prescription.component';
import { ComplaintComponent } from './PrescriptionMaster/complaint/complaint.component';
import { TitleDashboardComponent } from './PrescriptionMaster/title-dashboard/title-dashboard.component';
import { TitleAdvicesComponent } from './PrescriptionMaster/title-advices/title-advices.component';
import { TitleMedicinesComponent } from './PrescriptionMaster/title-medicines/title-medicines.component';
import { ClinicServicesComponent } from './clinic-services/clinic-services.component';

@NgModule({
  declarations: [
    LoginComponent,
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    PatientComponent,
    AddEditPatientComponent,
    ShowPatientComponent,
    AppointmentComponent,
    AddAppointmentComponent,
    PrescriptionComponent,
    AddPrescriptionComponent,
    EditAppointmentComponent,
    DoctorProfileComponent,
    StaffProfileComponent,
    SettingsComponent,
    HomeComponent,
    AppointmentsComponent,
    CommunicationComponent,
    LabReportsComponent,
    ImmunizationComponent,
    ImagesComponent,
    PaymentComponent,
    NavMenuComponent,
    PatientDetailsComponent,
    BillComponent,
    AddEditBillComponent,
    RegisterComponent,
    DepartmentComponent,
    PrintbillComponent,
    PrintPrescriptionComponent,
    ComplaintComponent,
    TitleDashboardComponent,
    TitleAdvicesComponent,
    TitleMedicinesComponent,
    ClinicServicesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    Ng2OrderModule,
    NgbModule,
    BrowserAnimationsModule,
    NgIdleKeepaliveModule.forRoot(),
    ModalModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgbModule,
  ],  
  providers: [
    SharedService,DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
