import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import{AuthenticationService} from '../_services/authentication.service';

@Component({
  selector: 'app-settings',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  currentUser: any;

  constructor(private authenticationService: AuthenticationService) {
    this.currentUser = this.authenticationService.currentUserValue;
   }

  ngOnInit(): void {    
  }

}
