import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../shared.service';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-clinic-services',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './clinic-services.component.html',
  styleUrls: ['./clinic-services.component.css']
})
export class ClinicServicesComponent implements OnInit {

  form: FormGroup;
  viewDashboard: boolean = true;
  currentUser: any;
  searchName: any;
  submitted: any;
  submitText: any;
  editService: boolean = false;
  doctorId: any;
  titleHeading: any = "Services";
  addTitle: any;
  searchItemList: any = [];
  servicesList: any = [];

  page: number = 1;
  pageSize = 5;

  constructor(
    public service: SharedService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUser = this.authenticationService.currentUserValue;    
    this.createForm();
    this.getServiceNames();
  }

  ngOnInit(): void {
    if (JSON.stringify(this.currentUser) == '{}' && localStorage.getItem('currentUser') == undefined) {
      this.router.navigateByUrl('login');
    }
    else {
      this.route.queryParams.subscribe(
        params => {
          if (params['viewDashboard'] != undefined && params['viewDashboard'] == 'true') {
            this.viewDashboard = true;
            this.addTitle = "Add Service";
          }
        });
    }
  }

  createForm() {
    this.submitText = 'SAVE';
    this.viewDashboard = false;
    this.form = this.fb.group({
      serviceId: new FormControl(''),
      name: new FormControl('', Validators.required),
      amount: new FormControl('', Validators.required),
      discountPercentage: new FormControl('', Validators.required),
      doctorId: new FormControl(''),
      hospitalId: new FormControl(''),
    });
  }

  getServiceNames() {
    this.service.getServices(this.currentUser.User.HospitalId)
      .subscribe(
        (data) => {
          if (data != null) {
            this.servicesList = data;
          };
          this.searchItemList = data;
          this.viewDashboard = true;
        },
        (error) => {
          alert(error);
        });
  }

  
  getItem(serviceId: any) {
    this.form.reset();
    this.searchName = "";
    this.form.controls.serviceId.setValue(serviceId);
    this.form.controls.doctorId.setValue(this.doctorId);
    this.form.controls.hospitalId.setValue(this.currentUser.User.HospitalId);

    this.service.getService(serviceId, this.form.value.hospitalId).subscribe((data: any) => {
      if (data != null) {
        var res = data.Data;
        this.editService = true;
        this.submitText = 'UPDATE';
        this.viewDashboard = false;
        this.form.controls.name.setValue(res.Name);
        this.form.controls.amount.setValue(res.Amount);
        this.form.controls.discountPercentage.setValue(res.DiscountPercentage);
      }
    },
      (error) => {
        alert(error);
      });
  }

  deleteItem(serviceId: any) {
    this.searchName = "";
    this.form.controls.doctorId.setValue(this.doctorId);
    this.form.controls.hospitalId.setValue(this.currentUser.User.HospitalId);

    this.service.deleteService(serviceId, this.currentUser.User.HospitalId).subscribe((data: any) => {
      var result = data;
      alert("Deleted successfully");
      this.getServiceNames();
    });
  }

  searchItem() {
    if (this.searchName == "") {
      this.getServiceNames();
    } else {
      this.servicesList = this.searchItemList.filter((data: any) => {
        var name = data.Name;
        return name.toString().toLowerCase().match(this.searchName.toLowerCase());
      })
    }
  }

  submit() {
    this.form.controls.doctorId.setValue(this.doctorId);
    this.form.controls.hospitalId.setValue(this.currentUser.User.HospitalId);

    this.submitted = true;
    for (let control in this.form.controls) {
      if (this.form.controls[control].errors) {
        console.log(control)
      }
    }
    if (this.form.invalid) {
      return;
    }

    if (this.form.value.serviceId == null || this.form.value.serviceId == '') {
      delete this.form.value.serviceId;

      this.service.addService(this.form.value).subscribe((data: any) => {
        alert("Added successfully");
        this.getServiceNames();
      },
        (error) => {
          alert(error);
        });
    }
    else {
      this.service.updateService(this.form.value).subscribe((data: any) => {
        alert("Updated successfully");
        this.getServiceNames();
      },
        (error) => {
          alert(error);
        });
    }

  }

  get f() {
    return this.form.controls;
  }

}
