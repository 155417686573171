import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from './../_services/authentication.service';
import { User } from './../_models/user';

@Component({
  selector: 'app-header',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  currentUser: User;
  hospitalName:any;

  constructor(public router: Router,private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
   }

  ngOnInit(): void {
    this.hospitalName = this.currentUser.User.Hospital.HospitalName;
  }

  logMeOut(){
    this.authenticationService.logout();
        this.router.navigate(['/login']);
  }

}
