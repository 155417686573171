<div class="main-wrapper account-wrapper">
    <div class="account-page">
        <div class="account-center">
            <div class="account-box">
                <form [formGroup]="loginForm" class="form-signin">
                    <div class="account-logo">
                        <a href="#"><img src="assets/img/AccountLogo.png" alt=""></a>
                    </div>
                    <h2 style="text-align:center">Ai Clinics</h2>
                    <div class="form-group">
                        <label>User Name</label>
                        <input type="text" autofocus="" class="form-control" formControlName="userName"
                            [ngClass]="{ 'is-invalid': submitted && f.userName.errors }">
                        <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                            <div *ngIf="f.userName.errors.required">User Name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input type="password" formControlName="password" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                        </div>
                    </div>
                    <div class="form-group text-right" data-toggle="modal" data-target="#exampleModalCenter">
                        <a href="#!">Forgot your password?</a>                        
                    </div>

                    <div class="form-group text-center">
                        <!-- <button type="submit" (click)="login()" class="btn btn-primary">Login</button> -->
                        <button [disabled]="loading" class="btn btn-primary account-btn" (click)="login()">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Login
                        </button>
                        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                    </div>

                    <div class="text-center register-link">
                        Don't have an account? <a href="register">Register Now</a>
                    </div>

                    <!-- Modal -->
                    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title w-100 text-center" id="exampleModalCenterTitle">Forgot Password</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group">
                                        <label>User Name</label>
                                        <input type="text" autofocus="" class="form-control" formControlName="userName"
                                            [ngClass]="{ 'is-invalid': submitted && f.userName.errors }">
                                        <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                                            <div *ngIf="f.userName.errors.required">User Name is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="password">New Password</label>
                                        <input type="password" formControlName="password" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">Password is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="forgotPassword()">Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>