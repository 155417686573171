<div [ngClass]="{'patient-wrapper': fromNavbar}">
    <!-- <div class="header">
        <div class="header-left">
            <a href="index-2.html" class="logo">
                <img src="assets/img/logo.png" width="35" height="35" alt=""> <span>Preclinic</span>
            </a>
        </div>
        <a id="toggle_btn" href="javascript:void(0);"><i class="fa fa-bars"></i></a>
        <a id="mobile_btn" class="mobile_btn float-left" href="#sidebar"><i class="fa fa-bars"></i></a>
        <ul class="nav user-menu float-right">
            <li class="nav-item dropdown d-none d-sm-block">
                <a href="#" class="dropdown-toggle nav-link" data-toggle="dropdown"><i class="fa fa-bell-o"></i> <span class="badge badge-pill bg-danger float-right">3</span></a>
                <div class="dropdown-menu notifications">
                    <div class="topnav-dropdown-header">
                        <span>Notifications</span>
                    </div>
                    <div class="drop-scroll">
                        <ul class="notification-list">
                            <li class="notification-message">
                                <a href="activities.html">
                                    <div class="media">
                                        <span class="avatar">
                                            <img alt="John Doe" src="assets/img/user.jpg" class="img-fluid rounded-circle">
                                        </span>
                                        <div class="media-body">
                                            <p class="noti-details"><span class="noti-title">John Doe</span> added new task <span class="noti-title">Patient appointment booking</span></p>
                                            <p class="noti-time"><span class="notification-time">4 mins ago</span></p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li class="notification-message">
                                <a href="activities.html">
                                    <div class="media">
                                        <span class="avatar">V</span>
                                        <div class="media-body">
                                            <p class="noti-details"><span class="noti-title">Tarah Shropshire</span> changed the task name <span class="noti-title">Appointment booking with payment gateway</span></p>
                                            <p class="noti-time"><span class="notification-time">6 mins ago</span></p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li class="notification-message">
                                <a href="activities.html">
                                    <div class="media">
                                        <span class="avatar">L</span>
                                        <div class="media-body">
                                            <p class="noti-details"><span class="noti-title">Misty Tison</span> added <span class="noti-title">Domenic Houston</span> and <span class="noti-title">Claire Mapes</span> to project <span class="noti-title">Doctor available module</span></p>
                                            <p class="noti-time"><span class="notification-time">8 mins ago</span></p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li class="notification-message">
                                <a href="activities.html">
                                    <div class="media">
                                        <span class="avatar">G</span>
                                        <div class="media-body">
                                            <p class="noti-details"><span class="noti-title">Rolland Webber</span> completed task <span class="noti-title">Patient and Doctor video conferencing</span></p>
                                            <p class="noti-time"><span class="notification-time">12 mins ago</span></p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li class="notification-message">
                                <a href="activities.html">
                                    <div class="media">
                                        <span class="avatar">V</span>
                                        <div class="media-body">
                                            <p class="noti-details"><span class="noti-title">Bernardo Galaviz</span> added new task <span class="noti-title">Private chat module</span></p>
                                            <p class="noti-time"><span class="notification-time">2 days ago</span></p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="topnav-dropdown-footer">
                        <a href="activities.html">View all Notifications</a>
                    </div>
                </div>
            </li>
            <li class="nav-item dropdown d-none d-sm-block">
                <a href="javascript:void(0);" id="open_msg_box" class="hasnotifications nav-link"><i class="fa fa-comment-o"></i> <span class="badge badge-pill bg-danger float-right">8</span></a>
            </li>
            <li class="nav-item dropdown has-arrow">
                <a href="#" class="dropdown-toggle nav-link user-link" data-toggle="dropdown">
                    <span class="user-img"><img class="rounded-circle" src="assets/img/user.jpg" width="40" alt="Admin">
                        <span class="status online"></span></span>
                    <span>Admin</span>
                </a>
                <div class="dropdown-menu">
                    <a class="dropdown-item" href="profile.html">My Profile</a>
                    <a class="dropdown-item" href="edit-profile.html">Edit Profile</a>
                    <a class="dropdown-item" href="settings.html">Settings</a>
                    <a class="dropdown-item" href="login.html">Logout</a>
                </div>
            </li>
        </ul>
        <div class="dropdown mobile-user-menu float-right">
            <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
            <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="profile.html">My Profile</a>
                <a class="dropdown-item" href="edit-profile.html">Edit Profile</a>
                <a class="dropdown-item" href="settings.html">Settings</a>
                <a class="dropdown-item" href="login.html">Logout</a>
            </div>
        </div>
    </div> -->
    <div class="row">
        <div [ngClass]="{'col-lg-9 offset-lg-2 dash-widget': fromNavbar}">
            <button type="button" class="close" aria-label="Close" *ngIf="fromNavbar" onclick="window.history.back()">
                <span aria-hidden="true">×</span>
            </button>
            <div class="row d-none" [ngClass]="{'d-block': fromNavbar}">
                <div class="col-lg-10 offset-lg-0">
                    <h4 class="page-title">Add Patient</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Patient ID</label>
                        <input class="form-control" name="PatientId" type="text" [(ngModel)]="PatientId" readonly>
                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="image-upload-container">Profile Picture</label>

                        <div class="profile-upload">
                            <div class="upload-input">
                                <!-- <input type="file" class="form-control" [(ngModel)]="ProfilePic" /> -->
                                <input #imageInput type="file" class="form-control col-sm-12" accept="image/*"
                                    (change)="processFile(imageInput)">
                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="form-group">
                        <label>First Name <span class="text-danger">*</span></label>
                        <input class="form-control" name="FirstName" type="text" [(ngModel)]="FirstName" placeholder="FirstName"/>
                    </div>
                    <!-- <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert">
                        <div *ngIf="name.errors?.['required']">FirstName is required</div>
                    </div> -->
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Last Name </label>
                        <input class="form-control" type="text" [(ngModel)]="LastName" placeholder="LastName" />
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Email </label>
                        <input class="form-control" type="text" [(ngModel)]="Email" ngDefaultControl
                            placeholder="Email" />
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Mobile Number <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [(ngModel)]="PhoneNumber" />
                    </div>                    
                </div>

                <div class="col-sm-6">
                    <div class="form-group">
                        <div class="input-group">
                            <label style="margin-right:1rem">Date of Birth</label>
                            <input class="form-control" type="date" placeholder="DD-MM-YYYY" [(ngModel)]="DateOfBirth"
                                name="dp" />
                        </div>
                    </div>
                </div>
                <!-- <div class="col-sm-2">
                                <div class="form-group">
                                    <label>Years</label>
                                    <input class="form-control" name="yearAge" type="text" [(ngModel)]="yearAge" ngDefaultControl />
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label>Months</label>
                                    <input class="form-control" name="monthAge" type="text" [(ngModel)]="monthAge" ngDefaultControl />{{monthAge}}
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="form-group">
                                    <label>Days</label>
                                    <input class="form-control" name="dateAge" type="text" [(ngModel)]="dateAge" ngDefaultControl />{{dateAge}}
                                </div>
                            </div> -->
                <!-- <div class="col-sm-8" style="max-width: fit-content;">
                                <div class="form-group gender-select">
                                    <label class="col-form-label col-sm-3" style="padding-left: 0px; max-width: fit-content;"> Gender </label>
                                    <div class="form-check-inline">
                                        <label for="male" class="form-check-label">                                            
                                            <input type="radio" id="male" name="gender" formControlName="gender" class="form-check-input" [(ngModel)]="Gender"/>Male                                            
                                        </label>
                                    </div>
                                    <div class="form-check-inline">
                                        <label for="female" class="form-check-label">
                                            <input type="radio" id="female" name="gender" formControlName="gender" class="form-check-input" [(ngModel)]="Gender" />Female
                                        </label>
                                    </div>
                                    <div class="form-check-inline">
                                        <label for="other" class="form-check-label">
                                            <input type="radio" id="other" name="gender" formControlName="gender" class="form-check-input" [(ngModel)]="Gender" />Other
                                        </label>
                                    </div>
                                </div>
                            </div> -->

                <div class="col-sm-8" style="max-width: fit-content;">
                    <div class="form-group">

                        <label class="col-form-label col-sm-3 form-check-label"
                            style="padding-left: 0px; max-width: fit-content;"> Gender  <span class="text-danger">*</span></label>
                        <div class="form-check-inline">
                            <label class="form-check-label" style="margin-left: 1rem;" *ngFor="let item of GenderList">
                                <input type="radio" name="Gender" [(ngModel)]="Gender" value="{{item.name}}"
                                    class="form-check-input" />{{item.name}}
                            </label>
                        </div>                        
                    </div>
                </div>

                <div class="col-sm-10">
                    <div class="form-group">
                        <label>Blood Group</label>
                        <select class="custom-select" [(ngModel)]="Bloodgroup">
                            <option value="">Select Blood group</option>
                            <option *ngFor="let bgName of BloodGroupList">
                                {{bgName.Name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label>Address</label>
                                <input type="text" class="form-control" [(ngModel)]="Address" />
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-5">
                            <div class="form-group">
                                <label>City</label>
                                <input type="text" class="form-control" [(ngModel)]="City" />
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4">
                            <div class="form-group">
                                <label>State</label>
                                <input type="text" class="form-control" [(ngModel)]="State" />
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3">
                            <div class="form-group">
                                <label>Postal Code</label>
                                <input type="text" class="form-control" [(ngModel)]="PostalCode" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-5">
                    <div class="form-group row">
                        <label class="col-form-label col-md-4">Documents</label>
                        <div class="col-md-12">
                            <div class="profile-upload">
                                <div class="upload-input">
                                    <input type="file" class="form-control" [(ngModel)]="Files" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-sm-12">
                                <div class="form-group row">
                                    <label class="col-form-label col-md-2">Profile Picture</label>
                                    <div class="col-md-10">
                                    <div class="profile-upload">                                        
                                        <div class="upload-input">
                                            <input type="file" class="form-control" [(ngModel)]="ProfilePic" />
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div> -->
                <div class="col-sm-6 col-md-6 col-lg-5">
                    <div class="form-group row">
                        <label class="col-form-label col-md-4">Hospital <span class="text-danger">*</span></label>
                        <!-- <div class="col-md-10">
                            <select class="form-control select" [(ngModel)]="HospitalName">
                                <option *ngFor="let name of HospitalList" [ngValue]="name.HospitalName">
                                    {{name.HospitalName}}
                                </option>
                            </select>
                        </div> -->
                        <input class="form-control" type="text" [(ngModel)]="HospitalName" ngDefaultControl
                            placeholder="HospitalName" readonly/>
                    </div>
                </div>
            </div>

            <div class="m-t-20 text-center">
                <button class="btn btn-primary submit-btn" data-dismiss="modal" #closeAddExpenseModal (click)="addPatient()" *ngIf="!ActivateEditPatient">Create
                    Patient</button>
            </div>
            <div class="m-t-20 text-center">
                <button class="btn btn-primary submit-btn" data-dismiss="modal" #closeAddExpenseModal (click)="updatePatient()" *ngIf="ActivateEditPatient">Update
                    Patient</button>
            </div>
        </div>
    </div>

    <!-- <div class="notification-box">
            <div class="msg-sidebar notifications msg-noti">
                <div class="topnav-dropdown-header">
                    <span>Messages</span>
                </div>
                <div class="drop-scroll msg-list-scroll" id="msg_list">
                    <ul class="list-box">
                        <li>
                            <a href="chat.html">
                                <div class="list-item">
                                    <div class="list-left">
                                        <span class="avatar">R</span>
                                    </div>
                                    <div class="list-body">
                                        <span class="message-author">Richard Miles </span>
                                        <span class="message-time">12:28 AM</span>
                                        <div class="clearfix"></div>
                                        <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="chat.html">
                                <div class="list-item new-message">
                                    <div class="list-left">
                                        <span class="avatar">J</span>
                                    </div>
                                    <div class="list-body">
                                        <span class="message-author">John Doe</span>
                                        <span class="message-time">1 Aug</span>
                                        <div class="clearfix"></div>
                                        <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="chat.html">
                                <div class="list-item">
                                    <div class="list-left">
                                        <span class="avatar">T</span>
                                    </div>
                                    <div class="list-body">
                                        <span class="message-author"> Tarah Shropshire </span>
                                        <span class="message-time">12:28 AM</span>
                                        <div class="clearfix"></div>
                                        <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="chat.html">
                                <div class="list-item">
                                    <div class="list-left">
                                        <span class="avatar">M</span>
                                    </div>
                                    <div class="list-body">
                                        <span class="message-author">Mike Litorus</span>
                                        <span class="message-time">12:28 AM</span>
                                        <div class="clearfix"></div>
                                        <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="chat.html">
                                <div class="list-item">
                                    <div class="list-left">
                                        <span class="avatar">C</span>
                                    </div>
                                    <div class="list-body">
                                        <span class="message-author"> Catherine Manseau </span>
                                        <span class="message-time">12:28 AM</span>
                                        <div class="clearfix"></div>
                                        <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="chat.html">
                                <div class="list-item">
                                    <div class="list-left">
                                        <span class="avatar">D</span>
                                    </div>
                                    <div class="list-body">
                                        <span class="message-author"> Domenic Houston </span>
                                        <span class="message-time">12:28 AM</span>
                                        <div class="clearfix"></div>
                                        <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="chat.html">
                                <div class="list-item">
                                    <div class="list-left">
                                        <span class="avatar">B</span>
                                    </div>
                                    <div class="list-body">
                                        <span class="message-author"> Buster Wigton </span>
                                        <span class="message-time">12:28 AM</span>
                                        <div class="clearfix"></div>
                                        <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="chat.html">
                                <div class="list-item">
                                    <div class="list-left">
                                        <span class="avatar">R</span>
                                    </div>
                                    <div class="list-body">
                                        <span class="message-author"> Rolland Webber </span>
                                        <span class="message-time">12:28 AM</span>
                                        <div class="clearfix"></div>
                                        <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="chat.html">
                                <div class="list-item">
                                    <div class="list-left">
                                        <span class="avatar">C</span>
                                    </div>
                                    <div class="list-body">
                                        <span class="message-author"> Claire Mapes </span>
                                        <span class="message-time">12:28 AM</span>
                                        <div class="clearfix"></div>
                                        <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="chat.html">
                                <div class="list-item">
                                    <div class="list-left">
                                        <span class="avatar">M</span>
                                    </div>
                                    <div class="list-body">
                                        <span class="message-author">Melita Faucher</span>
                                        <span class="message-time">12:28 AM</span>
                                        <div class="clearfix"></div>
                                        <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="chat.html">
                                <div class="list-item">
                                    <div class="list-left">
                                        <span class="avatar">J</span>
                                    </div>
                                    <div class="list-body">
                                        <span class="message-author">Jeffery Lalor</span>
                                        <span class="message-time">12:28 AM</span>
                                        <div class="clearfix"></div>
                                        <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="chat.html">
                                <div class="list-item">
                                    <div class="list-left">
                                        <span class="avatar">L</span>
                                    </div>
                                    <div class="list-body">
                                        <span class="message-author">Loren Gatlin</span>
                                        <span class="message-time">12:28 AM</span>
                                        <div class="clearfix"></div>
                                        <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="chat.html">
                                <div class="list-item">
                                    <div class="list-left">
                                        <span class="avatar">T</span>
                                    </div>
                                    <div class="list-body">
                                        <span class="message-author">Tarah Shropshire</span>
                                        <span class="message-time">12:28 AM</span>
                                        <div class="clearfix"></div>
                                        <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="topnav-dropdown-footer">
                    <a href="chat.html">See all messages</a>
                </div>
            </div>
        </div> -->

</div>
<div class="sidebar-overlay" data-reff=""></div>