import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-immunization',
  templateUrl: './immunization.component.html',
  styleUrls: ['./immunization.component.css']
})
export class ImmunizationComponent implements OnInit {

  userForm: FormGroup;
  patientId: any;
  scheduledList: any;
  completedList: any;
  age: any;
  buttonText: any;
  page: number = 1;
  pageSize = 5;
  scheduledPage: number = 1;
  scheduledPageSize = 5;
  todayDate:any = this.datePipe.transform(new Date(), "yyyy-MM-dd");

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private service: SharedService, private datePipe: DatePipe,) { this.createForm(); }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        this.patientId = params['patientId'];
      });
    if (this.patientId != undefined) {
      this.getImmunizationDetails();
    }
  }

  public createForm() {
    this.buttonText = 'Save';
    this.userForm = this.fb.group({
      scheduleImmunization: this.fb.group({
        patientId: [''],
        id: [''],
        scheduleddate: [''],
        vaccine: [''],
        dosefrequency: ['']
      }),
      addImmunization: this.fb.group({
        patientId: [''],
        id: [''],
        immunizationdate: [this.datePipe.transform(new Date(), "yyyy-MM-dd")],
        age: [this.service.ageCalculator(new Date())],
        brand: [''],
        composition: [''],
        noofdose: [''],
        batchno: [''],
        expirydate: [''],
        remarks: ['']
      })
    })
  };

  getImmunizationDetails() {
    this.service.getScheduledImmunizationsList(this.patientId)
      .subscribe(
        (data) => {
          if (data != null) {
            this.scheduledList = data;
          };
        })

    this.service.getImmunizationsList(this.patientId)
      .subscribe(
        (data) => {
          if (data != null) {
            this.completedList = data;
            for (var item of this.completedList) {
              item.Age = this.service.ageCalculator(item.ImmunizationDate);
            }
          };
        })
  };

  editSchedule(id: any) {
    this.buttonText = 'Update';
    this.service.getScheduledImmunization(id)
      .subscribe(
        (data) => {
          if (data != null) {
            this.userForm.controls.scheduleImmunization.setValue({
              id: data.Id, patientId: data.PatientId, scheduleddate: this.datePipe.transform(data.ScheduledDate, "yyyy-MM-dd"), vaccine: data.Vaccine, dosefrequency: data.DoseFrequency
            });
          }
        });
  }
  editImmunization(id: any) {
    this.buttonText = 'Update';
    this.service.getImmunization(id)
      .subscribe(
        (data) => {
          if (data != null) {
            this.age = this.service.ageCalculator(data.ImmunizationDate);
            this.userForm.controls.addImmunization.setValue({
              id: data.Id, patientId: data.PatientId, immunizationdate: this.datePipe.transform(data.ImmunizationDate, "yyyy-MM-dd"), age: this.age, brand: data.Brand,
              composition: data.Composition, noofdose: data.NoOfDose, batchno: data.BatchNo, expirydate: this.datePipe.transform(data.ExpiryDate, "yyyy-MM-dd"),
              remarks: data.Remarks
            });
          }
        });
  }

  get f() {
    return this.userForm.controls;
  }

  saveSchedule() {
    this.userForm.value.scheduleImmunization.patientId = this.patientId;
    var val = this.userForm.value;
    if (val.scheduleImmunization.id == '' && val.scheduleImmunization.id != undefined) {
      delete val.scheduleImmunization.id;
      this.service.addSchedule(val.scheduleImmunization).subscribe({
        next: res => {
          alert("Immunization details added !!");
          this.ngOnInit();
        },
        error: (error: any) => {
          console.error('There was an error in add Immunization Method!', error.message);
        }
      });
    }
    else {
      this.service.updateSchedule(val.scheduleImmunization).subscribe({
        next: res => {
          alert("Scheule immunization details updated !!");
          this.ngOnInit();
        },
        error: (error: any) => {
          console.error('There was an error in updateSchedule Method!', error.message);
        }
      });
    }
  }

  submit() {
    this.userForm.value.addImmunization.patientId = this.patientId;
    var val = this.userForm.value;
    if (val.addImmunization.id == '' && val.addImmunization.id != undefined) {
      delete val.addImmunization.id;
      this.service.addImmunization(val.addImmunization).subscribe({
        next: res => {
          alert("Immunization details added !!");
          this.ngOnInit();
        },
        error: (error: any) => {
          console.error('There was an error in add Immunization Method!', error.message);
        }
      });
    }
    else {
      this.service.updateImmunization(val.addImmunization).subscribe({
        next: res => {
          alert("Immunization details added !!");
          this.ngOnInit();
        },
        error: (error: any) => {
          console.error('There was an error in add Immunization Method!', error.message);
        }
      });
    }
  }

  deleteCompletedImmunization(id:any) { 
    this.service.deleteImmunization(id).subscribe({
      next: res => {
        alert("Immunization details deleted !!");
        this.ngOnInit();
      },
      error: (error: any) => {
        console.error('There was an error in delete completed immunization method!', error.message);
      }
    });
  }

  deleteScheduledImmunization(id:any) { 
    this.service.deleteScheduledImmunization(id).subscribe({
      next: res => {
        alert("Scheduled immunization details deleted !!");
        this.ngOnInit();
      },
      error: (error: any) => {
        console.error('There was an error in delete scheduled immunization method!', error.message);
      }
    });
  }

}
