import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SharedService } from '../shared.service';
import { AuthenticationService } from './../_services/authentication.service';

@Component({
  selector: 'app-staff-profile',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './staff-profile.component.html',
  styleUrls: ['./staff-profile.component.css']
})
export class StaffProfileComponent implements OnInit {

  form: FormGroup;
  editStaffId: any;
  staffDetails: any = [];
  viewDashboard: any = false;
  staffList: any = [];
  submitText: any;
  submitted = false;
  currentUser: any;

  constructor(
    public service: SharedService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.createForm();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        if (params['viewDashboard'] != undefined && params['viewDashboard'] == 'true') {
          this.viewDashboard = true;
          this.getListOfStaff();
        }
      });
  }

  createForm() {
    this.submitText = 'SAVE';
    this.viewDashboard = false;
    this.form = this.fb.group({
      RoleId: new FormControl(3),
      userid: new FormControl(''),
      staffid: new FormControl(''),
      lastname: new FormControl('', Validators.required),
      firstname: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      dob: new FormControl('', Validators.required),
      age: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      phonenumber: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      postalcode: new FormControl('', Validators.required),
      profileImagePath: new FormControl(''),
      DocumentIdPath: new FormControl(''),
      registrationNumber: new FormControl(''),
      designation: new FormControl('', Validators.required),
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required)
    }, {
      validator: this.MustMatch('password', 'confirmPassword')
    });
  }
  getStaffProfile(id: any) {
    this.form.reset();
    this.service.getStaffId(id, this.currentUser.User.HospitalId).subscribe((data: any) => {
      this.submitText = 'UPDATE';
      this.viewDashboard = false;
      this.staffDetails = data;
      var _age = this.service.ageCalculator(this.staffDetails.Dob);
      this.form.controls.userid.setValue(data.UserId);
      this.form.controls.staffid.setValue(data.StaffId);
      this.form.controls.RoleId.setValue(data.RoleId);
      this.form.controls.firstname.setValue(data.FirstName);
      this.form.controls.lastname.setValue(data.LastName);
      this.form.controls.gender.setValue(data.Gender);
      this.form.controls.age.setValue(_age);
      this.form.controls.profileImagePath.setValue(data.ProfileImagePath);
      this.form.controls.DocumentIdPath.setValue(data.DocumentIdPath);
      this.form.controls.registrationNumber.setValue(data.RegistrationNumber);
      this.form.controls.designation.setValue(data.Designation);

      this.form.controls.email.setValue(data.Email);
      this.form.controls.phonenumber.setValue(data.PhoneNumber);
      this.form.controls.address.setValue(data.Address);
      this.form.controls.city.setValue(data.City);
      this.form.controls.state.setValue(data.State);
      this.form.controls.postalcode.setValue(data.PostalCode);
      this.form.controls.username.setValue(data.UserName);
      this.form.controls.password.setValue(data.Password);
      this.form.controls.confirmPassword.setValue(data.Password);

      let _dob = new Date(data.Dob);
      _dob.setDate(_dob.getDate());
      this.form.controls['dob'].setValue(this.datePipe.transform(_dob, 'yyyy-MM-dd'));
    });
  }
  getListOfStaff() {
    this.service.getStaffList(this.currentUser.User.HospitalId).subscribe((data: any) => {
      this.staffList = data;
      this.viewDashboard = true;
    });
  }
  deleteStaff(id: any) {
    this.service.deleteStaff(id, this.currentUser.User.HospitalId).subscribe((data: any) => {
      var res = data;
      alert("Profile deleted successfully");
      this.getListOfStaff();
    });
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
  get f() {
    return this.form.controls;
  }

  submit() {
    this.submitted = true;
    this.form.value.hospitalId = this.currentUser.User.HospitalId;

    for (let control in this.form.controls) {
      if (this.form.controls[control].errors) {
        console.log(control)
      }
    }
    if (this.form.invalid) {
      return;
    }
    if (this.form.value.staffid == null || this.form.value.staffid == '') {
      delete this.form.value.staffid;
      delete this.form.value.userid;
      this.service.addStaff(this.form.value).subscribe((data: any) => {
        var res = data;
        alert("Profile added successfully");
        this.getListOfStaff();
      },
        (error) => {
          console.log(error);
          alert(error);
        });
    }
    else {
      this.service.updateStaff(this.form.value).subscribe((data: any) => {
        var res = data;
        alert("Profile updated");
        this.getListOfStaff();
      },
        (error) => {
          console.log(error);
          alert(error);
        });
    }
  }

}
