<div class="page-wrapper">
  <div class="content">
    <div class="float-right">
      <label>Upload</label>
      <div><input type="file"></div>
    </div>
    <div>
      <img src="../../assets/pres1.png" alt="..." class="img-thumbnail" data-toggle="modal" data-target="#viewImg">
      <img src="../../assets/prc2.jpg" alt="..." class="img-thumbnail" data-toggle="modal" data-target="#viewImg">
      <img src="../../assets/prc3.jpg" alt="..." class="img-thumbnail" data-toggle="modal" data-target="#viewImg">
    </div>

    <div class="modal in" id="viewImg" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!--<div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            <h4 class="modal-title">View Image</h4>
          </div>-->
          <div class="modal-body">
            <div id="imgViewer" style="overflow-x: scroll;">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>