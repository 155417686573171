<div class="content-page">
   <div class="content">
      <div class="row">
         <div class="col-lg-10 offset-lg-2">
            <form [formGroup]="myForm">
               <div class="card-body">
               <div class="row">
                  <div class="col-md-6">
                     <div class="row">
                        <div class="col-md-6">
                           <label for="PrescptionDate">Date <span class="text-center">*</span></label>
                           <input class="form-control" type="date" placeholder="dd-MM-yyyy"
                                 formControlName="PrescptionDate" />
                        </div>
                        <!-- <div class="text-danger" *ngIf="(f.PrescptionDate.touched || submitted) && f.PrescptionDate.errors?.required">Select prescription date</div> -->
                        <div class="col-md-6">
                           <ngb-timepicker formControlName="PrescptionTime" [meridian]="meridian"></ngb-timepicker>
                        </div>
                        <!-- <div class="text-danger" *ngIf="(f.PrescptionTime.touched || submitted) && f.PrescptionTime.errors?.required">Select prescription time</div> -->
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-group">
                        <label for="hospitalname">Clinic <span class="text-center">*</span></label>
                        <input class="form-control" type="input" placeholder="Hospital name"
                                 formControlName="hospitalname" readonly />
                        <!-- <select class="custom-select" (change)="changeHospitalName($event)"
                           formControlName="hospitalname">
                           <option *ngFor="let name of hospitalList" [attr.disabled]="editPrescriptionId != undefined">
                              {{name.HospitalName}}</option>
                        </select> -->
                     </div>
                     <div class="text-danger" *ngIf="(f.hospitalname.touched || submitted) && f.hospitalname.errors?.required">Select hospital name</div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-sm-6">
                     <div class="form-group" style="margin-bottom: 0px;">
                        <input formControlName="patientid" class="form-control" type="hidden" />
                        <label>Patient <span class="text-center">*</span></label>
                        <input formControlName="patientname" class="form-control" type="text" (input)="Search()"
                           [readonly]="editPrescriptionId != undefined" />
                     </div>
                     <div class="text-danger" *ngIf="(f.patientname.touched || submitted) && f.patientname.errors?.required">Select patient name</div>
                     <ng-template [ngIf]="PatientList.length > 0">
                        <div class="mt-1 form-control form-control-sm" *ngFor="let item of PatientList" (click)="onSelect(item)">
                           {{item.FirstName}} {{item.LastName}} / {{item.PhoneNumber}}
                        </div>
                     </ng-template>
                  </div>
                  <div class="col-sm-6">
                     <div class="form-group">
                        <label>Doctor</label>
                        <input formControlName="doctorname" class="form-control" type="text" readonly />                        
                     </div>
                     <div class="text-danger" *ngIf="(f.doctorname.touched || submitted) && f.doctorname.errors?.required">Select doctor name</div>
                  </div>
               </div>
            </div>
               <div class="row mt-1">
                  <div class="col-lg-12">
                     <div class="panel panel-default">
                        <div class="panel-title">
                           Prescription
                           <div class="pull-right">
                              <button type="button" class="btn btn-outline-danger" onclick="window.history.back();"><i
                                    class="fa fa-arrow-left"></i> Back</button><span></span>
                              <button type="button" id="prescription_delete" *ngIf="editPrescriptionId != undefined"
                                 class="btn btn-danger ml-1">Delete</button>
                              <button type="button" id="prescription_save" class="btn btn-danger active ml-1">
                                 Save</button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <hr>

               <div class="card-body">
                  <div class="card-heading">
                     <label class="control-label card-title">Anthropometry</label>
                  </div>
                  <div class="card card-border" formGroupName="Anthropometry">
                     <div class="card-body">
                        <div class="row">
                           <div class="col-md-2">
                                 <input class="form-control" formControlName="id" type="hidden">
                                 <label class="control-label" for="height">Height</label>
                                 <div class="input-group">
                                    <input class="form-control ui-autocomplete-input" formControlName="height"
                                       (keyup)="calculateBmi($event)" id="height" name="height" type="number"
                                       autocomplete="off" min="1" />
                                    <div class="input-group-append">
                                       <span class="input-group-text">Cm</span>
                                    </div>
                                    <!-- <div class="input-group-append">
                                             <button type="button" class="btn btn-outline-danger" id="add_height">Add</button>
                                             </div> -->
                                 </div>
                           </div>
                           <div class="col-md-2">
                                 <label class="control-label" for="weight">Weight</label>
                                 <div class="input-group">
                                    <input class="form-control ui-autocomplete-input" formControlName="weight"
                                       (keyup)="calculateBmi($event)" id="weight" name="weight" type="number"
                                       autocomplete="off" min="1" />
                                    <div class="input-group-append">
                                       <span class="input-group-text">Kg</span>
                                    </div>
                                    <!-- <div class="input-group-append">
                                             <button type="button" class="btn btn-outline-danger" id="add_weight" >Add</button>
                                          </div> -->
                                 </div>
                           </div>
                           <div class="mt-4">
                              <button class="btn btn-primary mt-1">Calculate BMI</button>
                           </div>
                           <div class="col-md-2">
                                 <label class="control-label" for="bmi">BMI</label>
                                 <div class="input-group">
                                    <input class="form-control ui-autocomplete-input" formControlName="bmi" id="bmi"
                                       name="bmi" type="text" value="" placeholder="" autocomplete="off" readonly>
                                    <div class="input-group-append">
                                       <span class="input-group-text">Kg/m<sup>2</sup></span>
                                    </div>                                    
                                 </div>
                           </div>
                           <div class="col-md-2">
                                 <label class="control-label" for="others">Others</label>
                                 <div class="input-group">
                                    <input class="form-control ui-autocomplete-input" formControlName="others"
                                       id="others" name="others" type="text" value="" placeholder="" autocomplete="off">
                                 </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="row m-t-15">
                     <div class="col">
                        <label class="control-label card-title" for="chiefcomplaints">Chief complaints</label>
                        <div class="input-group">
                        <input type="text" formControlName="chiefcomplaint" class="form-control" aria-label="Default" placeholder="add / search complaint"
                           aria-describedby="chiefcomplaints" (keyup.enter)="addComplaint($event)" (input)="searchComplaint()" />
                           <button type="button" class="btn btn-danger ml-1" (click)="addComplaint($event)"> Add </button>
                        </div>
                        <ng-template [ngIf]="complaintMasterList.length > 0">
                           <div class="mt-1">
                              <div class="col-12 form-control select2 searchItem" *ngFor="let item of complaintMasterList" (click)="onComplaintSelect(item.Name)">
                                 {{item.Name}}
                              </div>
                           </div>
                        </ng-template>
                     </div>
                  </div>
                  <div formArrayName="complaints">
                     <div *ngFor="let complaint of complaintsList.controls; let i=index;" class="mt-1"
                        style="width: 35%;">
                        <div [formGroupName]="i" class="input-group">
                           <input class="form-control col-md-auto" type="text" formControlName="Name"
                              [id]="'complaint' + i" />
                           <button type="button" class="btn btn-outline-danger" (click)="removeComplaint(i)"><span
                                 aria-hidden="true">&times;</span></button>
                        </div>
                     </div>
                  </div>
                  <br>

                  <div class="row m-t-15">
                     <div class="col">
                        <label class="control-label card-title" for="pasthistories">History</label>
                        <div class="input-group">
                        <input type="text" formControlName="pasthistory" class="form-control" aria-label="Default"
                           aria-describedby="pasthistories" (keyup.enter)="addPastHistory($event)" />
                           <button type="button" class="btn btn-danger ml-1" (click)="addPastHistory($event)"> Add </button>
                        </div>
                     </div>
                  </div>
                  <div formArrayName="pastHistories">
                     <div *ngFor="let history of pasthistoryList.controls; let i=index" class="mt-1" style="width:35%;">
                        <div [formGroupName]="i" class="input-group">
                           <input class="form-control col-md-auto" type="text" formControlName="Name"
                              [id]="'history' + i" [value]="history" />
                           <button type="button" class="btn btn-outline-danger" (click)="removePasthistory(i)"><span
                                 aria-hidden="true">&times;</span></button>
                        </div>
                     </div>
                  </div>
                  <br>

                  <div class="card-heading">
                     <label class="control-label card-title">Vitals</label>
                  </div>
                  <div class="card card-border" formGroupName="Vitals">
                     <div class="card-body">
                        <div class="row">
                           <div class="col-md-3">
                              <div class="row m-b-10">
                                 <div class="col-md-9">
                                    <input class="form-control" formControlName="id" type="hidden">
                                    <label class="control-label" for="pulseRate">Pulse rate</label>
                                    <div class="input-group">
                                       <input class="form-control ui-autocomplete-input" formControlName="pulseRate"
                                          name="pulseRate" type="text" value="" placeholder="" autocomplete="off">
                                       <div class="input-group-append">
                                          <span class="input-group-text">bpm</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-md-3">
                              <div class="row m-b-10">
                                 <div class="col-md-9">
                                    <label class="control-label" for="respiratoryRate">Respiratory rate</label>
                                    <div class="input-group">
                                       <input class="form-control ui-autocomplete-input"
                                          formControlName="respiratoryRate" name="respiratoryRate" type="text" value=""
                                          placeholder="" autocomplete="off">
                                       <div class="input-group-append">
                                          <span class="input-group-text">/min</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-md-3">
                              <div class="row m-b-10">
                                 <div class="col-md-9">
                                    <label class="control-label" for="spO2">SpO2</label>
                                    <div class="input-group">
                                       <input class="form-control ui-autocomplete-input" formControlName="spO2"
                                          name="spO2" type="text" value="" placeholder="" autocomplete="off">
                                       <div class="input-group-append">
                                          <span class="input-group-text">%O<sub>2</sub></span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-md-3">
                              <div class="row m-b-10">
                                 <div class="col-md-9">
                                    <label class="control-label" for="temperature">Temperature</label>
                                    <div class="input-group">
                                       <input class="form-control ui-autocomplete-input" formControlName="temperature"
                                          name="temperature" type="text" value="" placeholder="" autocomplete="off">
                                       <div class="input-group-append">
                                          <span class="input-group-text">&#176;F</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="row">
                                 <div class="col-md-12">
                                    <table class="table table-bordered visit_investigation_list"></table>
                                 </div>
                              </div>
                           </div>
                           <div class="col-md-3">
                              <div class="row m-b-10">
                                 <div class="col-md-12">
                                    <label class="control-label" for="bp">SBP / DBP</label>
                                    <div class="input-group">
                                       <input class="form-control ui-autocomplete-input" formControlName="sbp"
                                          type="text" value="" placeholder="" autocomplete="off" maxlength="3">

                                       <span class="input-group-text">/</span>

                                       <input class="form-control ui-autocomplete-input" formControlName="dbp"
                                          type="text" value="" placeholder="" autocomplete="off" maxlength="3">

                                       <div class="input-group-append">
                                          <span class="input-group-text">mm Hg</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="panel panel-border panel-danger">
                     <div class="panel-heading">
                        <h3 class="panel-title"></h3>
                     </div>
                     <div class="panel-body">
                        <div class="row m-t-10">
                        </div>

                        <div class="row m-t-15">
                           <div class="col">
                              <label class="control-label" for="clinicalexaminations">Clinical Examination</label>
                              <div class="input-group">
                              <input type="text" formControlName="clinicalexamination" class="form-control"
                                 aria-label="Default" aria-describedby="clinicalexaminations" placeholder="add / search finding"
                                 (keyup.enter)="addClinicalExamination($event)" (input)="searchExamination()"/>
                                 <button type="button" class="btn btn-danger ml-1" (click)="addClinicalExamination($event)"> Add </button>
                              </div>
                              <ng-template [ngIf]="examinationMasterList.length > 0">
                                 <div class="mt-1">
                                    <div class="col-12 form-control select2 searchItem" *ngFor="let item of examinationMasterList" (click)="onExaminationSelect(item.Name)">
                                       {{item.Name}}
                                    </div>
                                 </div>
                              </ng-template>
                           </div>
                        </div>
                        <div formArrayName="clinicalExaminations">
                           <div *ngFor="let clinical of clinicalExaminationList.controls; let i=index" class="mt-1"
                              style="width: 35%;">
                              <div [formGroupName]="i" class="input-group">
                                 <input class="form-control col-md-auto" type="text" formControlName="Name"
                                    [id]="'clinical' + i" [value]="clinical" />
                                 <button type="button" class="btn btn-outline-danger"
                                    (click)="removeClinicalExamination(i)"><span
                                       aria-hidden="true">&times;</span></button>
                              </div>
                           </div>
                        </div>
                        <br>

                        <div class="row m-t-15">
                           <div class="col">
                              <label class="control-label" for="investigations">Investigations</label>
                              <div class="input-group">
                              <input type="text" formControlName="investigation" class="form-control"
                                 aria-label="Default" aria-describedby="investigations"
                                 (keyup.enter)="addInvestigation($event)" />
                                 <button type="button" class="btn btn-danger ml-1" (click)="addInvestigation($event)"> Add </button>
                              </div>
                           </div>
                        </div>
                        <div formArrayName="investigations">
                           <div *ngFor="let investigation of investigationList.controls; let i=index" class="mt-1"
                              style="width: 35%;">
                              <div [formGroupName]="i" class="input-group">
                                 <input class="form-control col-md-auto" type="text" formControlName="Name"
                                    [id]="'investigation' + i" [value]="investigation" />
                                 <button type="button" class="btn btn-outline-danger"
                                    (click)="removeInvestigation(i)"><span aria-hidden="true">&times;</span></button>
                              </div>
                           </div>
                        </div>
                        <br>

                        <div class="row m-t-15">
                           <div class="col">
                              <label class="control-label" for="diagnosises">Diagnosis</label>
                              <div class="input-group">
                              <input type="text" formControlName="diagnosis" class="form-control" aria-label="Default" placeholder="add / search diagnosis"
                                 aria-describedby="diagnosises" (keyup.enter)="addDiagnosis($event)" (input)="searchDiagnosis()" />
                                 <button type="button" class="btn btn-danger ml-1" (click)="addDiagnosis($event)"> Add </button>
                              </div>
                           
                           <ng-template [ngIf]="diagnosisMasterList.length > 0">
                              <div class="mt-1">
                                 <div class="col-12 form-control select2 searchItem" *ngFor="let item of diagnosisMasterList" (click)="onDiagnosisSelect(item.Name)">
                                    {{item.Name}}
                                 </div>
                              </div>
                           </ng-template>
                           </div>
                        </div>
                        <div formArrayName="diagnosisList">
                           <div *ngFor="let diagnosis of diagnosisList.controls; let i=index" class="mt-1"
                              style="width: 35%;">
                              <div [formGroupName]="i" class="input-group">
                                 <input class="form-control col-md-auto" type="text" formControlName="Name"
                                    [id]="'diagnosis' + i" [value]="diagnosis" />
                                 <button type="button" class="btn btn-outline-danger" (click)="removeDiagnosis(i)"><span
                                       aria-hidden="true">&times;</span></button>
                              </div>
                           </div>
                        </div>
                        <br>
                        <div class="card-heading">
                           <label class="control-label card-title">Medicine</label>
                        </div>
                        <input type="text" [(ngModel)]="searchMedicineGroup" [ngModelOptions]="{standalone: true}" class="form-control"
                           placeholder="search medicine groups" (input)="searchMedicines()" />
                        <ng-template [ngIf]="medicineGroupList.length > 0">
                           <div class="mb-2">
                              <div class="col-12 form-control select2 searchItem" *ngFor="let item of medicineGroupList" (click)="onMedicineGroupSelect(item)">
                                 {{item.Title}}
                              </div>
                           </div>
                        </ng-template>

                        <div class="card card-border">
                           <div class="card-body">
                              <div class="form-row">
                                 <div class="col-md-12">
                                    <table class="form-table table" id="customFields">
                                       <thead class="thead-light">
                                          <tr>
                                             <th scope="col">Medicine</th>
                                             <th scope="col">Frequency</th>
                                             <th scope="col">Duration</th>
                                             <th scope="col">Measure</th>
                                             <th scope="col">Instructions</th>
                                             <th scope="col"></th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr>
                                             <td class="minputBox">
                                                <input type="text" class="form-control" formControlName="medicineName"
                                                   name="medicineName" value="">
                                                   <!-- <div class="text-danger" *ngIf="(f.medicineName.touched || submitted) && f.medicineName.errors?.required">Select medicine name</div> -->
                                             </td>
                                             <td class="col-md-4">
                                                <div class="form-check form-check-inline"
                                                   *ngFor="let item of frequencyList">
                                                   <input class="form-check-input" [value]="item.name"
                                                      [checked]="item.isSelected" name="frequencyCheckbox"
                                                      (change)="onCheckboxChange($event)" type="checkbox"
                                                      id="inlinecheck{{item.id}}">
                                                   <label class="form-check-label"
                                                      for="inlinecheck{{item.id}}">{{item.name}}</label>
                                                </div>
                                                <input style="padding: 0.2rem;" type="text" class="form-control"
                                                   formControlName="customText" placeholder="Custom frequency" />
                                                   <!-- <div class="text-danger" *ngIf="(f.frequencyCheckbox.touched || submitted) && f.frequencyCheckbox.errors?.required">Select frequency</div> -->
                                             </td>
                                             <td class="minputBox">
                                                <input type="text" class="form-control" formControlName="noOfDays"
                                                   name="noOfDays" value="">
                                                   <!-- <div class="text-danger" *ngIf="(f.noOfDays.touched || submitted) && f.noOfDays.errors?.required">Select duration/ no of days</div> -->
                                             </td>
                                             <td class="minputBox">
                                                <input type="text" class="form-control" formControlName="quantity"
                                                   name="quantity" value="">
                                                   <!-- <div class="text-danger" *ngIf="(f.quantity.touched || submitted) && f.quantity.errors?.required">Select measure</div> -->
                                             </td>
                                             <td class="minputBox">
                                                <input type="text" class="form-control" formControlName="instructions"
                                                   name="instructions" value="">
                                             </td>
                                             <td class="minputBox">
                                                <div class="input-group-append">
                                                   <button type="button" class="btn add btn-outline-danger"
                                                      *ngIf="f.medicineName.value != null && f.medicineName.value != ''"
                                                      id="add_medicine" (click)="addMedicine()">Add</button>
                                                </div>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>

                                 <!-- <div formArrayName="medicines">
                                    <div class="form-check form-check-inline"
                                       *ngFor="let item of medicineList.controls; let i=index">                                      
                                       <div [formGroupName]="i" class="input-group">
                                       </div>
                                    </div>
                                 </div> -->

                                 <table *ngIf="medicineList?.length">
                                    <thead class="text-nowrap">
                                       <!-- <th scope="col">S.No</th> -->
                                       <th scope="col">MedicineName</th>
                                       <th scope="col">Frequency</th>
                                       <th scope="col">Custom Frequency</th>
                                       <th scope="col">Duration</th>
                                       <th scope="col">Quantity</th>
                                       <th scope="col">Instructions</th>
                                    </thead>
                                    <tbody>
                                       <ng-container formArrayName="medicines"
                                          *ngFor="let item of medicineList.controls; let i=index">
                                          <tr [formGroupName]="i">
                                             <!-- <td scope="row"></td> -->
                                             <td><input type="text" class="form-control form-control-sm"
                                                   formControlName="medicineName" /></td>
                                             <td><input type="text" class="form-control form-control-sm"
                                                   formControlName="frequency" /></td>
                                             <td> <input type="text" class="form-control form-control-sm"
                                                   formControlName="customText" /></td>
                                             <td><input type="text" class="form-control form-control-sm"
                                                   formControlName="noOfDays" /></td>
                                             <td><input type="text" class="form-control form-control-sm"
                                                   formControlName="quantity" /></td>
                                             <td><input type="text" class="form-control form-control-sm"
                                                   formControlName="instructions" /></td>
                                             <td><button type="button" class="btn remove btn-outline-danger"
                                                   (click)="removeMedicine(i)"><span
                                                      aria-hidden="true">&times;</span></button></td>
                                          </tr>
                                       </ng-container>
                                    </tbody>
                                 </table>

                              </div>
                           </div>
                        </div>
                        <br>

                        <div class="row m-t-15">
                           <div class="col">
                              <div class="input-group">
                                 <label class="control-label" for="remarks">Advice/Remarks</label>
                                 <input type="text" [(ngModel)]="searchAdvice" [ngModelOptions]="{standalone: true}" class="form-control ml-3 mb-1" placeholder="search advice group"
                                    (input)="searchAdvices()" />
                              </div>
                              <ng-template [ngIf]="advicesList.length > 0">
                                 <div class="mb-2" style="margin-left: 117px;">
                                    <div class="col-12 form-control select2 searchItem" *ngFor="let item of advicesList" (click)="onAdviceGroupSelect(item)">
                                       {{item.Title}}
                                    </div>
                                 </div>
                              </ng-template>
                        
                              <div class="input-group">
                                 <input type="text" formControlName="remark" class="form-control" aria-label="Default" placeholder="add advice"
                                    aria-describedby="remarks" (keyup.enter)="addRemarks($event)" /><button type="button"
                                    class="btn btn-danger ml-1" (click)="addRemarks($event)"> Add </button>
                              </div>
                           </div>
                        </div>
                        <div formArrayName="remarks">
                           <div *ngFor="let remark of remarksList.controls; let i=index" class="mt-1"
                              style="width: 35%;">
                              <div [formGroupName]="i" class="input-group">
                                 <input class="form-control col-md-auto" type="text" formControlName="Name"
                                    [id]="'remark' + i" [value]="remark" />
                                 <button type="button" class="btn btn-outline-danger" (click)="removeRemarks(i)"><span
                                       aria-hidden="true">&times;</span></button>
                              </div>
                           </div>
                        </div>
                        <br>

                        <div class="row m-t-15">
                           <div class="col-md-3">
                              <label class="control-label" for="">Follow Up</label>
                              <!-- <div class="input-group">
                                 <input class="form-control" placeholder="dd-MM-yyyy" formControlName="followUpDate"
                                    name="dp" ngbDatepicker #d="ngbDatepicker" placeholder="Date">
                                 <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                       type="button"></button>
                                 </div>
                              </div> -->
                              <input class="form-control" type="date" placeholder="dd-MM-yyyy"
                                 formControlName="followUpDate" />
                           </div>
                           <div class="col-md-3">
                              <ngb-timepicker formControlName="followUpTime" [meridian]="meridian"></ngb-timepicker>
                           </div>
                           <div class="col-md-3">
                              <label class="control-label" for="">Follow Up Note</label>
                              <textarea rows="2" cols="30" class="form-control"
                                 formControlName="followUpNote"></textarea>
                           </div>
                        </div>
                        <br>

                        <div class="row m-t-15">
                           <div class="col-md-3">
                              <label class="control-label" for="">Refer to</label>
                              <input class="form-control" id="follow_up_doctor" formControlName="referDoctor"
                                 name="referDoctor" type="text" placeholder="Doctor name">
                           </div>
                           <div class="col-md-3" style="margin-top:27px">
                              <!-- <div class="input-group">
                                 <input class="form-control" placeholder="Date" formControlName="referDate" name="dp2"
                                    ngbDatepicker #rd="ngbDatepicker">
                                 <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="rd.toggle()"
                                       type="button"></button>
                                 </div>
                              </div> -->
                              <input class="form-control" type="date" placeholder="Date" formControlName="referDate" />
                           </div>
                           <div class="col-md-3">
                              <ngb-timepicker formControlName="referTime" [meridian]="meridian">
                              </ngb-timepicker>
                           </div>
                           <div class="col-md-3">
                              <label class="control-label" for="">Refer Note</label>
                              <textarea rows="2" cols="30" class="form-control" formControlName="referNote"></textarea>
                           </div>
                        </div>

                        <!--<div class="row">
                                                 <div class="col-md-4 m-t-5">
                                                    <div class="checkbox checkbox-danger checkbox-inline">
                                                       <input type="checkbox" id="" name="" value="">
                                                       <label for=""> Create Appointment </label>
                                                    </div>
                                                 </div>
                                              </div>-->
                     </div>
                  </div>
               </div>

            </form>
            <div class="m-t-20 text-center">
               <button [disabled]="loading" class="btn btn-primary submit-btn" (click)="addPescription()">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  {{name}}</button>
            </div>
         </div>
      </div>
   </div>

</div>

<div class="sidebar-overlay" data-reff=""></div>