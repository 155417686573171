import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup,FormBuilder } from '@angular/forms';
import { SharedService } from 'src/app/shared.service';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-payment',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  
  userForm: FormGroup;
  page: number = 1;
  pageSize = 5;
  billsList:any;
  currentUser:any;
  patientId:any;

  constructor(private fb: FormBuilder,private service: SharedService,private authenticationService: AuthenticationService, private route: ActivatedRoute) 
  {
    this.currentUser = this.authenticationService.currentUserValue;
   }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        this.patientId = params['patientId'];
      });
    this.createForm();
    this.getBillsList();
  }

  getBillsList() {
    this.service.getPatientBills(this.patientId, this.currentUser.User.HospitalId)
      .subscribe(
        (data) => {
          if (data != null) {
            this.billsList = data;
          };
        })
  };
  public createForm(){
    this.userForm = this.fb.group({
      saveBill: this.fb.group({
        patientId: [''],
        search: ['']        
      }),
      Services: new FormArray([])
    })
  };


  
}
