<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="content">
            <div><button class="d-print-none btn btn-danger float-right ml-1"
                    [routerLink]="['../prescription']">Cancel</button>
                <button class="d-print-none btn btn-primary float-right" (click)="openPdf()">Print
                    Prescription</button>
            </div>
            <div class="container" id="htmlData" #htmlData>
                <div class="selector-for-some-widget row">
                    <div class="text-center col-10">
                        <div class="font-weight-bold text-capitalize">{{hospitalName}}</div>
                        <div>{{hospitalAddress}}</div>
                        <div>Ph: {{hospitalPhonenumber}}, Email: {{hospitalEmail}}</div>
                    </div>
                </div>
                <hr class="border border-dark" style="width: 100%;margin-left:39px;">
                <div class="ml-5">
                    <div>
                        <span class="font-weight-bold">Patient Details :</span><span class="text-capitalize">{{patientDetails}}</span><br>
                        <div class="row">
                            <div class="col"><span class="font-weight-bold">UHID No : </span>{{uhidNo}}</div>
                            <div class="col"><span class="font-weight-bold">Contact No : </span>{{patientContactNo}}
                            </div>
                            <div class="col"><span class="font-weight-bold">Date : </span><span>{{prescptionDate | date
                                    :'MMM d, y h:mm a'}}</span></div>
                        </div>
                        <span class="font-weight-bold">Consultant Doctor : Dr. </span><span>{{doctorName}}</span><br>
                        <span class="ml-8">{{doctorQualification}}</span><br>
                        <span class="ml-8">{{doctorRegistrationNumber}}</span>
                    </div>
                </div>
                <hr class="border border-dark" style="width: 100%;margin-left:39px;">

                <div class="row anthro ml-5" *ngIf="anthropometry != null">
                    <div class="col" *ngIf="anthropometry.Height != ''">Height : <span>{{anthropometry.Height}} CM</span></div>
                    <div class="col" *ngIf="anthropometry.Weight != ''">Weight : <span>{{anthropometry.Weight}} Kg</span></div>
                    <div class="col" *ngIf="anthropometry.Bmi != ''">BMI : <span>{{anthropometry.Bmi}} kg/m<sup>2</sup></span></div>
                </div>
                <div class="ml-5" *ngIf="anthropometry != null && anthropometry.Others != ''">Others : <span>{{anthropometry.Others}}</span></div>

                <div class="row ml-5" *ngIf="vitals != null">
                    <div class="col" *ngIf="vitals.PulseRate != ''">PR/HR : <span>{{vitals.PulseRate}} bpm</span></div>
                    <div class="col" *ngIf="vitals.RespiratoryRate != ''">RR : <span>{{vitals.RespiratoryRate}} /min</span></div>
                    <div class="col" *ngIf="vitals.Dbp != '' && vitals.Sbp != ''">BP : <span>{{vitals.Sbp}}/{{vitals.Dbp}} mmHg</span></div>
                    <div class="col" *ngIf="vitals.Temperature != ''">Temp : <span>{{vitals.Temperature}}F</span></div>
                    <div class="col" *ngIf="vitals.SpO2 != ''">SpO2 : <span>{{vitals.SpO2}}%</span></div>
                </div><br>

                <div class="input-group" *ngIf="complaints != null && complaints.length > 0">
                    <div class="row justify-content-md-center justify-content-center">
                        <div class="col font-weight-bold header-width">Chief Complaints : </div>
                        <div class="col-md-auto">
                            <ol class="list-group list-group-flush">
                                <li *ngFor="let item of complaints" class="cust-list-item">{{item.Name}}</li>
                            </ol>
                        </div>
                    </div>
                </div><br *ngIf="complaints != null && complaints.length > 0">

                <div class="input-group" *ngIf="pastHistory != null && pastHistory.length > 0">
                    <div class="row justify-content-md-center justify-content-center">
                        <div class="col font-weight-bold header-width">History : </div>
                        <div class="col-md-auto">
                            <ul class="list-group list-group-flush">
                                <li *ngFor="let item of pastHistory" class="cust-list-item">{{item.Name}}</li>
                            </ul>
                        </div>
                    </div>
                </div><br *ngIf="pastHistory != null && pastHistory.length > 0">

                <div class="input-group" *ngIf="clinicalExaminations != null && clinicalExaminations.length > 0">
                    <div class="row justify-content-md-center justify-content-center">
                        <div class="col font-weight-bold header-width">Examination : </div>
                        <div class="col-md-auto">
                            <ul class="list-group list-group-flush">
                                <li *ngFor="let item of clinicalExaminations" class="cust-list-item">{{item.Name}}</li>
                            </ul>
                        </div>
                    </div>
                </div><br *ngIf="clinicalExaminations != null && clinicalExaminations.length > 0">

                <div class="input-group" *ngIf="diagnosis != null && diagnosis.length > 0">
                    <div class="row justify-content-md-center justify-content-center">
                        <div class="col font-weight-bold header-width">Diagnosis : </div>
                        <div class="col-md-auto">
                            <ol class="list-group list-group-flush">
                                <li *ngFor="let item of diagnosis" class="cust-list-item">{{item.Name}}</li>
                            </ol>
                        </div>
                    </div>
                </div><br *ngIf="diagnosis != null && diagnosis.length > 0">

                <div class="input-group" *ngIf="investigations != null && investigations.length > 0">
                    <div class="row justify-content-md-center justify-content-center">
                        <div class="col font-weight-bold header-width">Investigations : </div>
                        <div class="col-md-auto">
                            <ol class="list-group list-group-flush">
                                <li *ngFor="let item of investigations" class="cust-list-item">{{item.Name}}</li>
                            </ol>
                        </div>
                    </div>
                </div><br *ngIf="investigations != null && investigations.length > 0">

                <div *ngIf="medicineList != null && medicineList.length > 0">
                    <table class="table table-bordered">
                        <tr class="table-primary">
                            <th>Medicine Name</th>
                            <th>Dose</th>
                            <th>Frequency</th>
                            <th>Duration</th>
                            <th>Instructions</th>
                        </tr>
                        <tr *ngFor="let item of medicineList">
                            <th>{{item.MedicineName}}</th>
                            <td>{{item.Quantity}}</td>
                            <td>{{item.Frequency}}</td>
                            <td>{{item.NoOfDays}}</td>
                            <td>{{item.Instructions}}</td>
                        </tr>
                    </table>
                </div><br *ngIf="medicineList != null && medicineList.length > 0">

                <div class="input-group" *ngIf="remarks != null && remarks.length > 0">
                    <div class="row justify-content-md-center justify-content-center">
                        <div class="col font-weight-bold header-width">Advise : </div>
                        <div class="col-md-auto">
                            <ol class="list-group list-group-flush">
                                <li *ngFor="let item of remarks" class="cust-list-item">{{item.Name}}</li>
                            </ol>
                        </div>
                    </div>
                </div><br *ngIf="remarks != null && remarks.length > 0">

                <div class="row">
                    <div *ngIf="followUpDate != null"><span class="col font-weight-bold">Follow Up :</span><span>{{followUpDate | date :'MMM d, y
                            h:mm a'}}</span></div>
                    <div *ngIf="followUpNote != ''"><span class="col font-weight-bold">Follow up note :</span><span>{{followUpNote}}</span></div>
                </div>
                <div class="row">
                    <div *ngIf="referralDate != null"><span class="col font-weight-bold">Referral :</span><span>{{referralDate | date :'MMM d, y h:mm
                            a'}}</span></div>
                    <div *ngIf="referralTo != ''"><span class="col font-weight-bold">Refer To :</span><span>{{referralTo}}</span></div>
                </div>
                <div class="row">
                    <div *ngIf="referralNote != ''"><span class="col font-weight-bold">Referral note : </span><span>{{referralNote}}</span></div>
                </div>
                <!-- <hr class="border border-dark">
                 <div class="row">
                    <div class="col">Print Date : <span>{{todayDate | date :'MMM d, y h:mm a'}}</span></div>
                    <div class="float-right">clinics.ainetwork.in</div>
                </div> -->

            </div>
        </div>
    </div>
</div>