import { HttpRequest } from '@angular/common/http';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

class ImageSnippet {
  pending: boolean = false;
  status: string = 'init';

  constructor(public src: string, public file: File) { }
}
export class Item {
  id: string;
  name: string;
}

export const ITEMS: Item[] = [
  {
    id: '1',
    name: 'Male'
  },
  {
    id: '2',
    name: 'Female'
  },
  {
    id: '3',
    name: 'Other'
  }
];
@Component({
  selector: 'app-add-edit-patient',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './add-edit-patient.component.html',
  styleUrls: ['./add-edit-patient.component.css']
})
export class AddEditPatientComponent implements OnInit {
  constructor(public service: SharedService, public router: Router, private route: ActivatedRoute, public datepipe: DatePipe) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  }

  currentUser: any;
  id: number;
  errorMessage: String;
  GenderList: any = [];
  BloodGroupList: any = [];
  HospitalList: any = [];
  patientData: any;
  ActivateEditPatient: boolean = false;
  fromNavbar: boolean = false;

  @Input() editPatientID: any;
  PatientId: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Gender: string;
  DateOfBirth: any;
  PhoneNumber: string;
  Bloodgroup: string;
  ProfilePic: string;
  Address: string;
  City: string;
  State: string;
  Files: string;
  HospitalName: string;
  PostalCode: number;

  ngOnInit(): void {
    if (JSON.stringify(this.currentUser) == '{}' && localStorage.getItem('currentUser') == undefined) {
      this.router.navigateByUrl('login');
    }
    else {
      this.route.queryParams.subscribe(
        params => {
          this.fromNavbar = params['fromNavbar'];
        });

      this.loadBloodGroupList();
      this.loadHospitalList();
      this.getSelecteditem();

      if (this.editPatientID != undefined && this.editPatientID != 0) {
        this.ActivateEditPatient = true;
        this.getPatientDetails();
      }
      else {
        this.ActivateEditPatient = false;
        this.HospitalName = this.currentUser.User.Hospital.HospitalName;
      }
    }
  }

  getPatientDetails() {
    this.id = this.editPatientID;
    this.service.getPatientDetailsByID(this.id,this.currentUser.User.HospitalId)
      .subscribe(
        (data) => {
          this.patientData = data;

          this.PatientId = this.patientData.PatientId;
          this.FirstName = this.patientData.FirstName;
          this.LastName = this.patientData.LastName;
          this.Email = this.patientData.Email;
          this.Gender = this.patientData.Gender;
          this.PhoneNumber = this.patientData.PhoneNumber;
          this.Bloodgroup = this.patientData.Bloodgroup;
          this.ProfilePic = this.patientData.ProfilePic;
          this.Address = this.patientData.Address;
          this.City = this.patientData.City;
          this.State = this.patientData.State;
          this.Files = this.patientData.Files;
          this.HospitalName = this.currentUser.User.Hospital.HospitalName;
          this.PostalCode = this.patientData.PostalCode;

          let patientDob = new Date(this.patientData.DateOfBirth);
          patientDob.setDate(patientDob.getDate());
          this.DateOfBirth = this.datepipe.transform(patientDob, 'yyyy-MM-dd');
        },
        error => {
          console.log(error);
        });
  }

  checkDate() {
    let parsedDate = moment(this.DateOfBirth, "YYYY-MM-DD");
  }

  loadBloodGroupList() {
    this.service.getAllBloodgroupNames().subscribe((data: any) => {
      this.BloodGroupList = data;
    });
  }

  loadHospitalList() {
    this.service.getAllHospitalNames().subscribe((data: any) => {
      this.HospitalList = data;
    });
  }

  getSelecteditem() {
    this.GenderList = ITEMS;
  }

  addPatient() {
    var val = {
      PatientId: this.PatientId,
      FirstName: this.FirstName,
      LastName: this.LastName,
      PhoneNumber: this.PhoneNumber,
      Email: this.Email,
      DateOfBirth: this.DateOfBirth,
      Gender: this.Gender,
      Bloodgroup: this.Bloodgroup,
      Address: this.Address,
      City: this.City,
      State: this.State,
      PostalCode: this.PostalCode,
      Files: this.Files == undefined ? "" : this.Files,
      ProfilePic: this.ProfilePic == undefined ? "" : this.ProfilePic,
      HospitalId : this.currentUser.User.HospitalId
    };

    if (val.FirstName == undefined) {
      alert("First Name is required");
      return;
    }
    if (val.PhoneNumber == undefined) {
      alert("PhoneNumber is required");
      return;
    }
    if (val.Gender == undefined) {
      alert("Gender is required");
      return;
    }
    if (val.HospitalId == undefined || val.HospitalId == '') {
      alert("Hospital Name is required");
      return;
    }

    /** POST: add a new hero to the database */

    this.service.addPatient(val).subscribe({
      next: res => {
        alert("Patient details added !!");
        //this.router.navigateByUrl('header/patient');
        window.location.reload();
      },
      error: error => {
        this.errorMessage = error.message;
        console.error('There was an error in AddPatient Method!', error);
      }
    });
  }

  updatePatient() {
    var val = {
      PatientId: this.PatientId,
      FirstName: this.FirstName,
      LastName: this.LastName,
      PhoneNumber: this.PhoneNumber,
      Email: this.Email,
      DateOfBirth: this.DateOfBirth,
      Gender: this.Gender,
      Bloodgroup: this.Bloodgroup,
      Address: this.Address,
      City: this.City,
      State: this.State,
      PostalCode: this.PostalCode,
      Files: this.Files,
      ProfilePic: this.ProfilePic,
      HospitalId : this.currentUser.User.HospitalId
    };

    this.service.updatePatient(val).subscribe({
      next: res => {
        alert("Patient details updated !!")
        window.location.reload();
      },
      error: error => {
        this.errorMessage = error.message;
        console.error('There was an error in UpdatePatient Method!', error);
      }
    });

  }

  selectedFile: ImageSnippet;
  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      this.selectedFile = new ImageSnippet(event.target.result, file);

      // this.imageService.uploadImage(this.selectedFile.file).subscribe(
      //   (res) => {

      //   },
      //   (err) => {

      //   })
    });

    reader.readAsDataURL(file);
  }

}
