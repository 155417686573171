import { PrintPrescriptionComponent } from './prescription/print-prescription/print-prescription.component';
import { PrintbillComponent } from './bill/printbill/printbill.component';
import { DepartmentComponent } from './department/department.component';
import { RegisterComponent } from './login/register/register.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './../app/_helpers/auth-guard.service';

import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PatientComponent } from './Patient/patient.component';
import { AddEditPatientComponent } from './Patient/add-edit-patient/add-edit-patient.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { AddAppointmentComponent } from './appointment/add-appointment/add-appointment.component';
import { EditAppointmentComponent } from './appointment/edit-appointment/edit-appointment.component';
import { PrescriptionComponent } from './prescription/prescription.component';
import { AddPrescriptionComponent } from './prescription/add-prescription/add-prescription.component';

// Settings
import { DoctorProfileComponent } from './doctor-profile/doctor-profile.component';
import { StaffProfileComponent } from './staff-profile/staff-profile.component';
import { SettingsComponent } from './settings/settings.component';
import { ComplaintComponent } from './PrescriptionMaster/complaint/complaint.component';
import { TitleDashboardComponent } from './PrescriptionMaster/title-dashboard/title-dashboard.component';
import { TitleAdvicesComponent } from './PrescriptionMaster/title-advices/title-advices.component';
import { TitleMedicinesComponent } from './PrescriptionMaster/title-medicines/title-medicines.component';
import { ClinicServicesComponent } from './clinic-services/clinic-services.component';

// Patient profile
import { HomeComponent } from './patientprofile/home/home.component';
import { AppointmentsComponent } from './patientprofile/appointments/appointments.component';
import { CommunicationComponent } from './patientprofile/communication/communication.component';
import { LabReportsComponent } from './patientprofile/lab-reports/lab-reports.component';
import { ImmunizationComponent } from './patientprofile/immunization/immunization.component';
import { ImagesComponent } from './patientprofile/images/images.component';
import { PaymentComponent } from './patientprofile/payment/payment.component';
import { NavMenuComponent } from './patientprofile/nav-menu/nav-menu.component';
import { PatientDetailsComponent } from './patientprofile/patient-details/patient-details.component';
import { BillComponent } from './bill/bill.component';
import { AddEditBillComponent } from './bill/add-edit-bill/add-edit-bill.component';

const routes: Routes = [

  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'header', component: HeaderComponent,
    children: [
     { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      //{ path: 'header', component: HeaderComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'patient', component: PatientComponent },
      { path: 'addPatient', component: AddEditPatientComponent },
      { path: 'appointments', component: AppointmentComponent },
      { path: 'addappointment', component: AddAppointmentComponent },
      { path: 'editappointment', component: EditAppointmentComponent },
      { path: 'prescription', component: PrescriptionComponent },
      { path: 'addprescription', component: AddPrescriptionComponent },
      { path: 'editprescription', component: AddPrescriptionComponent },
      { path: 'doctorprofile', component: DoctorProfileComponent },
      { path: 'staffprofile', component: StaffProfileComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'bills', component: BillComponent },
      { path: 'addeditbill', component: AddEditBillComponent },
      { path: 'department', component: DepartmentComponent },
      { path: 'printbill', component: PrintbillComponent },
      { path: 'printprescription', component: PrintPrescriptionComponent },
      { path: 'complaint', component: ComplaintComponent },
      { path: 'titleDashboard', component: TitleDashboardComponent },
      { path: 'advicesDashboard', component: TitleAdvicesComponent },
      { path: 'medicinesDashboard', component: TitleMedicinesComponent },
      { path: 'clinicServices', component: ClinicServicesComponent },
      
      // Patient profile routing  
      {
        path: 'patientprofile', component: NavMenuComponent,
        children: [
          { path: '', redirectTo: 'patientprofileHome', pathMatch: 'full' },
          { path: 'patientprofileHome', component: HomeComponent },
          { path: 'patientprofileAppointment', component: AppointmentsComponent },
          { path: 'patientprofileCommunication', component: CommunicationComponent },
          { path: 'patientprofileLabreports', component: LabReportsComponent },
          { path: 'patientprofileImmunization', component: ImmunizationComponent },
          { path: 'patientprofileImages', component: ImagesComponent },
          { path: 'patientprofilePayment', component: PaymentComponent },
          { path: 'patientdetails', component: PatientDetailsComponent },
        ]
      }
    ],canActivate: [AuthGuard]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
