import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-prescription',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.css']
})
export class PrescriptionComponent implements OnInit {

  prescriptionData: any;
  prescriptionList: any;
  id: any;
  page: number = 1;
  pageSize = 5;
  currentUser:any;
  diagnosisItems:any=',';
  SearchPatientList: any = [];
  patientName:any;
  datePipe: DatePipe = new DatePipe('en-US');

  constructor(
    private router: Router,
    public service: SharedService,
  ) { this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}')}

  ngOnInit(): void {
    if (JSON.stringify(this.currentUser) == '{}' && localStorage.getItem('currentUser') == undefined) {
      this.router.navigateByUrl('login');      
    }
    else{    
      this.getAllPrescriptions();
    }
  }

  getAllPrescriptions() {
    this.service.getPrescriptionList(this.currentUser.User.HospitalId).subscribe((data: any) => {
    if(data != null){
      for (var item of data) {
        var date = this.dateConvertion(item.PrescptionDate);
        item.PrescptionDate = date;
        item.enableOption = this.currentUser.User.UserId == item.UserId ? true : false;
            
      for (var item2 of item.DiagnosisList) {
        this.diagnosisItems =this.diagnosisItems + item2.Name +',';
      }
      this.diagnosisItems = this.diagnosisItems.replace(/^,|,$/g, '');
      item.diagnosis = this.diagnosisItems;
      this.diagnosisItems = ',';
    }
      this.prescriptionList = data;
      this.SearchPatientList=data;  
    }
    });
  }
  addPrescription() {
    this.router.navigateByUrl('header/addprescription');
  }
  deletePrescription() {
    this.service.deletePrescription(this.id,this.currentUser.User.HospitalId).subscribe(data => {
      // alert("Prescription Deleted");
      this.getAllPrescriptions();
    });
  }
  getPrescId(id: any) {
    this.id = id;
  }
  
  dateConvertion(PrescptionDate: any) {
    let appDate = new Date(PrescptionDate);
    appDate.setDate(appDate.getDate());
    return this.datePipe.transform(appDate, 'dd-MM-yyyy');
  }

  searchPatient() {
    if (this.patientName == "") {
      this.ngOnInit();
    } else {
      this.prescriptionList = this.SearchPatientList.filter((data: any) => {
        var name = data.PatientName;
        return name.toString().toLowerCase().match(this.patientName.toLowerCase());
      })
      if (this.prescriptionList.length == 0) {
        this.prescriptionList = this.SearchPatientList.filter((data: any) => {
          return data.PhoneNumber.toString().toLowerCase().match(this.patientName.toLowerCase());
        })
      }
    }
  }

}

