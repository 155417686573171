<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="dash-widget" style="margin: 0px 96px 5px 39px;">
            <button type="button" class="close" aria-label="Close" *ngIf="viewDashboard != true"
                (click)="loadTitlesList()">
                <span aria-hidden="true">×</span>
            </button>
            <div class="row">
                <div>
                    <h4 class="page-title">{{titleHeading}}</h4>
                </div>
            </div>
            <div class="row float-right mr-1">
                <button type="button" class="btn btn-outline-danger mr-1" onclick="window.history.back();" *ngIf="viewDashboard == true"><i
                    class="fa fa-arrow-left"></i> Back</button>
                <button type="button" class="btn btn-primary" *ngIf="this.currentUser.User.RoleId == 2"
                    [ngClass]="viewDashboard == true ?'d-block':'d-none'" (click)="createForm()">{{addTitle}}</button>
            </div>

            <!-- Add or Edit medicine -->
            <form [formGroup]="form" (ngSubmit)="submit()" [ngClass]="viewDashboard == true ?'d-none':'d-block'">
                <div class="form-row">
                    <div class="form-group col-6">
                        <label>Name <span class="text-danger">*</span></label>
                        <input formControlName="title" class="form-control" type="text" />
                        <div class="text-danger" *ngIf="(f.title.touched || submitted) && f.title.errors?.required">Name
                            is required</div>
                    </div>
                </div>
                <div class="m-t-20 text-center">
                    <button class="btn btn-primary submit-btn" type="submit">{{submitText}}</button>
                </div>
            </form>

            <!-- Medicine master dashboard -->
            <div class="mt-5" [ngClass]="viewDashboard == true ?'d-block':'d-none'">
                <div class="dash-widget">
                    <div class="table-responsive">
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Search name" aria-label="Search Name"
                                aria-describedby="basic-addon2" [(ngModel)]="searchName" (input)="searchItem()" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button">
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <table class="table table-border table-striped custom-table mb-2">
                            <thead>
                                <th>S.No</th>
                                <th>Name</th>
                                <th *ngIf="this.currentUser.User.RoleId == 2">Action</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of itemsList | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize ; let i = index">
                                    <td>{{(pageSize *(page-1))+ i+1}}</td>
                                    <td><a [routerLink]="[item.routingItem]" [queryParams]="{viewDashboard:true, titleId:item.Id,title:item.Title}"> {{item.Title}}</a></td>
                                    <td *ngIf="this.currentUser.User.RoleId == 2">
                                        <div class="dropdown dropdown-action">
                                            <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown"
                                                aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a class="dropdown-item" (click)="getItem(item.Id)"><i
                                                        class="fa fa-pencil m-r-5"></i> Edit</a>
                                                <a class="dropdown-item" (click)="deleteItem(item.Id)"><i
                                                        class="fa fa-trash-o m-r-5"></i>Delete</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ngb-pagination *ngIf="itemsList?.length" class="pagination justify-content-center" [collectionSize]="itemsList.length" [(page)]="page" [maxSize]="5" [pageSize]="pageSize">
                            <ng-template ngbPaginationPrevious>Prev</ng-template>
                            <ng-template ngbPaginationNext>Next</ng-template>
                          </ngb-pagination>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>