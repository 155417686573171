<div class="page-wrapper">
    <div class="content pb1">
        <h3 class="text-center">Personal details</h3>
        <form [formGroup]="userForm">
            <div class="form-group">
                <div class="form-row mt-3">
                    <div class="col-md-2"><label for="firstname" class="col-form-label">First Name</label></div>
                    <div class="col-xs-6 col-sm-4"><input type="text" class="form-control"
                            formControlName="firstname" /></div>
                    <div class="col-md-2"><label for="lastname" class="col-form-label">Last Name </label></div>
                    <div class="col-xs-6 col-sm-4"><input type="text" class="form-control" formControlName="lastname" />
                    </div>
                </div>
                <div class="form-row mt-3">
                    <div class="col-md-2"><label class="col-form-label">Emergency Contact</label></div>
                    <div class="col-xs-6 col-sm-4"><input type="text" class="form-control"
                            formControlName="mobilenumber" /></div>
                    <div class="col-md-2"><label for="emailidFormControl" class="col-form-label">Email id </label></div>
                    <div class="col-xs-6 col-sm-4"><input type="email" class="form-control"
                            placeholder="name@example.com" formControlName="emailid" /></div>
                </div>
                <div class="form-row mt-3">
                    <div class="col-md-2"><label for="dateofbirth" class="col-form-label">Date of Birth</label></div>
                    <div class="col-xs-6 col-sm-4"><input type="date" placeholder="DD-MM-YYYY" class="form-control"
                            formControlName="dateofbirth" /></div>
                    <div class="col-md-2"><label for="gender" class="col-form-label">Gender</label></div>
                    <div class="form-group col-xs-6 col-sm-4">
                        <div class="form-check-inline">
                            <div class="col-4">
                                <input class="form-check-input" id="male" type="radio" value="Male" name="gender"
                                    formControlName="gender">
                                <label class="form-check-label" for="male">Male</label>
                            </div>
                            <div class="col-5">
                                <input class="form-check-input" id="female" type="radio" value="Female" name="gender"
                                    formControlName="gender">
                                <label class="form-check-label" for="female">Female</label>
                            </div>
                            <div class="col-5">
                                <input class="form-check-input" id="others" type="radio" value="Others" name="gender"
                                    formControlName="gender">
                                <label class="form-check-label" for="others">Others</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3">
                    <div class="col-md-2"><label for="address" class="col-form-label">Address</label></div>
                    <div class="col-xs-6 col-sm-4"><input type="text" class="form-control" formControlName="address" />
                    </div>
                    <div class="col-md-2"><label for="city" class="col-form-label">City</label></div>
                    <div class="col-xs-6 col-sm-4"><input type="text" class="form-control" formControlName="city" />
                    </div>
                </div>
                <div class="form-row mt-3">
                    <div class="col-md-2"><label for="state" class="col-form-label">State</label></div>
                    <div class="col-xs-6 col-sm-4"><input type="text" class="form-control" formControlName="state" />
                    </div>
                    <div class="col-md-2"><label for="postalcode" class="col-form-label">Postal Code</label></div>
                    <div class="col-xs-6 col-sm-4"><input type="text" class="form-control"
                            formControlName="postalcode" /></div>
                </div>

                <div class="form-row mt-3">
                    <div class="col-md-1" hidden><label class="col-form-label">Clinics</label></div>
                    <!--<div class="col-xs-6 col-sm-4"><input type="text" class="form-control" /></div>-->

                    <div class="dropdown col-xs-6 col-sm-4" hidden>
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Rainbow childrens hospital
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#">Marvel childrens and general clinic</a>
                            <a class="dropdown-item" href="#">Rainbow childrens hospital, Kukatpally</a>
                            <a class="dropdown-item" href="#">Something else here</a>
                        </div>
                    </div>

                    <div class="col-md-2"><label class="col-form-label"> Blood Group </label></div>
                    <div class="col-xs-6 col-sm-4"><input type="text" class="form-control"
                            formControlName="bloodgroup" />
                    </div>
                    <div class="col-md-2"><label class="col-form-label"> Birth History </label></div>
                    <div class="col-xs-6 col-sm-4"><button class="btn btn-primary" type="button" data-toggle="modal"
                            data-target="#birthHistory">Add Birth History</button>
                    </div>
                </div>

                <div class="form-row mt-3">
                    <div class="col-md-5"><label class="col-form-label"> Allergies </label></div>
                </div>
                <div class="form-row mt-3">
                    <div class="col-xs-8 col-sm-5 input-group"><input type="text" class="form-control"
                            formControlName="allergy" /><button type="button" class="btn btn-primary col-3"
                            (click)="addAllergy()">Add allergy</button>
                    </div>
                </div>
                <div formArrayName="allergies">
                    <div *ngFor="let allery of allergies.controls; let i=index" class="mt-1" style="width:34%;">
                        <div [formGroupName]="i" class="input-group">
                            <input id="allergy-{{i}}" type="text" class="form-control col-md-auto"
                                formControlName="Name">
                            <button type="button" class="btn btn-danger" (click)="removeAllergy(i)"><span
                                    aria-hidden="true">&times;</span></button>
                        </div>
                    </div>
                </div>

                <div class="form-row mt-3">
                    <div class="col-md-5"><label class="col-form-label"> Medical History </label></div>
                </div>
                <div class="form-row mt-3">
                    <div class="col-xs-8 col-sm-5 input-group"><input type="text" class="form-control" formControlName="medical" />
                        <button type="button" class="btn btn-primary col-3"
                        (click)="addMedical()">Add medical</button></div>
                </div>
                <div formArrayName="medicalhistory">
                    <div *ngFor="let medical of medicalhistory.controls; let i=index" class="mt-1" style="width:34%;">
                        <div [formGroupName]="i" class="input-group">
                            <input id="medical-{{i}}" type="text" class="form-control col-md-auto"
                                formControlName="Name">
                            <button type="button" class="btn btn-danger" (click)="removeMedical(i)"><span
                                    aria-hidden="true">&times;</span></button>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3">
                    <div class="col-md-4"><label class="col-form-label"> Drug History </label></div>
                </div>
                <div class="form-row mt-3">
                    <div class="col-xs-8 col-sm-5 input-group"><input type="text" class="form-control" formControlName="drug" />
                        <button type="button" class="btn btn-primary col-3"
                        (click)="addDrug()">Add drug</button></div>
                </div>
                <div formArrayName="drughistory">
                    <div *ngFor="let drug of drughistory.controls; let i=index" class="mt-1" style="width:34%;">
                        <div [formGroupName]="i" class="input-group">
                            <input id="drug-{{i}}" type="text" class="form-control col-md-auto"
                                formControlName="Name">
                            <button type="button" class="btn btn-danger" (click)="removeDrug(i)"><span
                                    aria-hidden="true">&times;</span></button>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3">
                    <div class="col-md-4"><label class="col-form-label"> Surgical History </label></div>
                </div>
                <div class="form-row mt-3">
                    <div class="col-xs-8 col-sm-5 input-group"><input type="text" class="form-control" formControlName="surgical" />
                        <button type="button" class="btn btn-primary col-3"
                        (click)="addSurgical()">Add surgical</button></div>
                </div>
                <div formArrayName="surgicalhistory">
                    <div *ngFor="let surgical of surgicalhistory.controls; let i=index" class="mt-1" style="width:34%;">
                        <div [formGroupName]="i" class="input-group">
                            <input id="surgical-{{i}}" type="text" class="form-control col-md-auto"
                                formControlName="Name">
                            <button type="button" class="btn btn-danger" (click)="removeSurgical(i)"><span
                                    aria-hidden="true">&times;</span></button>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3">
                    <div class="col-md-4"><label class="col-form-label"> Family History </label></div>
                </div>
                <div class="form-row mt-3">
                    <div class="col-xs-8 col-sm-5 input-group"><input type="text" class="form-control" formControlName="family" />
                        <button type="button" class="btn btn-primary col-3"
                        (click)="addFamily()">Add family</button></div>
                </div>
                <div formArrayName="familyhistory">
                    <div *ngFor="let family of familyhistory.controls; let i=index" class="mt-1" style="width:34%;">
                        <div [formGroupName]="i" class="input-group">
                            <input id="family-{{i}}" type="text" class="form-control col-md-auto"
                                formControlName="Name">
                            <button type="button" class="btn btn-danger" (click)="removeFamily(i)"><span
                                    aria-hidden="true">&times;</span></button>
                        </div>
                    </div>
                </div>
                <div class="form-row mt-3">
                    <div class="col-md-4"><label class="col-form-label"> Others </label></div>
                </div>
                <div class="form-row mt-3">
                    <div class="col-xs-8 col-sm-5"><input type="text" class="form-control" formControlName="others" />
                    </div>
                </div>
            </div>

            <div class="text-center mb-5">
                <button class="btn btn-primary submit-btn justify-text-center" (click)="submit()">Save</button>
            </div>

            <!--Blood History-->
            <div class="modal fade" id="birthHistory" tabindex="-1" role="dialog" aria-labelledby="birthHistoryTitle"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="birthHistoryTitle">Birth History </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group" formGroupName="birthHistory">
                                <div class="form-row mt-3">
                                    <div class="col-md-3">Date of birth</div>
                                    <div class="col-xs-6 col-sm-2"><input type="text" class="form-control"
                                            formControlName="dob" /></div>
                                    <div class="col-md-3">Time of birth </div>
                                    <div class="col-xs-6 col-sm-2"><input type="text" class="form-control"
                                            formControlName="tob" /></div>
                                </div>
                                <div class="form-row mt-3">
                                    <div class="col-md-3">Mode of delivery</div>
                                    <div class="col-xs-6 col-sm-4"><input type="text" class="form-control"
                                            formControlName="mod" /></div>
                                </div>
                                <div class="form-row mt-3">
                                    <div class="col-md-3"> Term/Preterm </div>
                                    <div class="col-xs-6 col-sm-4"><input type="text" class="form-control"
                                            formControlName="term" /></div>
                                </div>
                                <div class="form-row mt-3">
                                    <div class="col-md-3"> Birth weight </div>
                                    <div class="col-xs-6 col-sm-4"><input type="text" class="form-control"
                                            formControlName="birthweight" /></div>
                                </div>
                                <div class="form-row mt-3">
                                    <div class="col-md-3">Admission at birth </div>
                                    <div class="col-xs-6 col-sm-4"><input type="text" class="form-control"
                                            formControlName="admissionatbirth" /></div>
                                </div>
                                <div class="form-row mt-3">
                                    <div class="col-md-3"> Other details </div>
                                    <div class="col-xs-6 col-sm-4"><input type="text" class="form-control"
                                            formControlName="otherdetails" /></div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" (click)="saveBirthHistory()">Save Birth history</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>