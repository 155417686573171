<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="dash-widget" style="margin: 0px 96px 5px 39px;">
            <button type="button" class="close" aria-label="Close" onclick="window.history.back()">
                <span aria-hidden="true">×</span>
            </button>
            <div class="row">
                <div>
                    <h4 class="page-title">Add Appointment</h4>
                </div>
            </div>
            <form [formGroup]="form">
                <div class="form-row">
                <div class="col-6">
                    <div class="form-group" style="margin-bottom: 1px;">
                        <label>Patient Name <span class="text-danger">*</span></label>
                        <input formControlName="patientname" class="form-control" type="text"
                            (input)="Search()" [ngClass]="{ 'is-invalid': submitted && f.patientname.errors }"/>
                        <div class="text-danger" *ngIf="(f.patientname.touched || submitted) && f.patientname.errors?.required">Patient Name is required</div>
                    </div>
                    <ng-template [ngIf]="PatientList.length > 0">
                        <div class="col-12 form-control form-control-sm" *ngFor="let item of PatientList" (click)="onSelect(item)">
                            {{item.FirstName}} {{item.LastName}}/ {{item.PhoneNumber}}
                        </div>
                    </ng-template>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label>Clinic Name <span class="text-danger">*</span></label>
                        <input formControlName="clinicname" class="form-control" type="text" placeholder="Clinic Name" readonly [ngClass]="{ 'is-invalid': submitted && f.clinicname.errors }">
                        <div class="text-danger" *ngIf="(f.clinicname.touched || submitted) && f.clinicname.errors?.required">Clinic Name is required</div>                        
                    </div>
                </div>
                </div>
                <div class="form-row">
                    <input formControlName="patientid" class="form-control" type="hidden" value="APT-0001">
                </div>
                <div class="form-row mt-1">
                    <div class="form-group col-6">
                        <label>Doctor <span class="text-danger">*</span></label>
                        <select class="form-control select" (change)="selectDoctorName($event)" formControlName="doctorid">
                            <option value="" disabled>Select Doctor</option>
                            <option *ngFor="let item of Doctors" [ngValue]="item.DoctorId">{{item.FirstName}} {{item.LastName}}</option>
                        </select>
                        <!-- <div class="text-danger" *ngIf="(f.doctorid.touched || submitted) && f.doctorid.errors?.required">Doctor Name is required</div> -->
                    </div>
                    <div class="form-group col-6">
                        <label>Department</label>
                        <input formControlName="departmentname" type="text" class="form-control" readonly>
                    </div>                    
                </div>
                <div class="form-row">
                    <div class="form-group col-sm-3">
                        <label>Date <span class="text-danger">*</span></label>
                        <input formControlName="Date" type="date" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.Date.errors }">
                        <div class="text-danger" *ngIf="(f.Date.touched || submitted) && f.Date.errors?.required">Date is required</div>
                    </div>
                    <div class="form-group col-sm-3">
                        <ngb-timepicker formControlName="Time" [meridian]="meridian"></ngb-timepicker>
                    </div>
                    <div class="form-group col-6">
                        <label>Mode <span class="text-danger">*</span></label>
                        <select class="custom-select" (change)="selectMode($event)" formControlName="mode" [ngClass]="{ 'is-invalid': submitted && f.mode.errors }">
                            <option *ngFor="let item of ModeList">{{item}}</option>
                        </select>
                        <div class="text-danger" *ngIf="(f.mode.touched || submitted) && f.mode.errors?.required">Mode is required</div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-6" hidden>
                        <label>Patient Status</label>
                        <input formControlName="status" class="form-control" type="text" type="hidden">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-6">
                        <label>Purpose of Visit</label>
                        <textarea formControlName="purposeofvisit" class="form-control" placeholder="Enter visiting purpose"></textarea>
                    </div>
                </div>
                <div class="m-t-20 text-center">
                    <button class="btn btn-primary submit-btn" (click)="submit()" data-dismiss="modal" #closeAddExpenseModal>Create Appointment</button>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="sidebar-overlay" data-reff=""></div>