<div class="main-wrapper account-wrapper">
    <div class="account-page">
        <div class="account-center">
            <div class="account-box">
                <form [formGroup]="registerForm" class="form-signin">
                    <div class="account-logo">
                        <div class="form-group"><a href="#"><img src="assets/img/AccountLogo.png" alt=""></a> <span
                                style="font-size: 30px;">Ai Clinics</span></div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label>First Name</label>
                            <input type="text" autofocus="" class="form-control" formControlName="firstName"
                                [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }">
                            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                <div *ngIf="f.firstName.errors.required">Firstname is required</div>
                            </div>
                        </div>
                        <div class="col form-group">
                            <label>Last Name</label>
                            <input type="text" autofocus="" class="form-control" formControlName="lastName"
                                [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }">
                            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                <div *ngIf="f.lastName.errors.required">Lastname is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label>Email Id</label>
                            <input type="text" autofocus="" class="form-control" formControlName="email"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                             <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                            </div>                            
                        </div>
                        <div class="col form-group">
                            <label>Phone Number</label>
                            <input type="text" autofocus="" class="form-control" formControlName="phonenumber"
                                [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }">
                            <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                <div *ngIf="f.phonenumber.errors.required">Phone Number is required</div>
                            </div>
                        </div>
                    </div>
                     <div class="row">
                        <div class="col form-group">
                            <label>Date Of Birth</label>
                            <input type="date" autofocus="" class="form-control" placeholder="dd-MM-yyyy" formControlName="dateOfBirth"
                                [ngClass]="{ 'is-invalid': submitted && f.dateOfBirth.errors }">
                            <div *ngIf="submitted && f.dateOfBirth.errors" class="invalid-feedback">
                                <div *ngIf="f.dateOfBirth.errors.required">DateOfBirth is required</div>
                            </div>
                        </div>
                        <div class="col form-group">
                            <label>Gender</label><br>
                            <div class="form-check-inline">
                                <label class="form-check-label mr-3" *ngFor="let item of gendersList">
                                    <input type="radio" autofocus="" name="gender" formControlName="gender" value="{{item.name}}"
                                    [ngClass]="{ 'is-invalid': submitted && f.gender.errors }" class="form-check-input" />{{item.name}}
                                </label>
                            </div>                            
                            <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                                <div *ngIf="f.gender.errors.required">Gender is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label>Address</label>
                            <input type="text" autofocus="" class="form-control" formControlName="address"
                                [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
                            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                <div *ngIf="f.address.errors.required">Address is required</div>
                            </div>
                        </div>
                        <div class="col form-group">
                            <label>City</label>
                            <input type="text" autofocus="" class="form-control" formControlName="city"
                                [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
                            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                <div *ngIf="f.city.errors.required">City is required</div>
                            </div>
                        </div>
                        <div class="col form-group">
                            <label>State</label>
                            <input type="text" autofocus="" class="form-control" formControlName="state"
                                [ngClass]="{ 'is-invalid': submitted && f.state.errors }">
                            <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                <div *ngIf="f.state.errors.required">State is required</div>
                            </div>
                        </div>
                        <div class="col form-group">
                            <label>Postal Code</label>
                            <input type="text" autofocus="" class="form-control" formControlName="postalCode"
                                [ngClass]="{ 'is-invalid': submitted && f.postalCode.errors }">
                            <div *ngIf="submitted && f.postalCode.errors" class="invalid-feedback">
                                <div *ngIf="f.postalCode.errors.required">Postal Code is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col form-group">
                            <label>Hospital Name</label>
                            <input type="text" autofocus="" class="form-control" formControlName="hospitalName"
                                [ngClass]="{'is-invalid': submitted && f.hospitalName.errors }">
                            <div *ngIf="submitted && f.hospitalName.errors" class="invalid-feedback">
                                <div *ngIf="f.hospitalName.errors.required">Hospital Name is required</div>
                            </div>
                        </div>
                        <div class="col form-group">
                            <label>Hospital Address</label>
                            <input type="text" autofocus="" class="form-control" formControlName="hospitalAddress"
                                [ngClass]="{'is-invalid': submitted && f.hospitalAddress.errors }">
                            <div *ngIf="submitted && f.hospitalAddress.errors" class="invalid-feedback">
                                <div *ngIf="f.hospitalAddress.errors.required">Hospital Address is required</div>
                            </div>
                        </div>
                    </div>

                        <div class="row">
                            <div class="col form-group">
                                <label>Hospital City</label>
                                <input type="text" autofocus="" class="form-control" formControlName="hospitalCity"
                                    [ngClass]="{ 'is-invalid': submitted && f.hospitalCity.errors }">
                                <div *ngIf="submitted && f.hospitalCity.errors" class="invalid-feedback">
                                    <div *ngIf="f.hospitalCity.errors.required">Hospital City is required</div>
                                </div>
                            </div>
                            <div class="col form-group">
                                <label>Hospital State</label>
                                <input type="text" autofocus="" class="form-control" formControlName="hospitalState"
                                    [ngClass]="{ 'is-invalid': submitted && f.hospitalState.errors }">
                                <div *ngIf="submitted && f.hospitalState.errors" class="invalid-feedback">
                                    <div *ngIf="f.hospitalState.errors.required">hospital State is required</div>
                                </div>
                            </div>
                            <div class="col form-group">
                                <label>Hospital Postal Code</label>
                                <input type="text" autofocus="" class="form-control" formControlName="hospitalPostalCode"
                                    [ngClass]="{ 'is-invalid': submitted && f.hospitalPostalCode.errors }">
                                <div *ngIf="submitted && f.hospitalPostalCode.errors" class="invalid-feedback">
                                    <div *ngIf="f.hospitalPostalCode.errors.required">Hospital Postal Code is required</div>
                                </div>
                            </div>
                        </div>
                    
                    <div class="row">
                        <div class="col form-group">
                            <label>Hospital Phone Number</label>
                            <input type="text" autofocus="" class="form-control" formControlName="hospitalPhonenumber"
                                [ngClass]="{ 'is-invalid': submitted && f.hospitalPhonenumber.errors }">
                            <div *ngIf="submitted && f.hospitalPhonenumber.errors" class="invalid-feedback">
                                <div *ngIf="f.hospitalPhonenumber.errors.required">Hospital Phone Number is required</div>
                            </div>
                        </div>
                        <div class="col form-group">
                            <label>Hospital Mail Id</label>
                            <input type="text" autofocus="" class="form-control" formControlName="HospitalMailId"
                                [ngClass]="{ 'is-invalid': submitted && f.HospitalMailId.errors }">
                            <div *ngIf="submitted && f.HospitalMailId.errors" class="invalid-feedback">
                                <div *ngIf="f.HospitalMailId.errors.required">Hospital Mail Id is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label>Subscription Pack</label>
                            <input type="text" autofocus="" class="form-control" formControlName="subscriptionPack" readonly
                                [ngClass]="{ 'is-invalid': submitted && f.subscriptionPack.errors }">
                            <div *ngIf="submitted && f.subscriptionPack.errors" class="invalid-feedback">
                                <div *ngIf="f.subscriptionPack.errors.required">Select Subscription</div>
                            </div>
                        </div>
                        <div class="col form-group">
                            <label>Amount</label>
                            <input type="text" autofocus="" class="form-control" placeholder="subscription amount" >
                            
                        </div>
                        <div class="col form-group d-none">
                            <label>Start Date</label>
                            <input type="date" autofocus="" class="form-control" placeholder="dd-MM-yyyy" formControlName="CreatedDateTimeStamp"
                                [ngClass]="{'is-invalid': submitted && f.CreatedDateTimeStamp.errors }">
                            <div *ngIf="submitted && f.CreatedDateTimeStamp.errors" class="invalid-feedback">
                                <div *ngIf="f.CreatedDateTimeStamp.errors.required">Subscription Start Date is required</div>
                            </div>
                        </div>
                        <div class="col form-group d-none">
                            <label>Expire Date</label>
                            <input type="date" autofocus="" class="form-control" placeholder="dd-MM-yyyy" formControlName="ExpireDate"
                                [ngClass]="{'is-invalid': submitted && f.ExpireDate.errors }">
                            <div *ngIf="submitted && f.ExpireDate.errors" class="invalid-feedback">
                                <div *ngIf="f.ExpireDate.errors.required">Subscription End Date is required</div>
                            </div>
                        </div>
                    </div>
                   <div class="row">
                        <div class="col form-group">
                            <label>User Name</label>
                            <input type="text" autofocus="" class="form-control" formControlName="userName"
                                [ngClass]="{ 'is-invalid': submitted && f.userName.errors }">
                            <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                                <div *ngIf="f.userName.errors.required">User Name is required</div>
                            </div>
                        </div>
                      <div class="col form-group">
                            <label>Password</label>
                            <input type="password" autofocus="" class="form-control" formControlName="password"
                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                            </div>
                        </div>
                        <div class="col form-group">
                            <label>Confirm Password</label>
                            <input type="password" autofocus="" class="form-control" formControlName="confirmPassword"
                                [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
                            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                                <div *ngIf="f.confirmPassword.errors.mustMatch">Password & ConfirmPassword must match</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group text-center">
                        <button [disabled]="loading" class="btn btn-primary account-btn" (click)="register()">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Register
                        </button>
                        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>