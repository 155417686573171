import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SharedService } from 'src/app/shared.service';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.css']
})
export class PatientDetailsComponent implements OnInit {

  userForm: FormGroup;
  isSubmitted: boolean = false;
  patientId: any;
  currentUser:any;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private service: SharedService, private datePipe: DatePipe,private authenticationService: AuthenticationService) 
  { 
    this.currentUser = this.authenticationService.currentUserValue;
    this.createForm(); 
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        this.patientId = params['patientId'];
      });
    if (this.patientId != undefined) {
      this.getPatientDetails();
    }
  }

  private createForm() {
    this.userForm = this.fb.group({
      firstname: [''],
      lastname: [''],
      mobilenumber: [''],
      emailid: [''],
      dateofbirth: [''],
      gender: [''],
      address: [''],
      city: [''],
      state: [''],
      postalcode: [''],
      bloodgroup: [''],
      allergy: [''],
      medical: [''],
      drug: [''],
      surgical: [''],
      family: [''],
      others: [''],
      birthHistory: this.fb.group({
        id:[''],
        dob: [''],
        tob: [''],
        mod: [''],
        term: [''],
        birthweight: [''],
        admissionatbirth: [''],
        otherdetails: ['']
      }),
      allergies: this.fb.array([]),
      medicalhistory: this.fb.array([]),
      surgicalhistory: this.fb.array([]),
      familyhistory: this.fb.array([]),
      drughistory: this.fb.array([]),
    });
  }

  saveBirthHistory(){
    debugger;
    console.log(this.userForm.controls.birthHistory.value);
    var val = this.userForm.controls.birthHistory.value;
    delete val.id;
    this.service.addBirthhistory(val).subscribe({
      next: res => {
        alert("Birth history details added !!");
      },
      error: (error: any) => {
        console.error('There was an error in saveBirthHistory Method!', error.message);
      }
    });
  }

  updateBirthHistory(){
    debugger;
    console.log(this.userForm.controls.birthHistory.value);
    var val = this.userForm.controls.birthHistory.value;
    this.service.updateBirthhistory(val).subscribe({
      next: res => {
        alert("Birth history details updated !!");
      },
      error: (error: any) => {
        console.error('There was an error in updateBirthHistory Method!', error.message);
      }
    });
  }

  getPatientDetails() {
    this.service.getPatientDetailsByID(this.patientId,this.currentUser.User.HospitalId)
      .subscribe(
        (data) => {
          this.userForm.controls.firstname.setValue(data.FirstName);
          this.userForm.controls.lastname.setValue(data.LastName);
          this.userForm.controls.mobilenumber.setValue(data.PhoneNumber);
          this.userForm.controls.emailid.setValue(data.Email);
          this.userForm.controls.dateofbirth.setValue(this.datePipe.transform(data.DateOfBirth, "yyyy-MM-dd"));
          this.userForm.controls.gender.setValue(data.Gender);
          this.userForm.controls.address.setValue(data.Address);
          this.userForm.controls.city.setValue(data.City);
          this.userForm.controls.state.setValue(data.State);
          this.userForm.controls.postalcode.setValue(data.PostalCode);
          this.userForm.controls.bloodgroup.setValue(data.Bloodgroup);

          if (data.BirthHistory != null) {
            this.userForm.controls.birthHistory.setValue({
              dob: data.BirthHistory.Dob, tob: data.BirthHistory.Tob, mod: data.BirthHistory.ModeOfDelivery,
              term: data.BirthHistory.Term, birthweight: data.BirthHistory.BirthWeight, admissionatbirth: data.BirthHistory.AdmissionAtBirth, otherdetails: data.BirthHistory.OtherDetails
            });
          };

          if (data.Allergies != null) {
            data.Allergies.forEach((t: any) => {
              this.allergies.push(this.addItem(t.Id, t.Name));
            });
          }
          if (data.MedicalHistory != null) {
            data.MedicalHistory.forEach((t: any) => {
              this.medicalhistory.push(this.addItem(t.Id, t.Name));
            });
          }
          if (data.DrugHistory != null) {
            data.DrugHistory.forEach((t: any) => {
              this.drughistory.push(this.addItem(t.Id, t.Name));
            });
          }
          if (data.SurgicalHistory != null) {
            data.SurgicalHistory.forEach((t: any) => {
              this.surgicalhistory.push(this.addItem(t.Id, t.Name));
            });
          }
          if (data.FamilyHistory != null) {
            data.FamilyHistory.forEach((t: any) => {
              this.familyhistory.push(this.addItem(t.Id, t.Name));
            });
          }
        },
        error => {
          console.log(error);
        });
  }

  get f() {
    return this.userForm.controls;
  }
  get birthHistory(): any {
    return this.userForm.get('birthHistory');
  }
  get allergies(): FormArray {
    return this.userForm.get('allergies') as FormArray;
  }
  get medicalhistory(): FormArray {
    return this.userForm.get('medicalhistory') as FormArray;
  }
  get drughistory(): FormArray {
    return this.userForm.get('drughistory') as FormArray;
  }
  get surgicalhistory(): FormArray {
    return this.userForm.get('surgicalhistory') as FormArray;
  }
  get familyhistory(): FormArray {
    return this.userForm.get('familyhistory') as FormArray;
  }

  addAllergy() {
    var name = this.userForm.get('allergy')?.value;
    this.allergies.push(this.createItem(name));
    this.userForm.controls.allergy.setValue('');;
  }
  removeAllergy(i: number) {
    this.allergies.removeAt(i);
  }
  addMedical() {
    var name = this.userForm.get('medical')?.value;
    this.medicalhistory.push(this.createItem(name));
    this.userForm.controls.medical.setValue('');;
  }
  removeMedical(i: number) {
    this.medicalhistory.removeAt(i);
  }
  addDrug() {
    var name = this.userForm.get('drug')?.value;
    this.drughistory.push(this.createItem(name));
    this.userForm.controls.drug.setValue('');;
  }
  removeDrug(i: number) {
    this.drughistory.removeAt(i);
  }
  addSurgical() {
    var name = this.userForm.get('surgical')?.value;
    this.surgicalhistory.push(this.createItem(name));
    this.userForm.controls.surgical.setValue('');;
  }
  removeSurgical(i: number) {
    this.surgicalhistory.removeAt(i);
  }
  addFamily() {
    var name = this.userForm.get('family')?.value;
    this.familyhistory.push(this.createItem(name));
    this.userForm.controls.family.setValue('');;
  }
  removeFamily(i: number) {
    this.familyhistory.removeAt(i);
  }
  createItem(item: any) {
    return this.fb.group({
      Name: [item]
    });
  }
  addItem(id: any, name: any) {
    return this.fb.group({
      Id: [id],
      Name: [name]
    });
  }

  submit() {
    console.log(this.userForm.value);
  };
}
