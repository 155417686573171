import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {Subject, BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from 'src/app/_models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private userLoggedIn = new Subject<boolean>();

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
        this.currentUser = this.currentUserSubject.asObservable();
        this.userLoggedIn.next(false);
        // this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    setUserLoggedIn(userLoggedIn: boolean) {
        this.userLoggedIn.next(userLoggedIn);
    }

    getUserLoggedIn(): Observable<boolean> {
        return this.userLoggedIn.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
    public get current(): User {
        return this.currentUserSubject.value;
    }

    login(val: any) {
        return this.http.post<any>(`${environment.apiUrl}/Authentication/Login/${val.userName}/${val.password}`,{})
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                return user;
            }));
    }

    forgotPassword(val: any) {
        // const params = new HttpParams()
        //     .set('userName', val.userName)
        //     .set('password', val.password);
        //     console.log(params.toString());
        //     debugger;
        return this.http.put<any>(`${environment.apiUrl}/Authentication/UpdatePassword/${val.userName}/${val.password}`,{})
            .pipe(map(user => {
                return user;
            }));
    }

    logout() {
        // remove user from local storage to logout user
        localStorage.removeItem('currentUser');
        // this.currentUserSubject.next(null);
    }

    createUser(val: any) {
        return this.http.post<any>(`${environment.apiUrl}/Authentication/RegisterHospital/`, val)
            .pipe(map(user => {
                return user;
            }));
    }
}