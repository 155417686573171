<div class="page-wrapper">
    <div class="content">
        <form [formGroup]="userForm">
            <div class="table-responsive">
                <div class="row">
                    <div class="col"><label class="font-weight-bold mr-1">Total: </label><label> 300 Rs</label></div>
                    <div class="col"><label class="font-weight-bold mr-1">Due: </label><label> 10 Rs</label></div>
                    <div class="col"><label class="font-weight-bold mr-1">Clinic: </label><label> 100 Rs</label></div>
                    <div class="col"><label class="font-weight-bold mr-1">Pharmacy: </label><label> 100 Rs</label></div>
                    <div class="col"><label class="font-weight-bold mr-1">Lab: </label><label> 100 Rs</label></div>
                </div>
                <button type="submit" class="btn btn-primary btn-default mt-2 mb-2 ml-1"
                    style="float: right;">Print</button>
                <button type="submit" class="btn btn-primary btn-default mt-2 mb-2" style="float: right;"
                    data-toggle="modal" data-target="#saveBill">Add</button>

                <table class="table table-border table-striped custom-table mt-1">
                    <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Invoice NO</th>
                            <th scope="col">Amount (Rs)</th>
                            <th scope="col" style="text-align: center;">Pdf</th>
                            <th scope="col">Edit / Delete</th>
                            <th scope="col">Select</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of billsList | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i =index">
                            <td>{{ item.BillDate | date:"dd-MM-yyyy hh:mm a"}}</td>
                            <td>{{ item.InvoiceNo }}</td>
                            <td>{{ item.TotalAmount }}</td>
                            <td style="text-align: center;">
                                <a href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-file-pdf-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                        <path fill-rule="evenodd"
                                            d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                                    </svg>
                                </a>
                            </td>
                            <td><button type="button" class="btn btn-info btn-sm">Edit</button><button type="button"
                                    class="btn btn-danger ml-1 btn-sm">Delete</button></td>
                            <td>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck1">
                                    <label class="custom-control-label" for="customCheck1"></label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ngb-pagination *ngIf="billsList?.length" class="pagination justify-content-center" [collectionSize]="billsList.length" [(page)]="page" [maxSize]="5" [pageSize]="pageSize">
                    <ng-template ngbPaginationPrevious>Prev</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                  </ngb-pagination>
            </div>            
        </form>
    </div>
</div>