import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css']
})
export class AppointmentsComponent implements OnInit {
  appointments: any;
  patientId:any;

  constructor(public service: SharedService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        this.patientId = params['patientId'];
      });
    if (this.patientId != undefined) {
      this.getAppointments();
    }    
  }

  getAppointments() {
    this.service.getPatientAppointmets(this.patientId).subscribe(data => {
      this.appointments = data;
    });
  }

}
