import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../shared.service';
import { AppointmentModel } from './appointment.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

// calendar view modules
import { ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  black: {
    primary: '#444443',
    //secondary:'#FF0000',
  },
};

@Component({
  selector: 'app-appointment',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css']
})
export class AppointmentComponent implements OnInit {

  dep: any;
  appointments: AppointmentModel[] = [];
  viewDate: Date = new Date();
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  modalData: {
    action: string;
    //event: CalendarEvent;
  };

  currentItem: any;
  appointmentTime: any;
  constructor(
    public service: SharedService,
    private route: ActivatedRoute,
    private modal: NgbModal,
    private router: Router,
    private datePipe: DatePipe,
  ) { this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}')}

  currentUser:any;  
  ngOnInit(): void {
    if (JSON.stringify(this.currentUser) == '{}' && localStorage.getItem('currentUser') == undefined) {
      this.router.navigateByUrl('login');      
    }
    else{
    this.getAllAppointments();
    }
  }

  getAllAppointments() {
    this.service.getAppointmetsList(this.currentUser.User.HospitalId).subscribe((data: any) => {
      this.appointments = data

      // let data=fromdb();
      for (let x of data) {
        let appTime = new Date(x["Date"]);
        this.appointmentTime = this.datePipe.transform(appTime, 'shortTime');
        this.events = [
          ...this.events,
          {
            id: x["AppointmentId"],
            //start: addHours(startOfDay(new Date(x["Date"])), 0),
            start: addHours(new Date(x["Date"]), 0),
            // end: addHours(new Date(x["Time"]), 0),
            title: x["Patient"].FirstName + ' ' + x["Patient"].LastName + ',  ' + this.appointmentTime + '  (Dr ' + x["DoctorName"] + ')',
            color: colors.black,
            actions: this.actions,
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            draggable: true,
          },
          // {
          //   id: x["AppointmentId"],
          //   start: subDays(startOfDay(new Date(x["Date"])), 0),
          //   end: addDays(new Date(x["Date"]), 0),
          //   title: x["PurposeOfVisit"],
          //   color: colors.yellow,
          //   actions: this.actions,            
          // },
        ]
      };
      this.refresh.next();
    });
  }

  editPatient(item: any) {
    this.dep = item;
    // this.ModalTitle="Edit Patient Details";
  }

  deleteAppointment(id: any) {
    this.service.deleteAppointment(id,this.currentUser.User.HospitalId).subscribe(res => {
      this.appointments = this.appointments.filter(item => item.AppointmentId !== id);
      console.log('Appointment deleted successfully!');
    })
  }

  // Calendar functions
  setView(view: CalendarView) {
    this.view = view;
  }

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        // this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        // this.handleEvent('Deleted', event);
      },
    },
  ];

  handleEvent(action: string, event: CalendarEvent): void {
    //this.modalData = { event, action };
    this.currentItem = event.id;
    this.modalData = { action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }


  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [
    //  
    // {
    //   start: subDays(startOfDay(new Date()), 1),
    //   end: addDays(new Date(), 1),
    //   title: 'A 3 day event',
    //   color: colors.red,
    //   actions: this.actions,
    //   allDay: true,
    //   resizable: {
    //     beforeStart: true,
    //     afterEnd: true,
    //   },
    //   draggable: true,
    // },  
    // {
    //   start: subDays(endOfMonth(new Date()), 3),
    //   end: addDays(endOfMonth(new Date()), 3),
    //   title: 'A long event that spans 2 months',
    //   color: colors.blue,
    //   allDay: true,
    // },
    // {
    //   start: addHours(startOfDay(new Date()), 2),
    //   end: addHours(new Date(), 2),
    //   title: 'A draggable and resizable event',
    //   color: colors.yellow,
    //   actions: this.actions,
    //   resizable: {
    //     beforeStart: true,
    //     afterEnd: true,
    //   },
    //   draggable: true,
    // },
  ];

  // //assume data from db
  // //example: Hospital appointment info
  // let data=fromdb();
  // for(let x of data)
  // {
  // this.events = [
  //           ...this.events,
  //           {
  // 	start:x["appointment_date"],
  // 	title:x["patient_name"]+x["medical_problem"]
  //  	 }
  // 	]
  // }

  activeDayIsOpen: boolean = true;
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    // this.handleEvent('Dropped or resized', event);
  }
  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event ten',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
    this.router.navigate(['/addappointment']);
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

}
