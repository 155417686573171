<div class="page-wrapper">
    <div class="b-heading">Clinic bill details</div>
    <div class="col"><button class="btn btn-success" style="float: right;" (click)="saveBill()">Save bill</button></div>
    <div class="content">
        <form [formGroup]="billForm">
            <div class="form-row">
                    <div class="col-4">Patient Name <span class="text-danger">*</span>
                    <input type="text" class="form-control" formControlName="patientName"
                        [(ngModel)]="searchPatientName"
                        (input)="Search(1)" />
                    <div class="text-danger" *ngIf="(f.patientName.touched || submitted) && f.patientName.errors?.required">Patient name is required</div>
            <ng-template [ngIf]="SearchPatientsList.length > 0">
                <div>
                    <div class="col-11 form-control select2" *ngFor="let item of SearchPatientsList"
                        (click)="onPatientSelect(item)">
                        {{item.FirstName}} {{item.LastName}} / {{item.PhoneNumber}}
                    </div>
                </div>
            </ng-template>
        </div>
                <div class="col-4">Consultant Doctor <span class="text-danger">*</span>
                    <input type="text" class="form-control" formControlName="doctorName"
                        [(ngModel)]="searchDoctorName"
                        (input)="Search(2)" />
                    <div class="text-danger" *ngIf="(f.doctorName.touched || submitted) && f.doctorName.errors?.required">Doctor name is required</div>
            <ng-template [ngIf]="SearchDoctorsList.length > 0">
                <div>
                    <div class="col-11 form-control select2" *ngFor="let item of SearchDoctorsList"
                        (click)="onDoctorSelect(item)">
                        {{item.UserName}} / {{item.Speciality}}
                    </div>
                </div>
            </ng-template>
                </div>
                <div class="col-4">Bill Date and Time  <span class="text-danger">*</span><input type="text" class="form-control" formControlName="BillDate" [readonly]=editBill/>
                    <div class="text-danger" *ngIf="(f.BillDate.touched || submitted) && f.BillDate.errors?.required">Bill Date is required</div>
                </div>
            </div>

            <!-- Service details -->
            <div class="container mt-2">
                <div class="form-row">
                    
                        <div>Search Service</div>
                        <div class="input-group">
                        <input type="text" class="form-control"
                                [(ngModel)]="searchServiceName" [ngModelOptions]="{standalone: true}"
                                (input)="Search(3)" />                        
                        <div class="col-md-3"> <button class="btn btn-primary" (click)="addService()">Add New
                                Service</button></div>
                        </div>
                    <ng-template [ngIf]="SearchServicesList.length > 0">
                        <div>
                            <div class="col-xs-6" class="form-control select2" *ngFor="let item of SearchServicesList"
                                (click)="onSelect(item)">
                                {{item.Name}}
                            </div>
                        </div>
                    </ng-template>

                    <!-- <input id="typeahead-format" type="text" class="form-control" [(ngModel)]="searchServiceName" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="search" /> -->
                    <!-- <div class="form-group">
                                    <label for="ArtistName">Band Name:</label>
                                    <input id="ArtistName" type="text"
                                           name="ArtistName"
                                           class="form-control"                     
                                           [(ngModel)]="SearchServicesList.Name"
                                           [ngbTypeahead]="search"                     
                                           [resultFormatter]="resultFormatBandListValue"
                                           [inputFormatter]="inputFormatBandListValue"
                                           #instance="ngbTypeahead"                     
                                      />
                                  </div> -->
                </div>
                <div class="form-row mt-4">
                    <table class="form-table mt-1">
                        <thead>
                            <tr class="text-nowrap">
                                <th scope="col">Service Name</th>
                                <th scope="col">Rate (Rs)</th>
                                <th scope="col">Units</th>
                                <th scope="col">Discount (%)</th>
                                <th scope="col">Discount amount</th>
                                <th scope="col">Final Rate (Rs)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="enableAddService">
                                <td><input type="text" class="form-control"
                                        formControlName="serviceName"></td>
                                <td><input type="text" class="form-control" formControlName="amount"></td>
                                <td><input type="text" class="form-control" formControlName="quantity"></td>
                                <td><input type="text" class="form-control" formControlName="discountPercentage" [readonly]="roleId != 1">
                                </td>
                                <td><input type="text" class="form-control" readonly>{{discountAmount}}</td>
                                <td><input type="text" class="form-control" readonly>{{finalAmount}}</td>
                                <td><button class="btn btn-primary" (click)="saveService()">Save
                                        Service</button></td>
                                        <td><button type="button" class="btn remove btn-outline-danger"
                                            (click)="cancelService()"><span aria-hidden="true">&times;</span></button>
                                        </td>
                            </tr>
                            <ng-container formArrayName="Services"
                                *ngFor="let item of servicesList.controls; let i=index">
                                <tr [formGroupName]="i">
                                    <td><input type="text" class="form-control form-control-sm"
                                            formControlName="serviceName" readonly /></td>
                                    <td><input type="text" class="form-control form-control-sm"
                                            [id]="'serviceAmount' + i" formControlName="amount"
                                            readonly /></td>
                                    <td> <input type="text" class="form-control form-control-sm"
                                            [id]="'serviceQuantity' + i" formControlName="quantity" (keypress)="numberOnly($event)"
                                            (input)="$event.target.value != '' && calculateDiscount(i,$event.target.value)" /></td>
                                    <td><input type="text" class="form-control form-control-sm"
                                            [id]="'servicePercentage' + i" formControlName="discountPercentage"
                                            readonly /></td>
                                    <td> <input type="text" class="form-control form-control-sm"
                                            formControlName="serviceDiscountAmount" readonly />
                                    </td>
                                    <td><input type="text" class="form-control form-control-sm"
                                            formControlName="finalAmount" readonly /></td>
                                    <td><button type="button" class="btn remove btn-outline-danger"
                                            (click)="removeService(i)"><span aria-hidden="true">&times;</span></button>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr>
                                <td colspan="4"></td>
                                <th>Total: </th>
                                <td class="col-2"><input type="text" class="form-control" [(ngModel)]="totalFinalRates"
                                        [ngModelOptions]="{standalone: true}" readonly /></td>
                            </tr>
                            <tr>
                                <td colspan="4"></td>
                                <th>Discount (%): </th>
                                <td class="col-2"><input type="text" class="form-control" min="0" pattern="[0-9]*"
                                        [(ngModel)]="modifiedBillDiscountPercentage" (input)="$event.target.value != '' && inputDiscount($event.target.value)"
                                        [ngModelOptions]="{standalone: true}" (keypress)="numberOnly($event)" />
                                </td>
                            </tr>
                            
                        </tbody>                        
                    </table>
                    
                </div>
            </div>
            <div>
                <ngb-pagination *ngIf="servicesList?.length" class="pagination d-flex justify-content-center"
                    [collectionSize]="servicesList.length" [(page)]="page" [maxSize]="5" [pageSize]="pageSize">
                    <!-- <ng-template ngbPaginationPrevious>Prev</ng-template>
                                                                <ng-template ngbPaginationNext>Next</ng-template> -->
                </ngb-pagination>
            </div>

            <!-- Service details
                     <div class="form-group" formGroupName="serviceDetails">                
                        <div class="container">
                            <table class="table table-lg mt-1 table-responsive">
                                <thead>
                                    <tr class="form-row">
                                        <th scope="col" class="col-md-2">Service Name</th>
                                        <th scope="col" class="col-md-2">Rate (Rs)</th>
                                        <th scope="col" class="col-md-2">discount (%)</th>
                                        <th scope="col" class="col-md-2">discount amount</th>
                                        <th scope="col" class="col-md-2">Net Rate (Rs)</th>
                                        <th scope="col" class="col-md-2"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="form-row" [ngClass]="{'d-block':enableAddService, 'd-none':!enableAddService}">
                                        <td class="col-md-2"><input type="text" class="form-control"></td>
                                        <td><input type="text" class="form-control"></td>
                                        <td><input type="text" class="form-control"></td>
                                        <td><input type="text" class="form-control"></td>
                                        <td><input type="text" class="form-control"></td>
                                        <td><button class="btn btn-primary" (click)="saveService()">Save
                                                ServiceName</button></td>
                                    </tr>
        
                                    <ng-container formArrayName="Services"
                                        *ngFor="let item of servicesList.controls; let i=index">
                                        <tr [formGroupName]="i">
                                            <td> <input type="text" class="form-control form-control-sm"
                                                    formControlName="ServiceId" />
                                            </td>
                                            <td><input type="text" class="form-control form-control-sm"
                                                    formControlName="Name" /></td>
                                            <td><input type="text" class="form-control form-control-sm"
                                                    formControlName="Amount" /></td>
                                            <td><input type="text" class="form-control form-control-sm"
                                                    formControlName="DiscountPercentage" /></td>
                                                    <td><input type="text" class="form-control form-control-sm"
                                                        formControlName="DiscountAmount" /></td>
                                            <td><input type="text" class="form-control form-control-sm" />{{finalAmount}}</td>
                                            <td><button type="button" class="btn remove btn-outline-danger"
                                                    (click)="removeService(i)"><span aria-hidden="true">&times;</span></button>
                                            </td>
                                        </tr>
                                    </ng-container>
        
                                </tbody>
                            </table>
                        </div>
                    </div> -->

            <!-- Payment details -->
            <div class="container mt-2 p-d">
                <div class="input-group"><label class="font-weight-bold lab-pd" *ngIf="paymentList != null && paymentList.length > 0">Payment
                        details</label>
                    <div class="col-md-3"> <button type="button" class="btn btn-primary" data-toggle="modal"
                            data-target="#exampleModalCenter">Add
                            Payment</button></div>
                </div>
                <!-- <table>
                                <thead>
                                    <tr class="row">
                                        <th class="col">Date</th>
                                        <th class="col">Amount paid</th>
                                        <th class="col">Mode</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><input type="text" class="form-control form-control-sm"
                                            formControlName="paymentDate"></td>
                                        <td><input type="text" class="form-control form-control-sm"
                                            formControlName="amountPaid"></td>
                                        <td><input type="text" class="form-control form-control-sm"
                                            formControlName="modeOFPayment"></td>
                                    </tr>
                                </tbody>
                            </table> -->

                <div class="row" *ngIf="paymentList != null && paymentList.length > 0">
                    <div class="col font-weight-bold">Date</div>
                    <div class="col font-weight-bold">Amount paid</div>
                    <div class="col font-weight-bold">Mode</div>
                </div>
                <div class="row" *ngFor="let item of paymentList">
                    <div class="col">{{item.Billdate | date:"dd-MM-yyyy"}}</div>
                    <div class="col">{{item.PaidAmount}}</div>
                    <div class="col">{{item.PaymentMethod}}</div>
                    <button type="button" class="btn remove btn-outline-danger" *ngIf="item.EditPayment"
                    (click)="removePayment(item)"><span aria-hidden="true">&times;</span></button>
                </div>
            </div>

            <!-- Total bill details -->
            <div class="form-group">
                <div class="container tb-d mt-4">
                    <div class="row bill-background">
                        <div class="col"><span>Total Services: </span><span
                                class="font-weight-bold">{{totalServices}}</span>
                        </div>
                        <div class="col"><span>Bill discount (%): </span><span
                                class="font-weight-bold">{{modifiedBillDiscountPercentage}}</span></div>
                        <div class="col"><span>Bill Discount amount: </span><span
                                class="font-weight-bold">{{billDiscountAmount}}</span></div>
                        <div class="col"><span>Net amount after discount: </span><span
                                class="font-weight-bold">{{netAmountAfterDiscount}}</span></div>
                        <div class="col"><span>Total amount paid: </span><span
                                class="font-weight-bold">{{totalAmountPaid}}</span></div>
                        <div class="col"><span class="font-weight-bold">Due amount: </span><span
                                class="text-danger font-weight-bold">{{totalDueAmount}}</span></div>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title" id="exampleModalLongTitle">Add payment</h3>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <label for="billDate">Payment Date :</label>
                            <input class="form-control" type="date" placeholder="dd-MM-yyyy"
                                formControlName="paymentDate" />
                            <div>Amount : <input class="form-control" formControlName="paymentAmount" (keypress)="numberOnly($event)"></div>
                            <div>PaymentMethod : </div>
                            <div class="form-group">
                                <select class="custom-select mb-3" (change)="changePayment($event)"
                                    formControlName="PaymentModeId">
                                    <option *ngFor="let item of paymentMethods" [value]="item.PaymentModeId">
                                        {{ item.PaymentMethod }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal" #closeAddExpenseModal (click)="addToPaymentList();">Save
                                changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>