import { Patient } from 'src/app/patient';
import { User } from './../../_models/user';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/shared.service';
import { element } from '../../../../node_modules/protractor';

@Component({
  selector: 'app-add-prescription',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './add-prescription.component.html',
  styleUrls: ['./add-prescription.component.css']
})
export class AddPrescriptionComponent implements OnInit {

  PrescptionId: any;
  PrescptionDate: any;
  hospitalname = '';
  chiefcomplaint = '';
  pasthistory = '';
  clinicalexamination = '';
  investigation = '';
  diagnosis = '';
  remark = '';
  searchAdvice: any;
  advicesList: any = [];
  searchAdvicesList: any = [];
  searchMedicineGroup: any;
  medicineGroupList: any = [];
  searchMedicineGroupList: any = [];
  searchComplaintList: any = [];
  complaintMasterList:any =[];
  searchExaminationsList: any = [];
  examinationMasterList:any=[];
  searchDiagnosisList: any = [];
  diagnosisMasterList:any=[];

  // today = (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, -1).slice(0, 10);

  today: any;
  hours: any;
  minutes: any;
  meridian = true;
  myForm: FormGroup;
  errorMessage: any;
  name: any = 'Save Prescription';

  frequencyList: any = [
    { id: 1, name: 'Morning', isSelected: false },
    { id: 2, name: 'Afternoon', isSelected: false },
    { id: 3, name: 'Night', isSelected: false }
  ];
  hospitalList: any = [];
  PatientList: any = [];
  SearchPatientList: any = [];
  editPrescriptionId: any;
  prescriptionDetails: any = [];
  patientName: any;
  currentUser: any;
  doctorDetails: any;
  submitted = false;
  loading = false;
  datePipe: DatePipe = new DatePipe('en-US');

  constructor(
    public service: SharedService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}')
    this.createForm();
  }

  ngOnInit(): void {
    if (JSON.stringify(this.currentUser) == '{}' && localStorage.getItem('currentUser') == undefined) {
      this.router.navigateByUrl('login');
    }
    else {
      this.loadHospitalList();
      this.loadPatientList();
      this.route.queryParams.subscribe(
        params => {
          this.editPrescriptionId = params['PrescriptionId'];
          this.patientName = params['patientName'];
        });
      if (this.editPrescriptionId != undefined) {
        this.getPrescriptionDetails();
        this.name = 'update Prescription';
      }
      if (this.patientName != undefined) {
        this.myForm.controls.patientname.setValue(this.patientName);
      }

      if (this.currentUser.User.RoleId == 2) {
        this.getDoctorDetails();
      }
      this.myForm.controls.PrescptionDate.setValue(this.datePipe.transform(new Date(), "yyyy-MM-dd"));
      let now = this.datePipe.transform(new Date(), "HH:mm a");
      this.hours = now?.split(':')[0];
      this.minutes = now?.split(':')[1];
      this.myForm.controls.PrescptionTime.setValue({ hour: parseInt(this.hours), minute: parseInt(this.minutes) });
    }
  }

  calculateBmi(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9 with decimals
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else {
      var anthropometryId = null;
      if (this.editPrescriptionId != undefined) {
        anthropometryId = this.myForm.controls.Anthropometry.value.id;
      }
      if (this.myForm.controls.Anthropometry.value.height != '' && this.myForm.controls.Anthropometry.value.height != null
        && this.myForm.controls.Anthropometry.value.weight != '' && this.myForm.controls.Anthropometry.value.weight != null) {
        var result = (parseFloat(this.myForm.controls.Anthropometry.value.weight) /
          (parseFloat(this.myForm.controls.Anthropometry.value.height) * parseFloat(this.myForm.controls.Anthropometry.value.height)) * 10000);
        this.myForm.controls.Anthropometry.setValue({
          id: anthropometryId, height: this.myForm.controls.Anthropometry.value.height,
          weight: this.myForm.controls.Anthropometry.value.weight,
          bmi: Math.round(result * 100 + Number.EPSILON) / 100,
          others: this.myForm.controls.Anthropometry.value.others
        });
      }
      else if (this.myForm.controls.Anthropometry.value.height == '' || this.myForm.controls.Anthropometry.value.height == null
        || this.myForm.controls.Anthropometry.value.weight == '' || this.myForm.controls.Anthropometry.value.weight == null) {
        this.myForm.controls.Anthropometry.setValue({
          id: anthropometryId, height: this.myForm.controls.Anthropometry.value.height,
          weight: this.myForm.controls.Anthropometry.value.weight,
          bmi: '',
          others: this.myForm.controls.Anthropometry.value.others
        });
      }
      return true;
    }
  }

  private createForm() {
    this.myForm = this.fb.group({
      PrescptionId: '',
      PrescptionDate: [''],
      PrescptionTime: [''],
      hospitalname: [this.currentUser.User.Hospital.HospitalName, Validators.required],
      hospitalId: [this.currentUser.User.HospitalId, Validators.required],
      patientname: ['', Validators.required],
      patientid: [''],
      doctorname: ['', Validators.required],
      doctorid: [''],
      chiefcomplaint: '',
      complaints: this.fb.array([]),
      pasthistory: '',
      pastHistories: this.fb.array([]),
      clinicalexamination: '',
      clinicalExaminations: this.fb.array([]),
      investigation: '',
      investigations: this.fb.array([]),
      diagnosis: '',
      diagnosisList: this.fb.array([]),
      remark: '',
      remarks: this.fb.array([]),
      followUpDate: '',
      followUpTime: '',
      referDoctor: '',
      referDate: '',
      referTime: '',
      followUpNote: '',
      referNote: '',
      Anthropometry: new FormGroup({
        id: new FormControl(''),
        height: new FormControl(''),
        weight: new FormControl(''),
        bmi: new FormControl(''),
        others: new FormControl('')
      }),
      Vitals: new FormGroup({
        id: new FormControl(''),
        pulseRate: new FormControl(''),
        respiratoryRate: new FormControl(''),
        temperature: new FormControl(''),
        sbp: new FormControl(''),
        dbp: new FormControl(''),
        spO2: new FormControl('')
      }),
      medicineName: '',
      frequency: '',
      noOfDays: '',
      quantity: '',
      instructions: '',
      customText: '',
      medicines: new FormArray([]),
      selectedFrequency: this.fb.array([]),
    });
  }

  getPrescriptionDetails() {
    this.myForm.reset();
    this.service.getPrescription(this.editPrescriptionId, this.currentUser.User.HospitalId).subscribe((data: any) => {
      this.prescriptionDetails = data;
      this.myForm.controls.PrescptionDate.setValue(this.datePipe.transform(data.PrescptionDate, "yyyy-MM-dd"));
      let now = this.datePipe.transform(data.PrescptionDate, "HH:mm a");
      this.hours = now?.split(':')[0];
      this.minutes = now?.split(':')[1];
      this.myForm.controls.PrescptionTime.setValue({ hour: parseInt(this.hours), minute: parseInt(this.minutes) });

      this.myForm.controls.hospitalId.setValue(this.currentUser.User.HospitalId);
      this.myForm.controls.hospitalname.setValue(this.currentUser.User.Hospital.HospitalName);
      this.myForm.controls.patientid.setValue(data.Patient.PatientId);
      this.myForm.controls.patientname.setValue(data.Patient.FirstName + ' ' + data.Patient.LastName);
      this.myForm.controls.doctorname.setValue(data.DoctorName);
      this.myForm.controls.followUpDate.setValue(this.datePipe.transform(data.FollowUpDate, "yyyy-MM-dd"));
      if (data.FollowUpDate != null) {
        let now = new Date(this.prescriptionDetails.FollowUpDate);
        let hours = ("0" + now.getHours()).slice(-2);
        let minutes = ("0" + now.getMinutes()).slice(-2);
        this.myForm.controls.followUpTime.setValue({ hour: parseInt(hours), minute: parseInt(minutes) });
      }
      this.myForm.controls.followUpNote.setValue(data.FollowUpNote);
      this.myForm.controls.referDoctor.setValue(data.ReferDoctor);
      this.myForm.controls.referNote.setValue(data.ReferNote);
      this.myForm.controls.referDate.setValue(this.datePipe.transform(data.ReferDate, "yyyy-MM-dd"));
      if (data.ReferDate != null) {
        let now = new Date(data.ReferDate);
        let hours = ("0" + now.getHours()).slice(-2);
        let minutes = ("0" + now.getMinutes()).slice(-2);
        this.myForm.controls.referTime.setValue({ hour: parseInt(hours), minute: parseInt(minutes) });
      }
      if (data.Medicines != null) {
        data.Medicines.forEach((t: any) => {
          this.medicineList.push(this.addPatchValues(t.Id, t.MedicineName, t.Frequency,
            t.NoOfDays, t.Quantity, t.Instructions, t.CustomText));
        });
      }
      //Update Anthropometry values
      if (data.Anthropometry != null) {
        this.myForm.controls.Anthropometry.setValue({
          id: data.Anthropometry.Id, height: data.Anthropometry.Height, weight: data.Anthropometry.Weight,
          bmi: data.Anthropometry.Bmi, others: data.Anthropometry.Others
        });
      }

      //Update Vitals values
      if (data.Vitals != null) {
        this.myForm.controls.Vitals.setValue({
          id: data.Vitals.Id, pulseRate: data.Vitals.PulseRate, respiratoryRate: data.Vitals.RespiratoryRate,
          spO2: data.Vitals.SpO2, temperature: data.Vitals.Temperature, sbp: data.Vitals.Sbp, dbp: data.Vitals.Dbp
        });
      }

      if (data.Complaints != null) {
        data.Complaints.forEach((t: any) => {
          this.complaintsList.push(this.addItem(t.Id, t.Name));
        });
      }
      if (data.PastHistories != null) {
        data.PastHistories.forEach((t: any) => {
          this.pasthistoryList.push(this.addItem(t.Id, t.Name));
        });
      }
      if (data.ClinicalExaminations != null) {
        data.ClinicalExaminations.forEach((t: any) => {
          this.clinicalExaminationList.push(this.addItem(t.Id, t.Name));
        });
      }
      if (data.Investigations != null) {
        data.Investigations.forEach((t: any) => {
          this.investigationList.push(this.addItem(t.Id, t.Name));
        });
      }
      if (data.DiagnosisList != null) {
        data.DiagnosisList.forEach((t: any) => {
          this.diagnosisList.push(this.addItem(t.Id, t.Name));
        });
      }
      if (data.Remarks != null) {
        data.Remarks.forEach((t: any) => {
          this.remarksList.push(this.addItem(t.Id, t.Name));
        });
      }

    });
  }

  get Anthropometry(): any {
    return this.myForm.get('Anthropometry');
  }
  get Vitals(): any {
    return this.myForm.get('Vitals');
  }
  get complaintsList(): FormArray {
    return this.myForm.get('complaints') as FormArray;
  }
  loadHospitalList() {
    this.service.getAllHospitalNames().subscribe((data: any) => {
      this.hospitalList = data;
    });
  }
  changeHospitalName(e: any) {
    this.myForm.controls.hospitalname.setValue(e.target.value, {
      onlySelf: true
    })
  }

  getDoctorDetails() {
    this.service.getDoctorDetailsById(this.currentUser.User.UserId, this.currentUser.User.HospitalId).subscribe((data: any) => {
      this.doctorDetails = data;

      if (this.doctorDetails != undefined && this.doctorDetails.UserId == this.currentUser.User.UserId) {
        this.myForm.controls.doctorname.setValue(this.currentUser.User.FirstName + ' ' + this.currentUser.User.LastName);
        this.myForm.controls.doctorid.setValue(this.doctorDetails.DoctorId);
      }
      this.getAdviceGroupList();
      this.getMedicineGroupList();
      this.getComplaintList();
      this.getExaminationList();
      this.getDiagnosisList();
    });
  }

  loadPatientList() {
    this.service.getPatientsList(this.currentUser.User.HospitalId).subscribe(data => {
      data.forEach(element => {
        if (element.LastName == null) {
          element.LastName = "";
        }
      });
      this.SearchPatientList = data;

      if (this.myForm.controls.patientname.value != "" && this.myForm.controls.patientname.value != null) {
        let patient = this.SearchPatientList.filter((data: any) => {
          var name = data.FirstName + ' ' + data.LastName;
          return name.toString().toLowerCase().match(this.myForm.controls.patientname.value.toLowerCase()) ? data : '';
        });

        if (patient.length > 0 && patient != null) {
          patient.forEach((t: any) => {
            this.myForm.controls.patientid.setValue(t.PatientId);
          });
        }
      }

    });
  }

  onSelect(e: any) {
    this.myForm.controls.patientname.setValue(e.FirstName + ' ' + e.LastName, {
      onlySelf: true
    });
    this.myForm.controls.patientid.setValue(e.PatientId, {
      onlySelf: true
    });
    this.myForm.value.patientname = e.FirstName + ' ' + e.LastName;
    this.myForm.value.patientid = e.PatientId;
    this.PatientList.length = 0;
  }
  Search() {
    if (this.myForm.controls.patientname.value == "") {
      if (this.PatientList.length > 0) {
        this.PatientList.length = 0;
      }
    } else {
      this.PatientList = this.SearchPatientList.filter((data: any) => {
        return data.FirstName.toString().toLowerCase().match(this.myForm.controls.patientname.value.toLowerCase());
      });
    }
  }

  // get complaintsList(): FormArray {
  //   return this.myForm.get('complaints') as FormArray;
  // }
  get pasthistoryList(): FormArray {
    return this.myForm.get('pastHistories') as FormArray;
  }
  get clinicalExaminationList(): FormArray {
    return this.myForm.get('clinicalExaminations') as FormArray;
  }
  get investigationList(): FormArray {
    return this.myForm.get('investigations') as FormArray;
  }
  get diagnosisList(): FormArray {
    return this.myForm.get('diagnosisList') as FormArray;
  }
  get remarksList(): FormArray {
    return this.myForm.get('remarks') as FormArray;
  }
  get medicineList(): FormArray {
    return this.myForm.get('medicines') as FormArray;
  }
  get selectedFrequency(): FormArray {
    return this.myForm.get('selectedFrequency') as FormArray;
  }

  get f() {
    return this.myForm.controls;
  }

  createItem(item: any) {
    return this.fb.group({
      Name: [item]
    });
  }

  addItem(id: any, name: any) {
    return this.fb.group({
      Id: [id],
      Name: [name]
    });
  }

  //Edit medicine values
  addPatchValues(id: any, name: any, frequency: any, days: any, measure: any, instructions: any, customText: any) {
    return this.fb.group({
      id: [id],
      medicineName: [name],
      frequency: [frequency],
      noOfDays: [days],
      quantity: [measure],
      instructions: [instructions],
      customText: [customText]
    });
  }

  addComplaint(event: any): void {
    var name = event.target.value;
    if (name == "") {
      name = this.myForm.controls.chiefcomplaint.value;
    }
    if (name != null && name != "") {
      this.complaintsList.push(this.createItem(name));
      this.myForm.controls.chiefcomplaint.setValue("");
      if (this.complaintMasterList.length > 0) {
        this.complaintMasterList.length = 0;
      }
    }
    event.target.value = "";
  }
  removeComplaint(i: number) {
    this.complaintsList.removeAt(i);
  }

  addPastHistory(event: any) {
    var name = event.target.value;
    if (name == "") {
      name = this.myForm.controls.pasthistory.value;
    }
    if (name != null && name != "") {
      this.pasthistoryList.push(this.createItem(name));
      this.myForm.controls.pasthistory.setValue("");
    }
    event.target.value = "";
  }
  removePasthistory(i: number) {
    this.pasthistoryList.removeAt(i);
  }

  addClinicalExamination(event: any) {
    var examination = event.target.value;
    if (examination == "") {
      examination = this.myForm.controls.clinicalexamination.value;
    }
    if (examination != null && examination != "") {
      this.clinicalExaminationList.push(this.createItem(examination));
      this.myForm.controls.clinicalexamination.setValue("");
      if (this.examinationMasterList.length > 0) {
        this.examinationMasterList.length = 0;
      }
    }
    event.target.value = "";
  }
  removeClinicalExamination(i: number) {
    this.clinicalExaminationList.removeAt(i);
  }

  addInvestigation(event: any) {
    var investigation = event.target.value;
    if (investigation == "") {
      investigation = this.myForm.controls.investigation.value;
    }
    if (investigation != null && investigation != "") {
      this.investigationList.push(this.createItem(investigation));
      this.myForm.controls.investigation.setValue("");
    }
    event.target.value = "";
  }
  removeInvestigation(i: number) {
    this.investigationList.removeAt(i);
  }

  addDiagnosis(event: any) {
    var diagnosis = event.target.value;
    if (diagnosis == "") {
      diagnosis = this.myForm.controls.diagnosis.value;
    }
    if (diagnosis != null && diagnosis != "") {
      this.diagnosisList.push(this.createItem(diagnosis));
      this.myForm.controls.diagnosis.setValue("");
      if (this.diagnosisMasterList.length > 0) {
        this.diagnosisMasterList.length = 0;
      }
    }
    event.target.value = "";
  }
  removeDiagnosis(i: number) {
    this.diagnosisList.removeAt(i);
  }

  onCheckboxChange(e: any) {
    if (e.target.checked) {
      this.selectedFrequency.push(new FormControl(e.target.value));
      for (let i = 0; i < this.frequencyList.length; i++) {
        if (this.frequencyList[i].name.toLowerCase() == e.target.value.toLowerCase()) {
          this.frequencyList[i].isSelected = true;
        }
      }
    } else {
      const index = this.selectedFrequency.controls.findIndex(x => x.value === e.target.value);
      this.selectedFrequency.removeAt(index);
      for (let i = 0; i < this.frequencyList.length; i++) {
        if (this.frequencyList[i].name.toLowerCase() == e.target.value.toLowerCase()) {
          this.frequencyList[i].isSelected = false;
        }
      }
    }
  }

  addMedicine() {
    let a = this.myForm.value.medicineName;
    let b = this.myForm.value.frequency == null ? '' : this.myForm.value.frequency;

    let arr = this.selectedFrequency.controls;
    for (let i = 0; i < arr.length; i++) {
      b = b + arr[i].value + ', ';
    }
    b = b.replace(/,\s*$/, ""); // removes last comma

    let c = this.myForm.value.noOfDays;
    let d = this.myForm.value.quantity;
    let e = this.myForm.value.instructions;
    let f = this.myForm.value.customText;

    this.medicineList.push(this.patchValues(a, b, c, d, e, f));

    // let selectedCheckboxes = document.getElementsByName('frequencyCheckbox');
    for (let i = 0; i < this.frequencyList.length; i++) {
      this.frequencyList[i].isSelected = false;
    }

    this.myForm.controls.medicineName.setValue(null);
    this.myForm.controls.frequency.setValue('');
    this.myForm.controls.noOfDays.setValue('');
    this.myForm.controls.quantity.setValue('');
    this.myForm.controls.instructions.setValue('');
    this.myForm.controls.customText.setValue('');
    this.selectedFrequency.controls = [];
    //this.myForm.controls.selectedFrequency.setValue('');
  }
  patchValues(name: any, frequency: any, days: any, measure: any, instructions: any, customText: any) {
    return this.fb.group({
      medicineName: [name],
      frequency: [frequency],
      noOfDays: [days],
      quantity: [measure],
      instructions: [instructions],
      customText: [customText]
    });
  }
  removeMedicine(i: number) {
    this.medicineList.removeAt(i);
  }

  addRemarks(event: any) {
    var remark = event.target.value;
    if (remark == "") {
      remark = this.myForm.controls.remark.value;
    }
    if (remark != null && remark != "") {
      this.remarksList.push(this.createItem(remark));
      this.myForm.controls.remark.setValue("");
    }
    event.target.value = "";
  }
  removeRemarks(i: number) {
    this.remarksList.removeAt(i);
  }

  searchAdvices() {
    if (this.searchAdvice == "") {
      if (this.advicesList.length > 0) {
        this.advicesList.length = 0;
      }
    } else {
      this.advicesList = this.searchAdvicesList.filter((data: any) => {
        var name = data.Title;
        return name.toString().toLowerCase().match(this.searchAdvice.toLowerCase());
      })
    }
  }

  getAdviceGroupList() {
    var doctorId = this.myForm.controls.doctorid.value;
    this.service.getTitleByGroupId(doctorId, this.currentUser.User.HospitalId, "1").subscribe((data: any) => {
      var result = data;
      this.searchAdvicesList = result;
    },
      (error) => {
        alert(error);
      });
  }

  onAdviceGroupSelect(event: any) {
    this.getAdvicesList(event.Id);
    this.searchAdvice = "";
    if (this.advicesList.length > 0) {
      this.advicesList.length = 0;
    }
  }

  getAdvicesList(id: any) {
    this.service.getAdvicesList(id).subscribe((data: any) => {
      var result = data;
      if (data != null) {
        data.forEach((t: any) => {
          this.remarksList.push(this.addItem(t.Id, t.Name));
        });
      }
    },
      (error) => {
        alert(error);
      });
  }

  searchMedicines() {
    if (this.searchMedicineGroup == "") {
      if (this.medicineGroupList.length > 0) {
        this.medicineGroupList.length = 0;
      }
    } else {
      this.medicineGroupList = this.searchMedicineGroupList.filter((data: any) => {
        var name = data.Title;
        return name.toString().toLowerCase().match(this.searchMedicineGroup.toLowerCase());
      })
    }
  }

  getMedicineGroupList() {
    var doctorId = this.myForm.controls.doctorid.value;
    this.service.getTitleByGroupId(doctorId, this.currentUser.User.HospitalId, "2").subscribe((data: any) => {
      var result = data;
      this.searchMedicineGroupList = result;
    },
      (error) => {
        alert(error);
      });
  }

  onMedicineGroupSelect(event: any) {
    this.getMedicinesList(event.Id);
    this.searchMedicineGroup = "";
    if (this.medicineGroupList.length > 0) {
      this.medicineGroupList.length = 0;
    }
  }

  getMedicinesList(id: any) {
    this.service.getMedicinesList(id).subscribe((data: any) => {
      var result = data;
      if (data != null) {
        data.forEach((t: any) => {
          this.medicineList.push(this.addPatchValues(t.Id, t.MedicineName, t.Frequency,
            t.NoOfDays, t.Quantity, t.Instructions, t.CustomText));
        });
      }
    },
      (error) => {
        alert(error);
      });
  }

  searchComplaint(){
    var search = this.myForm.controls.chiefcomplaint.value;
    if (search == "") {
      if (this.complaintMasterList.length > 0) {
        this.complaintMasterList.length = 0;
      }
    } else {
      this.complaintMasterList = this.searchComplaintList.filter((data: any) => {
        var name = data.Name;
        return name.toString().toLowerCase().match(search.toLowerCase());
      })
    }
  }
  getComplaintList() {
    this.service.getMastersList(this.myForm.controls.doctorid.value,this.currentUser.User.HospitalId,"GetComplaints").subscribe((data: any) => {
      var result = data;
      this.searchComplaintList = result;
    },
      (error) => {
        alert(error);
      });
  }
  onComplaintSelect(event: any) {
    this.complaintsList.push(this.createItem(event));
    this.myForm.controls.chiefcomplaint.setValue("");
    if (this.complaintMasterList.length > 0) {
      this.complaintMasterList.length = 0;
    }
  }


  searchExamination(){
    var search = this.myForm.controls.clinicalexamination.value;
    if (search == "") {
      if (this.examinationMasterList.length > 0) {
        this.examinationMasterList.length = 0;
      }
    } else {
      this.examinationMasterList = this.searchExaminationsList.filter((data: any) => {
        var name = data.Name;
        return name.toString().toLowerCase().match(search.toLowerCase());
      })
    }
  }
  getExaminationList() {
    this.service.getMastersList(this.myForm.controls.doctorid.value,this.currentUser.User.HospitalId,"GetExaminations").subscribe((data: any) => {
      var result = data;
      this.searchExaminationsList = result;
    },
      (error) => {
        alert(error);
      });
  }
  onExaminationSelect(event: any) {
    this.clinicalExaminationList.push(this.createItem(event));
    this.myForm.controls.clinicalexamination.setValue("");
    if (this.examinationMasterList.length > 0) {
      this.examinationMasterList.length = 0;
    }
  }

  searchDiagnosis(){
    var search = this.myForm.controls.diagnosis.value;
    if (search == "") {
      if (this.diagnosisMasterList.length > 0) {
        this.diagnosisMasterList.length = 0;
      }
    } else {
      this.diagnosisMasterList = this.searchDiagnosisList.filter((data: any) => {
        var name = data.Name;
        return name.toString().toLowerCase().match(search.toLowerCase());
      })
    }
  }
  getDiagnosisList() {
    var doctorId = this.myForm.controls.doctorid.value;
    this.service.getMastersList(this.myForm.controls.doctorid.value,this.currentUser.User.HospitalId,"GetDiagnosis").subscribe((data: any) => {
      var result = data;
      this.searchDiagnosisList = result;
    },
      (error) => {
        alert(error);
      });
  }
  onDiagnosisSelect(event: any) {
    this.diagnosisList.push(this.createItem(event));
    this.myForm.controls.diagnosis.setValue("");
    if (this.diagnosisMasterList.length > 0) {
      this.diagnosisMasterList.length = 0;
    }
  }

  addPescription() {
    this.submitted = true;
    if (this.myForm.value.patientid == "") {
      alert('Patient details not available');
    }
    if (this.myForm.invalid) {
      return;
    }
    if (this.myForm.controls.followUpDate.value != null && this.myForm.controls.followUpDate.value != '' && (this.myForm.controls.followUpTime.value == '' || this.myForm.controls.followUpTime.value == null)) {
      alert('Please enter FollowUp time');
      return;
    }
    else if (this.myForm.controls.followUpTime.value != '' && this.myForm.controls.followUpTime.value != null && this.myForm.controls.followUpDate.value == '') {
      alert('Please enter FollowUp date');
      return;
    }
    else if (this.myForm.controls.followUpDate.value != null && this.myForm.controls.followUpDate.value != '' && this.myForm.controls.followUpTime.value != '' && this.myForm.controls.followUpTime.value != null) {
      var fTime = this.myForm.value.followUpTime.hour + ':' + this.myForm.value.followUpTime.minute;
      this.myForm.controls.followUpDate.setValue(this.myForm.controls.followUpDate.value + " " + fTime);
    }

    if (this.myForm.controls.referDate.value != '' && this.myForm.controls.referDate.value != null && (this.myForm.controls.referTime.value != '' && this.myForm.controls.referTime.value != null)) {
      var rTime = this.myForm.value.referTime.hour + ':' + this.myForm.value.referTime.minute;
      this.myForm.controls.referDate.setValue(this.myForm.controls.referDate.value + " " + rTime);
    }
    this.myForm.controls.PrescptionDate.setValue(this.myForm.controls.PrescptionDate.value + " " + this.myForm.controls.PrescptionTime.value.hour.toString() + ":" + this.myForm.controls.PrescptionTime.value.minute.toString() + ":" + "00");
    var stringJson = this.myForm.value;
    delete stringJson.chiefcomplaint;
    delete stringJson.pasthistory;
    delete stringJson.clinicalexamination;
    delete stringJson.investigation;
    delete stringJson.diagnosis;
    delete stringJson.remark;
    delete stringJson.selectedFrequency;
    if (this.editPrescriptionId != undefined) {
      stringJson.PrescptionId = this.editPrescriptionId;
      if (stringJson.Anthropometry.id == null) {
        delete stringJson.Anthropometry;
      }
      if (stringJson.Vitals.id == null) {
        delete stringJson.Vitals;
      }
      this.loading = true;
      this.service.updatePrescription(stringJson).subscribe(res => {
        this.loading = false;
        alert("Prescription updated successfully");
        this.router.navigateByUrl('header/prescription');
      },
        error => {
          console.log(error);
        });
    }
    else {
      if (this.hospitalList != null) {
      this.myForm.controls.hospitalname.setValue(this.hospitalList[0].HospitalName); 
      }
      stringJson = this.myForm.value;
      delete stringJson.Anthropometry.id;
      delete stringJson.Vitals.id;
      delete stringJson.PrescptionId;
      this.loading = true;
      this.service.addPrescription(stringJson).subscribe(res => {
        this.loading = false;
        alert("Prescription added successfully");
        this.router.navigateByUrl('header/prescription');
        // var tDate = (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, -1).slice(0, 10);
      },
        error => {
          console.log(error);
        });
    }

  }
}
