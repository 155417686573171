import { Patient } from './../../patient';
import { Component, OnInit, ViewEncapsulation,Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router,ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl,Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from './../../_services/authentication.service';

@Component({
  selector: 'app-add-appointment',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './add-appointment.component.html',
  styleUrls: ['./add-appointment.component.css']
})
export class AddAppointmentComponent implements OnInit {

  form: FormGroup;
  Doctors: any = [];
  ModeList: any = ['In Clinic', 'Online'];
  Departments: any = []; // Department Names
  meridian = true;
  PatientList: any = [];
  SearchPatientList: any = [];
  patientName:any;
  currentUser:any;
  submitted = false;

  constructor(
    private datePipe: DatePipe,
    public service: SharedService,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.createForm();
  }

  ngOnInit(): void {
    if (JSON.stringify(this.currentUser) == '{}' && localStorage.getItem('currentUser') == undefined) {
      this.router.navigateByUrl('login');      
    }
    else{
    this.loadDoctorList();
    this.loadPatientList();
    this.loadDepartmentsList();
    this.route.queryParams.subscribe(
      params => {
        this.patientName = params['patientName'];
      });
      if(this.patientName != undefined){
        this.form.controls.patientname.setValue(this.patientName);
      }
    }

    let hours: any;
    let minutes:any;
    let now = this.datePipe.transform(new Date(), "HH:mm a");
        hours = now?.split(':')[0];
        minutes = now?.split(':')[1];
      this.form.controls.Time.setValue({ hour: parseInt(hours), minute: parseInt(minutes) });
  }
  private createForm() {
    this.form = new FormGroup({
      clinicname: new FormControl(this.currentUser.User.Hospital.HospitalName,Validators.required),
      patientid: new FormControl(''),
      patientname: new FormControl('', Validators.required),
      departmentname: new FormControl(''),
      // doctorname: new FormControl('', Validators.required),
      Date: new FormControl((new Date()).toISOString().substring(0, 10),Validators.required),
      Time: new FormControl('', Validators.required),
      status: new FormControl('Pending',Validators.required),
      purposeofvisit: new FormControl(''),
      mode: new FormControl('In Clinic',Validators.required),
      doctorid: new FormControl(''),
      HospitalId : new FormControl(this.currentUser.User.HospitalId,Validators.required)
    })
  }

  // checkDate(value:any) {
  //   const dateSendingToServer = new DatePipe('en-US').transform(value , 'dd/MM/yyyy')
  //   console.log(dateSendingToServer);
  // }

  loadDoctorList() {
    this.service.getDoctorsList(this.currentUser.User.HospitalId).subscribe(data => {
      this.Doctors = data;
    });
  }

  loadPatientList() {
    this.service.getPatientsList(this.currentUser.User.HospitalId).subscribe(data => {
      data.forEach(element => {
        if (element.LastName == null) {
          element.LastName = "";
        }
      });
      this.SearchPatientList = data;
    });
  }

  loadDepartmentsList() {
    this.service.getDepartmentsList(this.currentUser.User.HospitalId).subscribe(data => {
      this.Departments = data;
    });
  }

  onSelect(e: any) {
    this.form.controls.patientname.setValue(e.FirstName + ' ' + e.LastName, {
      onlySelf: true
    });
    this.form.controls.patientid.setValue(e.PatientId, {
      onlySelf: true
    });
    this.PatientList.length=0;
  }
  Search() {
    if (this.form.controls.patientname.value == "") {
      if (this.PatientList.length > 0) {
        this.PatientList.length = 0;
      }
    } else {
      this.PatientList = this.SearchPatientList.filter((data: any) => {
        return data.FirstName.toString().toLowerCase().match(this.form.controls.patientname.value.toLowerCase());
      });
    }
  }
  
  // Choose department using select dropdown
  changeDepartment(e: any) {
    this.form.controls.departmentname.setValue(e.target.value, {
      onlySelf: true
    });
  }

  getDepartmentId(id:any){
    var doctorObj = this.Doctors.filter((data: any) => {
      return data.DoctorId.toString().toLowerCase().match(id);
    });

    if(doctorObj != null){
      var departmentObj = this.Departments.filter((data: any) => {
       return data.DepartmentId.toString().toLowerCase().match(doctorObj[0].Speciality.toString().toLowerCase());
      });
      if(departmentObj !=null){
       this.form.controls.departmentname.setValue(departmentObj[0].DepartmentName);
      }
    }
  }

  selectDoctorName(e: any) {
    this.getDepartmentId(this.form.controls.doctorid.value);
  }

  // Choose hospital using select dropdown
  selectMode(e: any) {
    this.form.controls.mode.setValue(e.target.value, {
      onlySelf: true
    })
  }

  // Getter method to access formcontrols
  get departmentname() {
    return this.form.get('departmentname');
  }
  get clinicname() {
    return this.form.get('clinicname');
  }
  get f() {
    return this.form.controls;
  }

  submit() {    
    if (this.form.controls.doctorid.value == "") {
     alert("Doctor name is mandatory !. Please select doctor");
     return;
    }
    if (this.form.controls.departmentname.value == "") {
      alert("Department name not provided");
      return;
    }
    if (this.form.controls.Time.value == "") {
      this.form.controls.Time.setValue(new Date().getHours() + ":" + new Date().getMinutes());
    }
    else if(this.form.controls.Time.value.hour != undefined) {
      var time = this.form.controls.Time.value.hour + ":" + this.form.controls.Time.value.minute;
      this.form.controls.Time.setValue(time);
    }
    this.form.value.Date = this.form.value.Date + ' ' + this.form.value.Time;
    
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    
    var stringJson = JSON.stringify(this.form.value);
    this.service.addAppointment(stringJson).subscribe(res => {
      alert("Appointment added successfully");
      this.router.navigateByUrl('header/appointments');
    });
  }
  
}
