<div class="page-wrapper">
  <div class="content">
    <div class="table-responsive">
      <table class="table table-border table-striped custom-table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Date and Time</th>
            <th scope="col">Doctor</th>
            <th scope="col">Purpose Of Visit</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of appointments">
            <td>{{item.AppointmentId}}</td>
            <td scope="row">{{item.Date | date:"dd-MM-yy hh:mm a"}}</td>
            <td>Dr. {{item.DoctorName}}</td>
            <td> {{item.PurposeOfVisit}} </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>