import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'src/app/shared.service';
import { first } from 'rxjs/operators';

import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-login',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  roles: any;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(private fb: FormBuilder, private service: SharedService,private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService) {
       // redirect to dashboard if already logged in
       if (this.authenticationService.currentUserValue && localStorage.getItem('currentUser') != null) 
       {
        this.router.navigate(['header/dashboard']);      
       }
    }

  ngOnInit(): void {
    this.createForm();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  public createForm() {
    this.loginForm = this.fb.group({
      userId:0,
      firstName:[''],
      lastName:[''],
      userName: ['', Validators.required],
      password: ['', Validators.required],
      token:[''],
      roleId:0,
    });
  }


  login(){
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    this.loading = true;
    this.authenticationService.login(this.loginForm.value).subscribe(data => {
      this.loading = false;
      this.authenticationService.setUserLoggedIn(true);
      this.router.navigateByUrl('header/dashboard');
    },
      error => {
        this.error = error;
        this.loading = false;
      });
  }

  forgotPassword(){
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    this.loading = true;
    this.authenticationService.forgotPassword(this.loginForm.value).subscribe(data => {
      this.loading = false;
      alert(data.Message);
      this.router.navigateByUrl('login');
      //this.router.navigate(['/login']);
    },
      error => {
        this.error = "Please pass the valid Username";
        this.loading = false;
      });
    //alert("New password updated !")
  }
  get f() {
    return this.loginForm.controls;
  }
}
