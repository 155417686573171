<div class="page-wrapper">
  <div class="content">
    <div>
      <button type="submit" class="btn btn-primary btn-default" style="float: right;">Print Patient History</button>
      <button type="submit" class="btn btn-primary btn-default mr-1" style="float: right;"
        [routerLink]="['../patientdetails']" [queryParams]="{patientId:editPatientID}">Edit details</button>
      <br /><br />

      <div class="container">
        <div class="row">
          <div class="font-weight-bold col-2">Mobile no:</div>
          <div class="col-sm-2">{{phoneNumber}}</div>
        </div>
        <div class="row">
          <div class="font-weight-bold col-2">Email Id:</div>
          <div class="col-sm-2"><a href="#">{{email}}</a></div>
        </div>
        <div class="row">
          <div class="font-weight-bold col-2">Bloog Group:</div>
          <div class="col-sm-2">{{bloodgroup}}</div>
        </div>
        <div class="row">
          <div class="font-weight-bold col-2">Address:</div>
          <div class="col-sm-6">{{address}}</div>
        </div>
        <div class="row">
          <label class="font-weight-bold col-2">Emergency contactNo:</label>
          <label class="col-sm-2">{{phoneNumber}}</label>
        </div>
        <div class="row" hidden>
          <div class="font-weight-bold col-2">Clinics:</div>
          <ol>
            <li>{{hospitalName}}</li>
          </ol>
        </div>
        <div class="row mt-4">
          <div class="font-weight-bold col-2">Allergies:</div>
        </div>
        <div class="row">
          <ul>
            <li style="color: red;">No known allergies</li>
          </ul>
        </div>
        <div class="row">
          <div class="font-weight-bold col-2">Birth History:</div>
        </div>
        <div>
          <ul>
            <li>
              <label class="font-weight-bold col-sm-2">Date of Birth:</label><label class="col-sm-2">11/08/2017</label>
              <label class="font-weight-bold">Time of Birth:</label><label class="col-sm-2">07:03 AM</label>
            </li>
            <li>
              <label class="font-weight-bold col-sm-2">Mode of Delivery:</label><label class="col-sm-6">Normal Vaginal
                delivery</label>
            </li>
            <li>
              <label class="font-weight-bold col-sm-2">Term/Preterm:</label><label class="col-sm-2">Term</label>
            </li>
            <li>
              <label class="font-weight-bold col-sm-2">Birth Weight:</label><label class="col-sm-2">3.29kg</label>
            </li>
            <li>
              <div class="col-sm-3"><span class="font-weight-bold">Admission at birth:</span><span class="col-sm-2"
                  style="margin-left: 1rem;">No</span></div>
            </li>
            <li>
              <label class="font-weight-bold col-sm-2">Other Details:</label><label class="col-sm-4">birth history was
                uneventful</label>
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="font-weight-bold col-2">Medical History:</div>
        </div>
        <div class="row">
          <ul>
            <li>No details added</li>
          </ul>
        </div>
        <div class="row">
          <div class="font-weight-bold col-2">Drug History:</div>
        </div>
        <div class="row">
          <ul>
            <li>No details added</li>
          </ul>
        </div>
        <div class="row">
          <div class="font-weight-bold col-2">Surgical History:</div>
        </div>
        <div class="row">
          <ul>
            <li>No details added</li>
          </ul>
        </div>
        <div class="row">
          <div class="font-weight-bold col-2">Family History:</div>
        </div>
        <div class="row">
          <ul>
            <li>No details added</li>
          </ul>
        </div>
        <div class="row">
          <div class="font-weight-bold col-2">Others:</div>
        </div>
        <div class="row">
          <ul>
            <li>No details added</li>
          </ul>
        </div>
      </div>
      <br /><br />
    </div>
  </div>
</div>