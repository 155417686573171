<div class="main-wrapper">
    <div class="s-header">
        <div class="h4 ml-1"> Settings </div>
        <ul class="nav user-menu">
            <li class="nav-item">
                <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle ml-1 mb-1" type="button" id="generalMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        General</button>
                    <div class="dropdown-menu" aria-labelledby="generalMenuButton">
                        <a class="dropdown-item">Profile Settings</a>
                        <a class="dropdown-item">Change Password</a>
                    </div>
                </div>
            </li>
            <li class="nav-item">
                <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle ml-1" type="button" id="masterMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Admin Settings</button>
                    <div class="dropdown-menu" aria-labelledby="masterMenuButton">
                        <a class="dropdown-item" >Clinic Preferences</a>
                        <a class="dropdown-item" >Print Settings</a>
                        <a class="dropdown-item" >Export Data</a>
                        <a class="dropdown-item" [routerLink]="['../department']" [queryParams]="{viewDashboard:true}">Departments</a>
                    </div>
                </div>
            </li>
            <li class="nav-item">
                <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle ml-1" type="button" id="usersMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Users</button>
                    <div class="dropdown-menu" aria-labelledby="usersMenuButton">
                        <a class="dropdown-item" [routerLink]="['../doctorprofile']"  [queryParams]="{viewDashboard:true}">Doctor</a>
                        <a class="dropdown-item" [routerLink]="['../staffprofile']"  [queryParams]="{viewDashboard:true}">Staff</a>
                    </div>
                </div>
            </li>
            <li class="nav-item">
                <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle ml-1" type="button" id="serviceMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Services</button>
                        <div class="dropdown-menu" aria-labelledby="usersMenuButton">
                            <a class="dropdown-item" [routerLink]="['../clinicServices']"  [queryParams]="{viewDashboard:true}">Clinic</a>
                            <a class="dropdown-item d-none" >Lab</a>
                        </div>
                </div>
            </li>

            <li class="nav-item">
                <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle ml-1" type="button" id="serviceMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="this.currentUser.User.RoleId == 2">
                        Prescription Master</button>
                        <div class="dropdown-menu" aria-labelledby="usersMenuButton">
                            <a class="dropdown-item" [routerLink]="['../complaint']" [queryParams]="{viewDashboard:true,viewComplaint:true}">Complaint master</a>
                            <a class="dropdown-item" [routerLink]="['../complaint']" [queryParams]="{viewDashboard:true,viewExamination:true}">Examination master</a>
                            <a class="dropdown-item" [routerLink]="['../complaint']" [queryParams]="{viewDashboard:true,viewDiagnosis:true}">Diagnosis master</a>
                            <a class="dropdown-item" [routerLink]="['../titleDashboard']" [queryParams]="{viewDashboard:true,viewAdviceTitles:true, id:'1'}">Advice master</a>
                            <a class="dropdown-item" [routerLink]="['../titleDashboard']" [queryParams]="{viewDashboard:true,viewMedicineTitles:true, id:'2'}">Medicine master</a>
                        </div>
                </div>
            </li>

        </ul>
    </div>
    <div class="page-wrapper d-none">
        <div>
            <div class="setting-wrapper">
                <div class="dash-widget">
                    <div class="table-responsive">
                        <table class="table table-border table-striped custom-table mb-2">
                            <thead>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Qualification</th>
                                <th>Specialization</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Sandeep</td>
                                    <td>MBBS</td>
                                    <td>Orthopedic</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>