import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../../shared.service';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-title-advices',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './title-advices.component.html',
  styleUrls: ['./title-advices.component.css']
})
export class TitleAdvicesComponent implements OnInit {

  form: FormGroup;
  viewDashboard: boolean = true;
  currentUser: any;
  itemsList: any = [];
  searchName: any;
  submitted: any;
  submitText: any;
  editTitle: boolean = false;
  doctorId: any;
  titleHeading: any = "Heading";
  addTitle: any;
  viewAdviceTitles: boolean = false;
  viewMedicineTitles: boolean = false;
  SearchItemList: any = [];
  titleId: any;

  page: number = 1;
  pageSize = 5;

  constructor(
    public service: SharedService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser.User.RoleId == 2) {
      this.getDoctorDetails();
    }
    this.createForm();
  }

  ngOnInit(): void {
    if (JSON.stringify(this.currentUser) == '{}' && localStorage.getItem('currentUser') == undefined) {
      this.router.navigateByUrl('login');
    }
    else {
      this.route.queryParams.subscribe(
        params => {
          if (params['viewDashboard'] != undefined && params['viewDashboard'] == 'true') {
            this.viewDashboard = true;
            this.addTitle = "Add Advices";
            this.titleId = params['titleId'];
            this.titleHeading = params['title'];
            
            if (this.doctorId != undefined && this.titleId) {
              this.loadAdvisesList();
            }
          }
        });
    }
  }

  createForm() {
    this.submitText = 'SAVE';
    this.viewDashboard = false;
    this.form = this.fb.group({
      id: new FormControl(''),
      name: new FormControl('', Validators.required),
      doctorId: new FormControl(this.doctorId),
      hospitalId: new FormControl(this.currentUser.User.HospitalId),
      masterTitleId: new FormControl(parseInt(this.titleId))
    });
  }

  loadAdvisesList() {
    this.service.getAdvicesList(this.titleId).subscribe((data: any) => {
      var result = data;
      this.itemsList = result;
      this.SearchItemList = result;
      this.viewDashboard = true;
    },
      (error) => {
        alert(error);
      });
  }

  getItem(id: any) {
    this.form.reset();
    this.searchName = "";
    this.form.controls.id.setValue(id);
    this.form.controls.doctorId.setValue(this.doctorId);
    this.form.controls.hospitalId.setValue(this.currentUser.User.HospitalId);
    
    this.service.getAdvice(id, this.titleId).subscribe((data: any) => {
      if (data != null) {
        var res = data;
        this.editTitle = true;
        this.submitText = 'UPDATE';
        this.viewDashboard = false;
        this.form.controls.name.setValue(data.Name);
        this.form.controls.masterTitleId.setValue(data.MasterTitleId);
      }
    },
      (error) => {
        alert(error);
      });
  }

  deleteItem(id: any) {
    this.searchName = "";
    this.service.deleteAdvice(id, parseInt(this.titleId)).subscribe((data: any) => {
      var result = data;
      alert("Deleted successfully");
      this.loadAdvisesList();
    });
  }

  searchItem() {
    if (this.searchName == "") {
      this.ngOnInit();
    } else {
      this.itemsList = this.SearchItemList.filter((data: any) => {
        var name = data.Name;
        return name.toString().toLowerCase().match(this.searchName.toLowerCase());
      })
    }
  }

  getDoctorDetails() {
    this.service.getDoctorDetailsById(this.currentUser.User.UserId, this.currentUser.User.HospitalId).subscribe((data: any) => {
      if (data != null) {
        this.doctorId = data.DoctorId;
        this.loadAdvisesList();
      }
    },
      (error) => {
        alert(error);
      });
  }

  submit(){

    if (this.form.value.id == null || this.form.value.id == '') {
      delete this.form.value.id;

      this.service.addAdvice(this.form.value).subscribe((data: any) => {
        alert("Added successfully");
        this.loadAdvisesList();
      },
        (error) => {
          alert(error);
        });
    }
    else {
      this.service.updateAdvice(this.form.value).subscribe((data: any) => {
        alert("Updated successfully");
        this.loadAdvisesList();
      },
        (error) => {
          alert(error);
        });
    }

  }

  get f() {
    return this.form.controls;
  }

}
