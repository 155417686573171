<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="dash-widget" style="margin: 0px 96px 5px 39px;">
            <button type="button" class="close" aria-label="Close" *ngIf="viewDashboard != true" (click)="getListOfStaff()">
                <span aria-hidden="true">×</span>
            </button>
            <div class="row">
                <div>
                    <h4 class="page-title">Profile</h4>
                </div>
            </div>
            <div class="row float-right mr-1">
                <button type="button" class="btn btn-primary" [ngClass]="viewDashboard == true ?'d-block':'d-none'"
                *ngIf="this.currentUser.User.RoleId == 1" (click)="createForm()">Add Staff</button>
            </div>
            <form [formGroup]="form" (ngSubmit)="submit()" [ngClass]="viewDashboard == true ?'d-none':'d-block'">
                <div class="form-row">
                    <div class="form-group col-6">
                        <label>First Name <span class="text-danger">*</span></label>
                        <input formControlName="firstname" class="form-control" type="text" />
                        <div class="text-danger" *ngIf="(f.firstname.touched || submitted) && f.firstname.errors?.required">First Name is required</div>
                    </div>
                    <div class="form-group col-6">
                        <label>Last Name <span class="text-danger">*</span></label>
                        <input formControlName="lastname" class="form-control" type="text" />
                        <div class="text-danger" *ngIf="(f.lastname.touched || submitted) && f.lastname.errors?.required">Last Name is required</div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-6">
                        <label>Email <span class="text-danger">*</span></label>
                        <input formControlName="email" class="form-control" type="email" />
                        <div class="text-danger" *ngIf="(f.email.touched || submitted) && f.email.errors?.required">Email is required</div>
                        <div *ngIf="f.email.errors?.pattern" class="text-danger">Please provide a valid email address</div>
                    </div>
                    <div class="form-group col-6">
                        <label>Mobile Number <span class="text-danger">*</span></label>
                        <input formControlName="phonenumber" class="form-control" type="text" />
                        <div class="text-danger" *ngIf="(f.phonenumber.touched || submitted) && f.phonenumber.errors?.required">Mobile Number is required</div>
                    </div>
                </div>
                <div class="form-row">                    
                    <div class="form-group col-4">
                        <label>Gender :  <span class="text-danger">*</span></label>
                        <div>
                            <div class="form-check-inline">
                                <div class="col-4">
                                    <input class="form-check-input" id="male" type="radio" value="Male" name="gender"
                                        formControlName="gender">
                                    <label class="form-check-label" for="male">Male</label>
                                </div>
                                <div class="col-5">
                                    <input class="form-check-input" id="female" type="radio" value="Female" name="gender"
                                        formControlName="gender">
                                    <label class="form-check-label" for="female">Female</label>
                                </div>
                                <div class="col-5">
                                    <input class="form-check-input" id="others" type="radio" value="Others" name="gender"
                                        formControlName="gender">
                                    <label class="form-check-label" for="others">Others</label>
                                </div>
                            </div>
                            <div class="text-danger" *ngIf="(f.gender.touched || submitted) && f.gender.errors?.required">Gender is required</div>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label>DOB <span class="text-danger">*</span></label>
                        <input formControlName="dob" class="form-control" type="date" />
                        <div class="text-danger" *ngIf="(f.dob.touched || submitted) && f.dob.errors?.required">Date of birth is required</div>
                    </div>
                    <div class="form-group col-4">
                        <label>Age</label>
                        <input formControlName="age" class="form-control" type="text" readonly/>
                    </div>                  
                </div>                
                <div class="form-row">
                    <div class="form-group col-6">
                        <label>Address <span class="text-danger">*</span></label>
                        <input formControlName="address" class="form-control" type="text" />
                        <div class="text-danger" *ngIf="(f.address.touched || submitted) && f.address.errors?.required">Address is required</div>
                    </div>
                    <div class="form-group col-6">
                        <label>City <span class="text-danger">*</span></label>
                        <input formControlName="city" class="form-control" type="text" />
                        <div class="text-danger" *ngIf="(f.city.touched || submitted) && f.city.errors?.required">City is required</div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-6">
                        <label>State <span class="text-danger">*</span></label>
                        <input formControlName="state" class="form-control" type="text" />
                        <div class="text-danger" *ngIf="(f.state.touched || submitted) && f.state.errors?.required">State is required</div>
                    </div>
                    <div class="form-group col-6">
                        <label>Postal Code <span class="text-danger">*</span></label>
                        <input formControlName="postalcode" class="form-control" type="number" />
                        <div class="text-danger" *ngIf="(f.postalcode.touched || submitted) && f.postalcode.errors?.required">Postal Code is required</div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-6">
                        <label>Profile Image</label>
                        <input formControlName="profileImagePath" class="form-control" type="text" />
                    </div>
                    <div class="form-group col-6">
                        <label>Document Id</label>
                        <input formControlName="DocumentIdPath" class="form-control" type="text" />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-6">
                        <label>RegistrationNumber</label>
                        <input formControlName="registrationNumber" class="form-control" type="text" />
                    </div>
                    <div class="form-group col-6">
                        <label>Designation <span class="text-danger">*</span></label>
                        <input formControlName="designation" class="form-control" type="text" />
                        <div class="text-danger" *ngIf="(f.designation.touched || submitted) && f.designation.errors?.required">Designation is required</div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-4">
                        <label>User Name <span class="text-danger">*</span></label>
                        <input formControlName="username" class="form-control" type="text" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"/>
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required">User name is required</div>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label>Password <span class="text-danger">*</span></label>
                        <input type="password" autofocus="" class="form-control" formControlName="password"
                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                            </div>
                    </div>
                    <div class="form-group col-4">
                        <label>Confirm Password <span class="text-danger">*</span></label>
                            <input type="password" autofocus="" class="form-control" formControlName="confirmPassword"
                                [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
                            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                                <div *ngIf="f.confirmPassword.errors.mustMatch">Password & ConfirmPassword must match</div>
                            </div>
                    </div>
                </div>
                <div class="m-t-20 text-center">
                    <button class="btn btn-primary submit-btn" type="submit">{{submitText}}</button>
                </div>
            </form>
            <!-- Staff Dashboard -->
            <div class="mt-5" [ngClass]="viewDashboard == true ?'d-block':'d-none'">
                <div class="dash-widget">
                    <div class="table-responsive">
                        <table class="table table-border table-striped custom-table mb-2">
                            <thead>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Designation</th>
                                <th *ngIf="this.currentUser.User.RoleId == 1">Action</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of staffList">
                                    <td>{{item.StaffId}}</td>
                                    <td>{{item.UserName}}</td>
                                    <td>{{item.Designation}}</td>
                                    <td *ngIf="this.currentUser.User.RoleId == 1">
                                        <div class="dropdown dropdown-action">
                                            <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown"
                                                aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a class="dropdown-item" (click)="getStaffProfile(item.StaffId)"><i
                                                        class="fa fa-pencil m-r-5"></i> Edit</a>
                                                <a class="dropdown-item" (click)="deleteStaff(item.StaffId)"><i
                                                        class="fa fa-trash-o m-r-5"></i>Delete</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>