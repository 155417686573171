import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-patient',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css']
})
export class PatientComponent implements OnInit {

  constructor(){}

  ngOnInit(): void {
  }

}
