import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../../shared.service';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-title-dashboard',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './title-dashboard.component.html',
  styleUrls: ['./title-dashboard.component.css']
})
export class TitleDashboardComponent implements OnInit {

  form: FormGroup;
  viewDashboard: boolean = true;
  currentUser: any;
  itemsList: any = [];
  searchName: any;
  submitted: any;
  submitText: any;
  editTitle: boolean = false;
  doctorId: any;
  titleHeading: any = "Heading";
  addTitle: any;
  viewAdviceTitles: boolean = false;
  viewMedicineTitles: boolean = false;
  SearchItemList: any = [];
  groupId: any;
  routingItem:any;

  page: number = 1;
  pageSize = 5;

  constructor(
    public service: SharedService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser.User.RoleId == 2) {
      this.getDoctorDetails();
    }
    this.createForm();
  }

  ngOnInit(): void {
    if (JSON.stringify(this.currentUser) == '{}' && localStorage.getItem('currentUser') == undefined) {
      this.router.navigateByUrl('login');
    }
    else {
      this.route.queryParams.subscribe(
        params => {
          if (params['viewDashboard'] != undefined && params['viewDashboard'] == 'true') {
            this.viewDashboard = true;
            this.addTitle = "Add Title";
            this.groupId = params['id'];

            if (params['viewAdviceTitles'] != undefined && params['viewAdviceTitles'] == 'true') {
              this.viewAdviceTitles = true;
              this.viewMedicineTitles = false;
              this.titleHeading = "Advice master";
            }
            else if (params['viewMedicineTitles'] != undefined && params['viewMedicineTitles'] == 'true') {
              this.viewAdviceTitles = false;
              this.viewMedicineTitles = true;
              this.titleHeading = "Medicine master";
            }
            if (this.doctorId != undefined) {
              this.loadTitlesList();
            }
          }
        });

    }
  }

  createForm() {
    this.submitText = 'SAVE';
    this.viewDashboard = false;
    this.form = this.fb.group({
      id: new FormControl(''),
      title: new FormControl('', Validators.required),
      doctorId: new FormControl(''),
      hospitalId: new FormControl(''),
      titleGroupId: new FormControl('')
    });
  }

  getDoctorDetails() {
    this.service.getDoctorDetailsById(this.currentUser.User.UserId, this.currentUser.User.HospitalId).subscribe((data: any) => {
      if (data != null) {
        this.doctorId = data.DoctorId;
        this.loadTitlesList();
      }
    },
      (error) => {
        alert(error);
      });
  }

  loadTitlesList() {
    this.service.getTitleByGroupId(this.doctorId, this.currentUser.User.HospitalId, this.groupId).subscribe((data: any) => {
      if(data != null){
        data.forEach((element:any) => {
          if (this.groupId == 1) {
            element.routingItem = "../advicesDashboard";
          }
          else{
            element.routingItem = "../medicinesDashboard";
          }
        });
      }
      var result = data;
      this.itemsList = result;
      this.SearchItemList = result;
      this.viewDashboard = true;

      if (this.groupId == 1) {
        this.itemsList.routingItem = "['../advicesDashboard]";
      }
      else{
        this.itemsList.routingItem = "['../medicinesDashboard]";
      }
    },
      (error) => {
        alert(error);
      });
  }

  getItem(id: any) {
    this.form.reset();
    this.searchName = "";
    this.form.controls.id.setValue(id);
    this.form.controls.doctorId.setValue(this.doctorId);
    this.form.controls.hospitalId.setValue(this.currentUser.User.HospitalId);
    this.form.controls.titleGroupId.setValue(this.groupId);

    this.service.getTitle(id, this.form.value.doctorId, this.form.value.hospitalId).subscribe((data: any) => {
      if (data != null) {
        var res = data;
        this.editTitle = true;
        this.submitText = 'UPDATE';
        this.viewDashboard = false;
        this.form.controls.title.setValue(data.Title);

      }
    },
      (error) => {
        alert(error);
      });
  }

  deleteItem(id: any) {
    this.searchName = "";
    this.form.controls.doctorId.setValue(this.doctorId);
    this.form.controls.hospitalId.setValue(this.currentUser.User.HospitalId);

    this.service.deleteTitle(id, this.doctorId, this.currentUser.User.HospitalId).subscribe((data: any) => {
      var result = data;
      alert("Deleted successfully");
      this.loadTitlesList();
    });
  }

  searchItem() {
    if (this.searchName == "") {
      this.ngOnInit();
    } else {
      this.itemsList = this.SearchItemList.filter((data: any) => {
        var name = data.Title;
        return name.toString().toLowerCase().match(this.searchName.toLowerCase());
      })
    }
  }

  submit() {
    this.form.controls.doctorId.setValue(this.doctorId);
    this.form.controls.hospitalId.setValue(this.currentUser.User.HospitalId);
    this.form.controls.titleGroupId.setValue(this.groupId);

    if (this.form.value.id == null || this.form.value.id == '') {
      delete this.form.value.id;

      this.service.addTitle(this.form.value).subscribe((data: any) => {
        alert("Added successfully");
        this.loadTitlesList();
      },
        (error) => {
          alert(error);
        });
    }
    else {
      this.service.updateTitle(this.form.value).subscribe((data: any) => {
        alert("Updated successfully");
        this.loadTitlesList();
      },
        (error) => {
          alert(error);
        });
    }

  }

  get f() {
    return this.form.controls;
  }

}
