import { User } from 'src/app/_models/user';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../shared.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from './../_services/authentication.service';
 

@Component({
  selector: 'app-dashboard',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  appointments: any = [];
  pendingAppointmentsList: any = [];
  totalPatients: any = 0;
  totalAppointments: any = 0;
  completedAppointments: any = 0;
  pendingAppointments: any = 0;
  missedAppointments: any = 0;
  currentUser:any;
  displayUserName:any;
  salutation:any;
  wish:any;
  time:any;

  constructor(public service: SharedService,private authenticationService: AuthenticationService,private router: Router, private datepipe: DatePipe) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
    // if (JSON.stringify(this.currentUser) == '{}' && localStorage.getItem('currentUser') == undefined) {
    //   this.router.navigateByUrl('login');      
    // }
    // else{
      var hr = new Date().getHours();
      this.wish = (hr > 0 && hr < 12 ) ? 'Good Morning' : (hr >= 12 && hr <= 16 ) ? 'Good Afternoon' : (hr > 16 && hr <= 23 ) ? 'Good Evening' : 'Good Night';
      this.displayUserName = this.currentUser.User.FirstName + ' ' + this.currentUser.User.LastName;
      this.salutation = this.currentUser.User.RoleId === 2 ? 'Dr' : this.currentUser.User.Gender === 'Male' ? 'Mr' : this.currentUser.User.Gender === 'Female' ? 'Mrs' : 'Mr/Mrs';
      this.dashboardActions();
    //  }
  }

  dashboardActions() {
    this.service.getAppointmetsList(this.currentUser.User.HospitalId).subscribe(data => {
      this.appointments = data;
      if (this.appointments.length > 0) {
        this.totalPatients = this.appointments[0].TotalPatients;
        // this.totalAppointments = this.appointments.length;
        //this.completedAppointments = this.appointments[0].CompletedAppointments;
        //this.pendingAppointments = this.totalAppointments - this.completedAppointments;

        var todayDate = new Date().toISOString().slice(0, 10);
        for (var item of this.appointments) {
          item.readOnly = this.currentUser.User.UserId == item.UserId ? false : true;
          item.Patient.DateOfBirth = this.service.ageCalculator(item.Patient.DateOfBirth);
          if (todayDate == item.Date.slice(0, 10)) {
            this.totalAppointments = this.totalAppointments + 1;
            if (item.Status.toLowerCase() == 'completed') {
              this.completedAppointments = this.completedAppointments + 1;
            }
            if (item.Status.toLowerCase() == 'pending') {
              // if(this.displayUserName === item.DoctorName){
              //   item.readOnly = true;
              // }
              this.pendingAppointmentsList.push(item);
              this.pendingAppointments = this.pendingAppointments + 1;
            }
            if (item.Status.toLowerCase() == 'missed') {
              this.missedAppointments = this.missedAppointments + 1;
            }
          }
        }
      }
    });
  }
}
