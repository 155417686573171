import { first } from 'rxjs/operators';
import { Patient } from './../../patient';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SharedService } from '../../shared.service';
import { ActivatedRoute, Router, ActivatedRouteSnapshot } from '@angular/router';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-print-prescription',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './print-prescription.component.html',
  styleUrls: ['./print-prescription.component.css']
})
export class PrintPrescriptionComponent implements OnInit {

  currentUser: any;
  prescriptionId: any = 10;
  todayDate: any = new Date();
  hospitalName: any;
  hospitalAddress: any;
  hospitalEmail: any;
  hospitalPhonenumber: any;
  patientDetails: any;
  uhidNo: any;
  patientContactNo: any;
  doctorName: any;
  doctorQualification:any;
  doctorRegistrationNumber:any;

  prescriptionDetails: any;
  prescptionDate: any = new Date();

  followUpDate: any;
  followUpNote: any;
  referralDate: any = new Date();
  referralNote: any;
  referralTo: any;

  medicineList: any[];
  anthropometry: any;
  vitals: any;
  complaints: any;
  clinicalExaminations: any;
  pastHistory: any;
  investigations: any;
  diagnosis: any;
  remarks: any;
  fileName:any = "Prescription";
  datePipe: DatePipe = new DatePipe('en-US');


  @ViewChild('htmlData') htmlData!: ElementRef;
  constructor(private service: SharedService, private route: ActivatedRoute, private router: Router) { this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}') }

  ngOnInit(): void {
    this.hospitalName = this.currentUser.User.Hospital.HospitalName;
    this.hospitalAddress = this.currentUser.User.Hospital.HospitalAddress;
    this.hospitalEmail = this.currentUser.User.Hospital.MailId;
    this.hospitalPhonenumber = this.currentUser.User.Hospital.Phonenumber;

    this.route.queryParams.subscribe(
      params => {
        this.prescriptionId = params['prescriptionId'];
      });
    if (this.prescriptionId != undefined) {
      this.getPrescriptionDetails();
    }
    
  }

  getPrescriptionDetails() {
    this.service.getPrescription(this.prescriptionId, this.currentUser.User.HospitalId).subscribe((data: any) => {
      this.prescriptionDetails = data;
      this.prescptionDate = data.PrescptionDate;
      this.todayDate = this.datePipe.transform(this.todayDate, "MMM d, y h:mm a");
      data.Patient.LastName = data.Patient.LastName == null ? '' : data.Patient.LastName;

      this.patientDetails = data.Patient.FirstName + ' ' + data.Patient.LastName;
      this.patientContactNo = data.Patient.PhoneNumber;
      this.doctorName = data.DoctorName;
      this.doctorQualification = data.DoctorQualification;
      this.doctorRegistrationNumber = data.DoctorRegistrationNumber;
      this.followUpDate = data.FollowUpDate;
      this.followUpNote = data.FollowUpNote;
      this.referralTo = data.ReferDoctor;
      this.referralNote = data.ReferNote;
      this.referralDate = data.ReferDate;
      this.fileName = data.Patient.FirstName+"-"+this.datePipe.transform(this.prescptionDate, "dd-MM-yy")
      if (data.Medicines != null) {
        this.medicineList = data.Medicines;
      }
      if (data.Anthropometry != null) {
        this.anthropometry = data.Anthropometry;
      }

      //Update Vitals values
      if (data.Vitals != null) {
        this.vitals = data.Vitals;
      }

      if (data.Complaints != null) {
        this.complaints = data.Complaints;
      }
      if (data.PastHistories != null) {
        this.pastHistory = data.PastHistories;
      }
      if (data.ClinicalExaminations != null) {
        this.clinicalExaminations = data.ClinicalExaminations;
      }
      if (data.Investigations != null) {
        this.investigations = data.Investigations;
      }
      if (data.DiagnosisList != null) {
        this.diagnosis = data.DiagnosisList;
      }
      if (data.Remarks != null) {
        this.remarks = data.Remarks;
      }

    });
  }

  public openPdf(): void {
    let DATA: any = document.getElementById('htmlData');
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 200;
      let fileHeight = ((canvas.height * fileWidth) / canvas.width);
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 4;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      addFooters(PDF);
      //PDF.rect(10, 5, 190, 280);
      PDF.save(this.fileName+'.pdf'); // This is a key for direct downloading
      //PDF.output('dataurlnewwindow'); //This is a key for downloading and printing
      //PDF.autoPrint({variant: 'non-conform'}); //This is a key for direct printing   
    });
  
    const addFooters = (doc:any) => {
      const pageCount = doc.internal.getNumberOfPages()    
      doc.setFont('helvetica', 'italic')
      doc.setFontSize(8)
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.line(5, 280, 200, 280);
        doc.setLineWidth(0.5);
        doc.text('Print Date : ' + this.todayDate , doc.internal.pageSize.width / 14, 290, {
          align: 'left'
        });
        doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width / 2, 290, {
          align: 'center'
        });
        doc.text('clinics.ainetwork.in', doc.internal.pageSize.width/1.1, 290, {
          align: 'right'
        })
      }
    }

    this.router.navigate(['header/prescription']);
  }
  
}
