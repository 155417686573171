import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lab-reports',
  templateUrl: './lab-reports.component.html',
  styleUrls: ['./lab-reports.component.css']
})
export class LabReportsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
