import { Component, OnInit, ViewEncapsulation,ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SharedService } from 'src/app/shared.service';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { $ } from 'protractor';


@Component({
  selector: 'app-bill',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.css']
})
export class BillComponent implements OnInit {
 
  billsList: any;
  page: number = 1;
  pageSize = 5;
  currentUser:any;
  isPrint:boolean = true;
  SearchPatientList: any = [];
  patientName:any;
  
  @ViewChild('htmlData') htmlData!: ElementRef;
  constructor(private service: SharedService, private datePipe: DatePipe, private router: Router, private route: ActivatedRoute) 
  {this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}') }

  ngOnInit(): void {
    if (JSON.stringify(this.currentUser) == '{}' && localStorage.getItem('currentUser') == undefined) {
      this.router.navigateByUrl('login');      
    }
    else{    
    this.getBillsList();
    }
  }

  getBillsList() {
    this.service.getBills(this.currentUser.User.HospitalId)
      .subscribe(
        (data) => {
          if (data != null) {            
            data.forEach((t: any) => {
              var discountAmount = t.TotalAmount * (t.DiscountPercentage / 100);
              var finalAmount = t.TotalAmount - discountAmount;
              t.TotalAmount = finalAmount;
            });
            this.billsList = data;
            this.SearchPatientList = data;
          };
        })
  };

  addeditBill(){
      this.router.navigate(['header/addeditbill']);
    }

  deleteBill(item: any) {
      if (confirm('Are you sure??')) {
        this.service.deleteBill(item,this.currentUser.User.HospitalId)
          .subscribe(
            response => {
              alert("Bill details deleted successfully !!");
              window.location.reload();
            },
            error => {
              console.log(error);
            });
      }
  }

  searchPatient() {
    if (this.patientName == "") {
      this.ngOnInit();
    } else {
      this.billsList = this.SearchPatientList.filter((data: any) => {
        var name = data.Patient.FirstName + ' ' + data.Patient.LastName;
        return name.toString().toLowerCase().match(this.patientName.toLowerCase());
      })
      if (this.billsList.length == 0) {
        this.billsList = this.SearchPatientList.filter((data: any) => {
          return data.Patient.PhoneNumber.toString().toLowerCase().match(this.patientName.toLowerCase());
        })
      }
    }
  }
  
  printPage(){
    // window.open();
    // var headstr = "<html><head><title></title></head><body>";
    // var footstr = "</body>";
    // let newstr:any = document.getElementById('htmlData');
    // let winPrint:any = window.open('', '_blank', 'left=0,top=0,width=900,height=700,toolbar=0,scrollbars=0,status=0');
    // winPrint.document.write(headstr + newstr.innerHTML+footstr);
    // winPrint.document.close();
    // winPrint.focus();
    // winPrint.print();
    // winPrint.close();

    this.router.navigate(['header/printbill']);
 
    
  }
}
