<div class="page-wrapper">
  <!-- <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3">
    <div class="container">
      <a class="navbar-brand" [routerLink]="['/']">Clinicia</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse"
        aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded" (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse" [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow">
          <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link text-dark" [routerLink]="['/']">Home</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link text-dark" [routerLink]="['/counter']">Counter</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link text-dark" [routerLink]="['/fetch-data']">Fetch data</a>
          </li>
        </ul>
      </div>
    </div>
  </nav> -->

  <!--<app-nav-menu></app-nav-menu>-->
  <div class="container-md content">
    <h3 class="text-center"> Patient profile</h3>
    <!--<div class="form-row mt-3" style="margin-left: 12rem;">
    <div class="col-md-1"><label type="text"> NAME: </label></div>
    <div class="col-xs-2 col-sm-2"><span type="text">SHREYANVI</span></div>
    <div class="col-md-1 ml-5"><label type="text"> PATIENT ID: </label></div>
    <div class="col-xs-4 col-sm-4"><span type="text">MARVEL 1</span></div>
  </div>
  <div class="form-row mt-3" style="margin-left: 12rem;">
    <div class="col-md-1"><label type="text"> SEX: </label></div>
    <div class="col-xs-2 col-sm-2"><span type="text">FEMALE</span></div>
    <div class="col-md-1 ml-5"><label type="text"> AGE: </label></div>
    <div class="col-xs-4 col-sm-4"><span type="text">3 YEARS 10 MONTHS 6DAYS</span></div>
  </div>-->
    <div class="row">
      <div class="col">
        <label class="font-weight-bold"> Name: </label>
        <label class="ml-2">{{name}}</label>
      </div>
      <div class="col">
        <label class="font-weight-bold"> Patient Id:</label>
        <label class="ml-2">{{patientId}}</label>
      </div>
      <div class="col">
        <label class="font-weight-bold">Age/Sex: </label>
        <label class="ml-2">{{age}} / {{gender}}</label>
      </div>
    </div>
    <div class="mt-2">
      <button type="button" class="btn btn-primary" [routerLink]="['../addappointment']" [queryParams]="{patientName:name}">Add Appointment</button>
      <button type="button" class="btn btn-primary ml-1" [routerLink]="['../addprescription']" [queryParams]="{patientName:name}" *ngIf="currentUser.User.RoleId === 2">Add Prescription</button>
      <button type="button" class="btn btn-primary ml-1">Add Bill</button>
    </div>

    <nav class="navbar-responsive navbar-expand-sm navbar-light bg-white box-shadow mt-4" role="navigation">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar row" id="navbarTogglerDemo01">
        <a class="navbar-brand nav-link" [routerLink]="['patientprofileHome']" [queryParams]="{patientId:editPatientID}"
          routerLinkActive="active-link"><button type="button" class="btn btn-outline-primary">Home</button></a>
        <a class="navbar-brand nav-link" [routerLink]="['patientprofileAppointment']"
          [queryParams]="{patientId:editPatientID}" routerLinkActive="active-link"><button type="button"
            class="btn btn-outline-primary">Appointments</button></a>
        <a class="navbar-brand nav-link" [routerLink]="['patientprofileCommunication']"
          [queryParams]="{patientId:editPatientID}" routerLinkActive="active-link"><button type="button"
            class="btn btn-outline-primary">Prescriptions</button></a>
        <a class="navbar-brand nav-link" [routerLink]="['patientprofileLabreports']"
          [queryParams]="{patientId:editPatientID}" hidden routerLinkActive="active-link"><button type="button"
            class="btn btn-outline-primary">Lab Reports</button></a>
        <a class="navbar-brand nav-link" [routerLink]="['patientprofileImmunization']"
          [queryParams]="{patientId:editPatientID}" routerLinkActive="active-link"><button type="button"
            class="btn btn-outline-primary">Immunization</button></a>
        <a class="navbar-brand nav-link" [routerLink]="['patientprofileImages']"
          [queryParams]="{patientId:editPatientID}" routerLinkActive="active-link"><button type="button"
            class="btn btn-outline-primary">Images</button></a>
        <a class="navbar-brand nav-link" [routerLink]="['patientprofilePayment']"
          [queryParams]="{patientId:editPatientID}" routerLinkActive="active-link"><button type="button"
            class="btn btn-outline-primary">Bills</button></a>

        <!--<a class="navbar-brand" href="#"> Logout</a>-->
      </div>
    </nav>
  </div>
</div>

<router-outlet></router-outlet>