import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedService } from 'src/app/shared.service';
import { Router } from '@angular/router';

//import { ActivatedRoute, Router } from '@angular/router';
//import { PaginatePipe } from 'ngx-pagination';

@Component({
  selector: 'app-show-patient',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './show-patient.component.html',
  styleUrls: ['./show-patient.component.css']
})
export class ShowPatientComponent implements OnInit {
  
  constructor(private service: SharedService,private router: Router) { 
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  }

  FirstName: string;
  page: number = 1;
  pageSize = 6;

  PatientList: any = [];
  SearchPatientList: any = [];
  ModalTitle: string;
  ActivateAddEditPatient: boolean = false;
  dep: any;
  currentItem: any;
  currentUser:any;

  ngOnInit(): void {
    if (JSON.stringify(this.currentUser) == '{}' && localStorage.getItem('currentUser') == undefined){
      this.router.navigateByUrl('login');
    }
    else{
      this.refreshPatientList();
    }
  }

  Search() {
    if (this.FirstName == "") {
      this.ngOnInit();
    } else {
      this.PatientList = this.SearchPatientList.filter((data: any) => {
        var name = data.FirstName + ' ' + data.LastName;
        return name.toString().toLowerCase().match(this.FirstName.toLowerCase());
      });
      if (this.PatientList.length == 0) {
        this.PatientList = this.SearchPatientList.filter((data: any) => {
          return data.PhoneNumber.toString().toLowerCase().match(this.FirstName.toLowerCase());
        })
      }
    }
  }

  key: string = "ParentId";
  reverse: boolean = false;
  sort(key: any) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  addPatient() {
    this.dep = {
      PatientId: 0,
      FirstName: "",
      LastName: "",
      PhoneNumber: 0,
      Email: "",
      DateOfBirth: "",
      Gender: "",
      BloodGroup: "",
      Address: "",
      City: "",
      State: "",
      PostalCode: "",
      Files: "",
      ProfilePic: "",
      HospitalName: ""
    }
    this.currentItem = 0;
    this.ModalTitle = "Add Patient";
    this.ActivateAddEditPatient = true;
  }

  editPatient(item: any) {
    this.currentItem = item.PatientId;
    this.ModalTitle = "Edit Patient Details"
    this.ActivateAddEditPatient = true;
  }

  deletePatient(item: any) {
    if (confirm('Are you sure??')) {
      this.service.deletePatient(item.PatientId,this.currentUser.User.HospitalId)
        .subscribe(
          response => {
            alert("Patient deleted successfully !!");
            this.refreshPatientList();
          },
          error => {
            console.log(error.error);
          });
    }
  }

  closeClick() {
    this.ActivateAddEditPatient = false;
    this.refreshPatientList();
  }

  // redirectToPatient(){
  //   this.router.navigateByUrl('patient');
  // }

  refreshPatientList() {
    this.service.getPatientsList(this.currentUser.User.HospitalId)
      .subscribe(data => {
        for (var item of data) {
          var age = this.ageCalculator(item.DateOfBirth);
          item.DateOfBirth = age;
        }        
        this.PatientList = data;
        this.SearchPatientList = data;
      },
    error => {
      console.log(error.error);
    });

  }

  // Age Calculation variables
  userinput: any;
  dob: any;
  dobYear: any;
  dobMonth: any;
  dobDate: any;
  now: any;
  currentYear: any;
  currentMonth: any;
  currentDate: any;
  age: any;
  ageString: any;
  yearAge: any;
  monthAge: any;
  dateAge: any;
  currentAge: any;

  // Age calculator
  ageCalculator(id: any) {
    //collect input from HTML form and convert into date format
    //var userinput = document.getElementById("DOB").value;
    this.userinput = id; //"1987-06-15"; //this.patient.DateOfBirth;  yyyy-mm-dd
    this.dob = new Date(this.userinput);

    if (this.dob.getUTCFullYear() == 0 || this.dob.getUTCFullYear() == 1) {
      return;
    }
    //check user provide input or not
    if (this.userinput == null || this.userinput == '') {
      //document.getElementById("message").innerHTML = "**Choose a date please!";  
      //return false; 
    }

    //execute if the user entered a date 
    else {
      //extract the year, month, and date from user date input
      this.dobYear = this.dob.getYear();
      this.dobMonth = this.dob.getMonth();
      this.dobDate = this.dob.getDate();

      //get the current date from the system
      this.now = new Date();
      //extract the year, month, and date from current date
      this.currentYear = this.now.getYear();
      this.currentMonth = this.now.getMonth();
      this.currentDate = this.now.getDate();

      //declare a variable to collect the age in year, month, and days
      this.age = {};
      this.ageString = "";

      //get years
      this.yearAge = this.currentYear - this.dobYear;

      //get months
      if (this.currentMonth >= this.dobMonth)
        //get months when current month is greater
        this.monthAge = this.currentMonth - this.dobMonth;
      else {
        this.yearAge--;
        this.monthAge = 12 + this.currentMonth - this.dobMonth;
      }

      //get days
      if (this.currentDate >= this.dobDate)
        //get days when the current date is greater
        this.dateAge = this.currentDate - this.dobDate;
      else {
        this.monthAge--;
        this.dateAge = 31 + this.currentDate - this.dobDate;

        if (this.monthAge < 0) {
          this.monthAge = 11;
          this.yearAge--;
        }
      }
      //group the age in a single variable
      this.age = {
        years: this.yearAge,
        months: this.monthAge,
        days: this.dateAge
      };

      this.yearAge = this.ageString.years;
      this.monthAge = this.ageString.months;
      this.dateAge = this.ageString.days;

      if ((this.age.years > 0) && (this.age.months > 0) && (this.age.days > 0))
        this.ageString = this.age.years + " Y " + this.age.months + " M " + this.age.days + " D";
      else if ((this.age.years == 0) && (this.age.months == 0) && (this.age.days > 0))
        this.ageString = this.age.days + " D";
      //when current month and date is same as birth date and month
      else if ((this.age.years > 0) && (this.age.months == 0) && (this.age.days == 0))
        this.ageString = this.age.years + " Y";
      else if ((this.age.years > 0) && (this.age.months > 0) && (this.age.days == 0))
        this.ageString = this.age.years + " Y " + this.age.months + " M";
      else if ((this.age.years == 0) && (this.age.months > 0) && (this.age.days > 0))
        this.ageString = this.age.months + " M " + this.age.days + " D";
      else if ((this.age.years > 0) && (this.age.months == 0) && (this.age.days > 0))
        this.ageString = this.age.years + " Y " + this.age.days + " D";
      else if ((this.age.years == 0) && (this.age.months > 0) && (this.age.days == 0))
        this.ageString = this.age.months + " M ";
      //when current date is same as dob(date of birth)
      else
        this.ageString = "Welcome to Earth! <br> It's first day on Earth!";

      this.currentAge = this.ageString;
      return this.currentAge;
      //display the calculated age
      //return document.getElementById("result").innerHTML = ageString; 
      //console.log(this.currentAge);

    }
  }

}
