<div class="main-wrapper">
    <div class="page-wrapper">
        <div class="dash-widget" style="margin: 0px 96px 5px 39px;">
            <button type="button" class="close" aria-label="Close" *ngIf="viewDashboard != true" (click)="loadDepartmentsList()">
                <span aria-hidden="true">×</span>
            </button>
            <div class="row">
                <div>
                    <h4 class="page-title">Department details</h4>
                </div>
            </div>
            <div class="row float-right mr-1">
                <button type="button" class="btn btn-primary" *ngIf="this.currentUser.User.RoleId == 1" [ngClass]="viewDashboard == true ?'d-block':'d-none'" (click)="createForm()">Add Department</button>
            </div>

            <!-- Add or Edit department -->
            <form [formGroup]="form" (ngSubmit)="submit()" [ngClass]="viewDashboard == true ?'d-none':'d-block'">
                <div class="form-row">
                    <div class="form-group col-6" hidden>
                        <label>Department Id</label>
                        <input formControlName="departmentId" class="form-control" type="number" [readonly]="viewDashboard == false"/>
                    </div>
                    <div class="form-group col-6">
                        <label>Department Name</label>
                        <input formControlName="departmentName" class="form-control" type="text" />
                    </div>
                    <div class="m-t-27 text-center">
                        <button class="btn btn-primary submit-btn" type="submit">{{submitText}}</button>
                    </div>
                </div>
            </form>

            <!-- Department Dashboard -->
            <div class="mt-5" [ngClass]="viewDashboard == true ?'d-block':'d-none'">
                <div class="dash-widget">
                    <div class="table-responsive">
                        <table class="table table-border table-striped custom-table mb-2">
                            <thead>
                                <th>Department No</th>
                                <th>Department Name</th>
                                <th *ngIf="this.currentUser.User.RoleId == 1">Action</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of Departments">
                                    <td>{{item.DepartmentId}}</td>
                                    <td>{{item.DepartmentName}}</td>
                                    <td *ngIf="this.currentUser.User.RoleId == 1">
                                        <div class="dropdown dropdown-action">
                                            <a href="#" class="action-icon dropdown-toggle" data-toggle="dropdown"
                                                aria-expanded="false"><i class="fa fa-ellipsis-v"></i></a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <a class="dropdown-item" (click)="getDepartmentDetails(item.DepartmentId)"><i
                                                        class="fa fa-pencil m-r-5"></i> Edit</a>
                                                <a class="dropdown-item" (click)="deleteDepartment(item.DepartmentId)"><i
                                                        class="fa fa-trash-o m-r-5"></i>Delete</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
