import { Injectable } from '@angular/core';
//import { HttpClient, HttpHeaders, HttpInterceptor } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
//import { Patient } from 'src/app/patient';
import { IAppointment } from 'src/app/appointment/iappointment';

import { HttpClient,HttpHeaders,HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';
import { User } from 'src/app/_models/user';
// import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/catch';

const users: User[] = [{ id: 1, username: 'test', password: 'test', firstName: 'Test', lastName: 'User' , roleId: 0,User:''}];
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SharedService implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request;

    // wrap in delayed observable to simulate server api call
    return of(null)
      .pipe(mergeMap(handleRoute))
      .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
      .pipe(delay(500))
      .pipe(dematerialize());

    function handleRoute() {
      switch (true) {
        case url.endsWith('/users/authenticate') && method === 'POST':
          return authenticate();
        case url.endsWith('/users') && method === 'GET':
          return getUsers();
        default:
          // pass through any requests not handled above
          return next.handle(request);
      }
    }

    // route functions

    function authenticate() {
      const { username, password } = body;
      const user = users.find(x => x.username === username && x.password === password);
      if (!user) return error('Username or password is incorrect');
      return ok({
        id: user.id,
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        token: 'fake-jwt-token'
      })
    }

    function getUsers() {
      if (!isLoggedIn()) return unauthorized();
      return ok(users);
    }

    // helper functions

    function ok(body?:any) {
      return of(new HttpResponse({ status: 200, body }))
    }

    function error(message:any) {
      return throwError({ error: { message } });
    }

    function unauthorized() {
      return throwError({ status: 401, error: { message: 'Unauthorised' } });
    }

    function isLoggedIn() {
      return headers.get('Authorization') === 'Bearer fake-jwt-token';
    }
  }


  //readonly APIUrl = "http://localhost:5001/api";
  readonly APIUrl = "https://apikey.ainetwork.in:8081/api"; // Dev Url
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
  };
  
  authenticateUser(val: any) {
    return this.http.post<any>(this.APIUrl + '/Authentication/Auth/', val, { headers: this.headers });
  }

  addPatient(val: any) {
    //return this.http.post<any>(this.APIUrl + '/patient', val);
    return this.http.post<any>(this.APIUrl + '/patient', val, httpOptions)
    .pipe(
      catchError(this.handleError('addPatient: '+ val))
    );

  }

  updatePatient(data: any) {
    return this.http.put(`${this.APIUrl}/patient/`, data);
  }

  deletePatient(id: any,hospitalId:any) {
    return this.http.delete(`${this.APIUrl}/patient/${id}/${hospitalId}`);
  }

  getPatientsList(id:any): Observable<any[]> {
    return this.http.get<any[]>(`${this.APIUrl}/patient/GetAllPatients/${id}`);
  }

  getPatientDetailsByID(patientId: any,hospitalId:any): Observable<any> {
    return this.http.get<any>(`${this.APIUrl}/patient/${patientId}/${hospitalId}`);
  }

  getAllBloodgroupNames(): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/patient/GetBloodgroupList');
  }

  getAllHospitalNames(): Observable<any[]> {
    return this.http.get<any[]>(this.APIUrl + '/patient/GetHospitalList');
  }

  getDoctorNames(hospitalId:any): Observable<any[]> {
    return this.http.get<any[]>(`${this.APIUrl}/appointment/Doctors/${hospitalId}`);
  }
  
  // <!----- Appointment control methods ------->
  getAppointmetsList(hospitalId:any): Observable<any[]> {
    return this.http.get<any[]>(`${this.APIUrl}/appointment/GetAppointments/${hospitalId}`);
  }

  addAppointment(val: any) {
    return this.http.post<any>(this.APIUrl + '/appointment/addappointment/', val, { headers: this.headers });
  }
  getAppointmentDetails(appointmentId: any,hospitalId:any): Observable<IAppointment> {
    return this.http.get<IAppointment>(`${this.APIUrl}/appointment/GetAppointmentByID/${appointmentId}/${hospitalId}`)
      .pipe(map((res: any) => {
        return res
      }))
  }
  updateAppointment(data: any): Observable<IAppointment> {
    return this.http.put<IAppointment>(`${this.APIUrl}/appointment/UpdateAppointment/`, JSON.stringify(data), { headers: this.headers });
  }
  deleteAppointment(appointmentId: any,hospitalId:any): Observable<IAppointment> {
    return this.http.delete<IAppointment>(`${this.APIUrl}/appointment/DeleteAppointment/${appointmentId}/${hospitalId}`);
  }

  // <!-------- Prescription Service methods ------> //
  addPrescription(val: any) {
    //return this.http.post<any>(this.APIUrl+'/Prescription', val);
    return this.http.post(this.APIUrl + '/prescription', val, { headers: this.headers });
  }

  getPrescriptionList(hospitalId:any): Observable<any[]> {
    return this.http.get<any[]>(`${this.APIUrl}/Prescription/GetAllPrescriptions/${hospitalId}`);
  }

  deletePrescription(id: any,hospitalId:any): Observable<any> {
    return this.http.delete<any>(`${this.APIUrl}/Prescription/${id}/${hospitalId}`);
  }

  getPrescription(prescriptionId: any,hospitalId:any): Observable<any> {
    return this.http.get<any>(`${this.APIUrl}/Prescription/${prescriptionId}/${hospitalId}`)
      .pipe(map((res: any) => {
        return res
      }))
  }

  updatePrescription(data: any): Observable<any> {
    return this.http.put<any>(`${this.APIUrl}/Prescription/UpdatePrescription/`, JSON.stringify(data), { headers: this.headers });
  }

  // Doctors Service Actions
  getDoctorsList(hospitalId:any): Observable<any[]> {
    return this.http.get<any[]>(`${this.APIUrl}/doctor/GetDoctors/${hospitalId}`);
  }
  getDoctorId(doctorId: any,hospitalId:any): Observable<any> {
    return this.http.get<any>(`${this.APIUrl}/doctor/${doctorId}/${hospitalId}`);
  }
  getDoctorDetailsById(userId: any,hospitalId:any): Observable<any> {
    return this.http.get<any>(`${this.APIUrl}/doctor/GetDoctorByUserId/${userId}/${hospitalId}`);
  }
  deleteDoctor(id: any,hospitalId:any): Observable<any> {
    return this.http.delete<any>(`${this.APIUrl}/doctor/${id}/${hospitalId}`);
  }
  addDoctor(val: any) {
    return this.http.post(this.APIUrl + '/doctor', val, { headers: this.headers }).pipe(catchError(this.handleError2));
  }
  updateDoctor(val: any) {
    return this.http.put(this.APIUrl+'/doctor/UpdateDetails', val);
  }
  getDepartmentsList(hospitalId:any): Observable<any[]> {
    return this.http.get<any[]>(`${this.APIUrl}/doctor/GetDepartments/${hospitalId}`);
  }
  getDepartmentetails(departmentId: any,hospitalId:any): Observable<any> {
    return this.http.get<any>(`${this.APIUrl}/doctor/GetDepartmentDetails/${departmentId}/${hospitalId}`);
  }
  deleteDepartment(id: any,hospitalId:any): Observable<any> {
    return this.http.delete<any>(`${this.APIUrl}/doctor/DeleteDepartment/${id}/${hospitalId}`);
  }
  addDepartment(val: any) {
    return this.http.post(this.APIUrl + '/doctor/SaveDepartment', val, { headers: this.headers });
  }
  updateDepartment(val: any) {
    return this.http.put(this.APIUrl+'/doctor/UpdateDepartment', val);
  }

  //  Staff Service Actions  
  getStaffList(hospitalId:any): Observable<any[]> {
    return this.http.get<any[]>(`${this.APIUrl}/staff/GetStaffs/${hospitalId}`);
  }
  getStaffId(staffId: any,hospitalId:any): Observable<any> {
    return this.http.get<any>(`${this.APIUrl}/staff/${staffId}/${hospitalId}`);
  }
  deleteStaff(id: any,hospitalId:any): Observable<any> {
    return this.http.delete<any>(`${this.APIUrl}/staff/${id}/${hospitalId}`);
  }
  addStaff(val: any) {
    // return this.http.post<any>(this.APIUrl + '/doctor', val);
    return this.http.post(this.APIUrl + '/staff', val, { headers: this.headers }).pipe(catchError(this.handleError2));
  }
  updateStaff(val: any) {
    return this.http.put(this.APIUrl+'/staff/UpdateDetails', val);
  }

  // Dashboard Actions
  dashboardActions(): Observable<any[]> {
    return this.http.get<any[]>(`${this.APIUrl}/appointment/GetAppointments`);
  }

  // Patient profile actions
  getPatientAppointmets(patientID: any): Observable<any> {
    return this.http.get<any[]>(`${this.APIUrl}/PatientProfile/GetAppointments/${patientID}`);
  }
  getPatientPrescriptons(patientID: any): Observable<any> {
    return this.http.get<any[]>(`${this.APIUrl}/PatientProfile/GetPrescriptions/${patientID}`);
  }
  getPatientProfile(patientID: any): Observable<any> {
    return this.http.get<any[]>(`${this.APIUrl}/PatientProfile/GetPatientProfile/${patientID}`);
  }
  updatePatientProfile(val: any) {
    return this.http.put(this.APIUrl + '/PatientProfile/UpdatePatientProfile', val, { headers: this.headers });
  }
  getBirthhistory(patientID: any): Observable<any> {
    return this.http.get<any[]>(`${this.APIUrl}/PatientProfile/GetBirthhistory/${patientID}`);
  }
  addBirthhistory(val: any) {
    return this.http.post(this.APIUrl + '/PatientProfile/SaveBirthhistory', val, { headers: this.headers });
  }
  updateBirthhistory(val: any) {
    return this.http.put(this.APIUrl + '/PatientProfile/UpdateBirthhistory', val, { headers: this.headers });
  }
  addSchedule(val: any) {
    return this.http.post(this.APIUrl + '/Immunization/ScheduleImmunization', val, { headers: this.headers });
  }
  addImmunization(val: any) {
    return this.http.post(this.APIUrl + '/Immunization/AddImmunization', val, { headers: this.headers });
  }
  updateSchedule(val: any) {
    return this.http.put(this.APIUrl + '/Immunization/UpdateScheduledImmunization', val, { headers: this.headers });
  }
  updateImmunization(val: any) {
    return this.http.put(this.APIUrl + '/Immunization/UpdateImmunization', val, { headers: this.headers });
  }
  getImmunizationsList(patientID: any): Observable<any> {
    return this.http.get<any[]>(`${this.APIUrl}/Immunization/GetImmunizations/${patientID}`);
  }
  getScheduledImmunizationsList(patientID: any): Observable<any> {
    return this.http.get<any[]>(`${this.APIUrl}/Immunization/GetScheduledImmunizations/${patientID}`);
  }  
  getScheduledImmunization(id: any): Observable<any> {
    return this.http.get<any[]>(`${this.APIUrl}/Immunization/GetScheduledImmunization/${id}`);
  }
  getImmunization(id: any): Observable<any> {
    return this.http.get<any[]>(`${this.APIUrl}/Immunization/GetImmunization/${id}`);
  }
  deleteImmunization(id: any): Observable<any> {
    return this.http.delete<any>(`${this.APIUrl}/Immunization/DeleteImmunization/${id}`);
  }
  deleteScheduledImmunization(id: any): Observable<any> {
    return this.http.delete<any>(`${this.APIUrl}/Immunization/DeleteScheduled/${id}`);
  }

  // Payment methods service
  paymentMethods(): Observable<any[]> {
    return this.http.get<any[]>(`${this.APIUrl}/Bill/GetPaymentMethods`);
  }

  //Bill End points
  addService(val: any) {
    //return this.http.post(this.APIUrl + '/Bill/AddService', val, { headers: this.headers });
    return this.http.post(this.APIUrl + '/Bill/AddService', val, { headers: this.headers }).pipe(
      tap((newHero: any) => this.log(`added service w/ id=${newHero.id}`)),
      catchError(this.handleError<any>('addService'))
    );
  }

  updateService(val: any) {
    return this.http.put(this.APIUrl + '/Bill/UpdateService', val, httpOptions).pipe(
      catchError(this.handleError('UpdateService: '+ val))
    );
  }

  getService(id: any,hospitalId:any): Observable<any> {
    return this.http.get(this.APIUrl+`/Bill/GetService/${id}/${hospitalId}`).pipe(
      catchError(this.handleError('GetService: '+ id,hospitalId))
    );
  }

  deleteService(id: any,hospitalId:any): Observable<any> {
    return this.http.delete(this.APIUrl + `/Bill/DeleteService/${id}/${hospitalId}`).pipe(
      catchError(this.handleError('DeleteService: '+ id,hospitalId))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  handleError2(error: HttpErrorResponse) {
    return throwError(error);
}
  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    return (`HeroService: ${message}`);
  }

  getServices(hospitalId:any): Observable<any[]> {
    return this.http.get<any[]>(`${this.APIUrl}/Bill/GetServiceNames/${hospitalId}`);
  }
  addBill(val: any) {
    return this.http.post(this.APIUrl + '/Bill/AddBill', val, { headers: this.headers });
  }
  updateBill(val: any) {
    return this.http.put(this.APIUrl + '/Bill/UpdateBill', val, { headers: this.headers });
  }
  getBills(hospitalId:any): Observable<any[]> {
    return this.http.get<any[]>(`${this.APIUrl}/Bill/GetBills/${hospitalId}`);
  }
  getBillById(id: any,hospitalId:any): Observable<any> {
    return this.http.get<any[]>(`${this.APIUrl}/Bill/GetBill/${id}/${hospitalId}`);
  }
  getPatientBills(patientId: any,hospitalId:any): Observable<any> {
    return this.http.get<any[]>(`${this.APIUrl}/Bill/GetPatientBills/${patientId}/${hospitalId}`);
  }
  deleteBill(id: any,hospitalId:any): Observable<any> {
    return this.http.delete<any>(`${this.APIUrl}/bill/DeleteBill/${id}/${hospitalId}`);
  }

  //Prescription master endpoints
  getMastersList(doctorId:any, hospitalId:any,name:any): Observable<any[]> {
    return this.http.get<any[]>(`${this.APIUrl}/PMaster/${name}/${doctorId}/${hospitalId}`).pipe(catchError(this.handleError2));
  }
  getPMasterItem(id: any,doctorId:any,hospitalId:any,name:any): Observable<any> {
    return this.http.get<any>(`${this.APIUrl}/PMaster/${name}/${id}/${doctorId}/${hospitalId}`).pipe(catchError(this.handleError2));
  }
  deletePMasterItem(id: any,doctorId:any,hospitalId:any,name:any): Observable<any> {
    return this.http.delete<any>(`${this.APIUrl}/PMaster/${name}/${id}/${doctorId}/${hospitalId}`).pipe(catchError(this.handleError2));
  }
  addPMasterItem(val: any, name:any) {
    return this.http.post(this.APIUrl + '/PMaster/'+ name, val, { headers: this.headers }).pipe(catchError(this.handleError2));
  }
  updatePMasterItem(val: any,name:any) {
    return this.http.put(this.APIUrl+'/PMaster/'+name, val)
    .pipe(catchError(this.handleError2));
  }

  //PrescriptionMaster Titles endpoints
  getTitlesList(doctorId:any, hospitalId:any): Observable<any[]> {
    return this.http.get<any[]>(`${this.APIUrl}/PMaster/GetTitles/${doctorId}/${hospitalId}`).pipe(catchError(this.handleError2));
  }
  getTitle(id: any,doctorId:any,hospitalId:any): Observable<any> {
    return this.http.get<any>(`${this.APIUrl}/PMaster/GetTitle/${id}/${doctorId}/${hospitalId}`).pipe(catchError(this.handleError2));
  }
  getTitleByGroupId(doctorId:any,hospitalId:any,groupId:any): Observable<any> {
    return this.http.get<any>(`${this.APIUrl}/PMaster/GetTitlesByGroupId/${doctorId}/${hospitalId}/${groupId}`).pipe(catchError(this.handleError2));
  }
  deleteTitle(id: any,doctorId:any,hospitalId:any): Observable<any> {
    return this.http.delete<any>(`${this.APIUrl}/PMaster/DeleteTitle/${id}/${doctorId}/${hospitalId}`).pipe(catchError(this.handleError2));
  }
  addTitle(val: any) {
    return this.http.post(this.APIUrl + '/PMaster/SaveTitle', val, { headers: this.headers }).pipe(catchError(this.handleError2));
  }
  updateTitle(val: any) {
    return this.http.put(this.APIUrl+'/PMaster/UpdateTitle', val).pipe(catchError(this.handleError2));
  }

  //PrescriptionMaster Advices endpoints
  getAdvicesList(titleId:any): Observable<any[]> {
    return this.http.get<any[]>(`${this.APIUrl}/PMaster/GetAdvices/${titleId}`).pipe(catchError(this.handleError2));
  }
  getAdvice(id: any,titleId:any): Observable<any> {
    return this.http.get<any>(`${this.APIUrl}/PMaster/GetAdvice/${id}/${titleId}`).pipe(catchError(this.handleError2));
  }  
  deleteAdvice(id: any,titleId:any): Observable<any> {
    return this.http.delete<any>(`${this.APIUrl}/PMaster/DeleteAdvice/${id}/${titleId}`).pipe(catchError(this.handleError2));
  }
  addAdvice(val: any) {
    return this.http.post(this.APIUrl + '/PMaster/SaveAdvice', val, { headers: this.headers }).pipe(catchError(this.handleError2));
  }
  updateAdvice(val: any) {
    return this.http.put(this.APIUrl+'/PMaster/updateAdvice', val).pipe(catchError(this.handleError2));
  }
  
  //PrescriptionMaster Medicines endpoints
  getMedicinesList(titleId:any): Observable<any[]> { 
    return this.http.get<any[]>(`${this.APIUrl}/PMaster/GetMedicines/${titleId}`).pipe(catchError(this.handleError2));
  }
  getMedicine(id: any,titleId:any): Observable<any> {
    return this.http.get<any>(`${this.APIUrl}/PMaster/GetMedicine/${id}/${titleId}`).pipe(catchError(this.handleError2));
  }  
  deleteMedicine(id: any,titleId:any): Observable<any> {
    return this.http.delete<any>(`${this.APIUrl}/PMaster/DeleteMedicine/${id}/${titleId}`).pipe(catchError(this.handleError2));
  }
  addMedicine(val: any) {
    return this.http.post(this.APIUrl + '/PMaster/SaveMedicine', val, { headers: this.headers }).pipe(catchError(this.handleError2));
  }
  updateMedicine(val: any) {
    return this.http.put(this.APIUrl+'/PMaster/updateMedicine', val).pipe(catchError(this.handleError2));
  }

  // Age Calculation variables
  dob: any;
  dobYear: any;
  dobMonth: any;
  dobDate: any;
  now: any;
  currentYear: any;
  currentMonth: any;
  currentDate: any;
  age: any;
  ageString: any;
  yearAge: any;
  monthAge: any;
  dateAge: any;
  currentAge: any;

  // Age calculator
  ageCalculator(id: any) {
    //collect input from HTML form and convert into date format
    //var userinput = document.getElementById("DOB").value;
    this.dob = new Date(id); //"1987-06-15"; //this.patient.DateOfBirth;  yyyy-mm-dd

    //check user provide input or not
    if (id == null || id == '') {
      //document.getElementById("message").innerHTML = "**Choose a date please!";  
      //return false; 
    }

    //execute if the user entered a date 
    else {
      //extract the year, month, and date from user date input
      this.dobYear = this.dob.getYear();
      this.dobMonth = this.dob.getMonth();
      this.dobDate = this.dob.getDate();

      //get the current date from the system
      this.now = new Date();
      //extract the year, month, and date from current date
      this.currentYear = this.now.getYear();
      this.currentMonth = this.now.getMonth();
      this.currentDate = this.now.getDate();

      //declare a variable to collect the age in year, month, and days
      this.age = {};
      this.ageString = "";

      //get years
      this.yearAge = this.currentYear - this.dobYear;

      //get months
      if (this.currentMonth >= this.dobMonth)
        //get months when current month is greater
        this.monthAge = this.currentMonth - this.dobMonth;
      else {
        this.yearAge--;
        this.monthAge = 12 + this.currentMonth - this.dobMonth;
      }

      //get days
      if (this.currentDate >= this.dobDate)
        //get days when the current date is greater
        this.dateAge = this.currentDate - this.dobDate;
      else {
        this.monthAge--;
        this.dateAge = 31 + this.currentDate - this.dobDate;

        if (this.monthAge < 0) {
          this.monthAge = 11;
          this.yearAge--;
        }
      }
      //group the age in a single variable
      this.age = {
        years: this.yearAge,
        months: this.monthAge,
        days: this.dateAge
      };

      this.yearAge = this.ageString.years;
      this.monthAge = this.ageString.months;
      this.dateAge = this.ageString.days;

      if ((this.age.years > 0) && (this.age.months > 0) && (this.age.days > 0))
        this.ageString = this.age.years + " Y " + this.age.months + " M " + this.age.days + " D";
      else if ((this.age.years == 0) && (this.age.months == 0) && (this.age.days > 0))
        this.ageString = this.age.days + " D";
      //when current month and date is same as birth date and month
      else if ((this.age.years > 0) && (this.age.months == 0) && (this.age.days == 0))
        this.ageString = this.age.years + " Y";
      else if ((this.age.years > 0) && (this.age.months > 0) && (this.age.days == 0))
        this.ageString = this.age.years + " Y " + this.age.months + " M";
      else if ((this.age.years == 0) && (this.age.months > 0) && (this.age.days > 0))
        this.ageString = this.age.months + " M " + this.age.days + " D";
      else if ((this.age.years > 0) && (this.age.months == 0) && (this.age.days > 0))
        this.ageString = this.age.years + " Y " + this.age.days + " D";
      else if ((this.age.years == 0) && (this.age.months > 0) && (this.age.days == 0))
        this.ageString = this.age.months + " M ";
      //when current date is same as dob(date of birth)
      else
        //this.ageString = "Welcome to Earth! <br> It's first day on Earth!";
        this.ageString = this.age.days + " D";

      this.currentAge = this.ageString;
      return this.currentAge;
      //console.log(this.currentAge);
    }
  }
}
