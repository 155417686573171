import { Patient } from 'src/app/patient';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared.service';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-home',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  isSubmitted = false;
  brandList: any;
  editPatientID: any;
  patientData: any;
  
  phoneNumber:any;
  email:any;
  bloodgroup:any;
  address:any='';
  city:any;
  state:any;
  postalCode:any;
  hospitalName:any;
  currentUser:any;

  constructor(
    public service: SharedService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {this.currentUser = this.authenticationService.currentUserValue; }

  ngOnInit(): void {  
    this.route.queryParams.subscribe(
      params => {
        this.editPatientID = params['patientId'];
      });
    if (this.editPatientID != undefined) {
      this.getPatientDetails();
    }
  }

  getPatientDetails() {
    this.service.getPatientDetailsByID(this.editPatientID, this.currentUser.User.HospitalId)
      .subscribe(
        (data) => {
          if (data.LastName == null) {
            data.LastName = "";
          }
          this.patientData = data;
          this.editPatientID = this.patientData.PatientId;
          this.phoneNumber = this.patientData.PhoneNumber;
          this.email = this.patientData.Email;
          this.bloodgroup = this.patientData.Bloodgroup;          
          this.hospitalName = this.patientData.HospitalName;
          
          if (this.patientData.Address != null) {
            this.address = this.patientData.Address + ',';
          }
          if (this.patientData.City != null) {
            this.address = this.address + this.patientData.City + ',';
          }
          if (this.patientData.State != null) {
            this.address = this.address + this.patientData.State + ',';
          }
          if (this.patientData.PostalCode != 0) {
            this.address = this.address + this.patientData.PostalCode;
          }
          this.address = this.address.replace(/^,|,$/g, '');

        },
        error => {
          console.log(error);
        });
  }

  // onFormSubmit() {
  //   console.log(this.userForm.value);

  //   let id = this.userForm.controls.id.value;
  //   if (!id) {
  //     // this._http.post(this.baseURL , this.userForm.value).subscribe(() => {
  //     //   alert('Created successfully');
  //     //   this.reset();
  //     // });
  //   } else {
  //     // this._http.put(this.baseURL + id, this.userForm.value).subscribe(() => {
  //     //   alert('Updated successfully');
  //     //   this.reset();
  //     // });
  //   }
  // }
  // reset() {
  //   this.userForm.reset();
  //   this.userForm.controls.is_active.setValue(1);
  //   this.isSubmitted = false;

  // }


}
