export class AppointmentModel {
    AppointmentId: number =0;
    ClinicName: string = ''; 
    PatientName: string = '';
    DepartmentName: string = '';
    DoctorName: string = '';
    Date: Date ;
    Time: string = '';
    //Email: string = '';
    Status: string = '';
    PurposeOfVisit: string = '';
}
